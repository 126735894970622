/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentFileContent,
    AttachmentFileContentFromJSON,
    AttachmentFileContentToJSON,
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoToJSON,
    ControlSampleAnalysisType,
    ControlSampleAnalysisTypeFromJSON,
    ControlSampleAnalysisTypeToJSON,
    CreateBasedOnProceedingParams,
    CreateBasedOnProceedingParamsFromJSON,
    CreateBasedOnProceedingParamsToJSON,
    CreateBasedOnProductTypeParams,
    CreateBasedOnProductTypeParamsFromJSON,
    CreateBasedOnProductTypeParamsToJSON,
    CreateBpNotificationMemoDto,
    CreateBpNotificationMemoDtoFromJSON,
    CreateBpNotificationMemoDtoToJSON,
    CreateInspectionBasedOnMetric,
    CreateInspectionBasedOnMetricFromJSON,
    CreateInspectionBasedOnMetricToJSON,
    CreateInspectionBasedOnMetricWithOrganization,
    CreateInspectionBasedOnMetricWithOrganizationFromJSON,
    CreateInspectionBasedOnMetricWithOrganizationToJSON,
    CreatePrivateMemoDto,
    CreatePrivateMemoDtoFromJSON,
    CreatePrivateMemoDtoToJSON,
    CreatePublicMemoDto,
    CreatePublicMemoDtoFromJSON,
    CreatePublicMemoDtoToJSON,
    CreateUpdateAttachmentDto,
    CreateUpdateAttachmentDtoFromJSON,
    CreateUpdateAttachmentDtoToJSON,
    CreateUpdateCertificateDto,
    CreateUpdateCertificateDtoFromJSON,
    CreateUpdateCertificateDtoToJSON,
    CreateUpdateCertificateWithOrganizationDto,
    CreateUpdateCertificateWithOrganizationDtoFromJSON,
    CreateUpdateCertificateWithOrganizationDtoToJSON,
    CreateUpdateCorrectiveActionDto,
    CreateUpdateCorrectiveActionDtoFromJSON,
    CreateUpdateCorrectiveActionDtoToJSON,
    CreateUpdateCustomsFeedbackDto,
    CreateUpdateCustomsFeedbackDtoFromJSON,
    CreateUpdateCustomsFeedbackDtoToJSON,
    CreateUpdateCustomsFeedbackWithOrganizationDto,
    CreateUpdateCustomsFeedbackWithOrganizationDtoFromJSON,
    CreateUpdateCustomsFeedbackWithOrganizationDtoToJSON,
    CreateUpdateDictionaryDto,
    CreateUpdateDictionaryDtoFromJSON,
    CreateUpdateDictionaryDtoToJSON,
    CreateUpdateEmployeeDto,
    CreateUpdateEmployeeDtoFromJSON,
    CreateUpdateEmployeeDtoToJSON,
    CreateUpdateEssentialFeatureDto,
    CreateUpdateEssentialFeatureDtoFromJSON,
    CreateUpdateEssentialFeatureDtoToJSON,
    CreateUpdateExamResultDto,
    CreateUpdateExamResultDtoFromJSON,
    CreateUpdateExamResultDtoToJSON,
    CreateUpdateExamResultHistoryDto,
    CreateUpdateExamResultHistoryDtoFromJSON,
    CreateUpdateExamResultHistoryDtoToJSON,
    CreateUpdateInspectedProductDto,
    CreateUpdateInspectedProductDtoFromJSON,
    CreateUpdateInspectedProductDtoToJSON,
    CreateUpdateInspectionDto,
    CreateUpdateInspectionDtoFromJSON,
    CreateUpdateInspectionDtoToJSON,
    CreateUpdateInspectionMetricDto,
    CreateUpdateInspectionMetricDtoFromJSON,
    CreateUpdateInspectionMetricDtoToJSON,
    CreateUpdateInspectionPlanDto,
    CreateUpdateInspectionPlanDtoFromJSON,
    CreateUpdateInspectionPlanDtoToJSON,
    CreateUpdateInspectionPlanGroupDto,
    CreateUpdateInspectionPlanGroupDtoFromJSON,
    CreateUpdateInspectionPlanGroupDtoToJSON,
    CreateUpdateInspectorDto,
    CreateUpdateInspectorDtoFromJSON,
    CreateUpdateInspectorDtoToJSON,
    CreateUpdateJudgmentDto,
    CreateUpdateJudgmentDtoFromJSON,
    CreateUpdateJudgmentDtoToJSON,
    CreateUpdateKwzDto,
    CreateUpdateKwzDtoFromJSON,
    CreateUpdateKwzDtoToJSON,
    CreateUpdateKwzHistoryDto,
    CreateUpdateKwzHistoryDtoFromJSON,
    CreateUpdateKwzHistoryDtoToJSON,
    CreateUpdateMisstatementDto,
    CreateUpdateMisstatementDtoFromJSON,
    CreateUpdateMisstatementDtoToJSON,
    CreateUpdateOrganizationDto,
    CreateUpdateOrganizationDtoFromJSON,
    CreateUpdateOrganizationDtoToJSON,
    CreateUpdateOrganizationalUnitDto,
    CreateUpdateOrganizationalUnitDtoFromJSON,
    CreateUpdateOrganizationalUnitDtoToJSON,
    CreateUpdatePakDto,
    CreateUpdatePakDtoFromJSON,
    CreateUpdatePakDtoToJSON,
    CreateUpdatePartyToProceedingDto,
    CreateUpdatePartyToProceedingDtoFromJSON,
    CreateUpdatePartyToProceedingDtoToJSON,
    CreateUpdatePartyWithOrganizationDto,
    CreateUpdatePartyWithOrganizationDtoFromJSON,
    CreateUpdatePartyWithOrganizationDtoToJSON,
    CreateUpdatePdfTemplateDto,
    CreateUpdatePdfTemplateDtoFromJSON,
    CreateUpdatePdfTemplateDtoToJSON,
    CreateUpdateProceedingDto,
    CreateUpdateProceedingDtoFromJSON,
    CreateUpdateProceedingDtoToJSON,
    CreateUpdateProductTypeDto,
    CreateUpdateProductTypeDtoFromJSON,
    CreateUpdateProductTypeDtoToJSON,
    CreateUpdateProtocolDuringInspectionDto,
    CreateUpdateProtocolDuringInspectionDtoFromJSON,
    CreateUpdateProtocolDuringInspectionDtoToJSON,
    CreateUpdateSampleCollectDto,
    CreateUpdateSampleCollectDtoFromJSON,
    CreateUpdateSampleCollectDtoToJSON,
    CreateUpdateSampleDto,
    CreateUpdateSampleDtoFromJSON,
    CreateUpdateSampleDtoToJSON,
    CreateUpdateSampleExamDto,
    CreateUpdateSampleExamDtoFromJSON,
    CreateUpdateSampleExamDtoToJSON,
    CreateUpdateTechAssessmentDto,
    CreateUpdateTechAssessmentDtoFromJSON,
    CreateUpdateTechAssessmentDtoToJSON,
    CreateUpdateTechAssessmentWithOrganizationDto,
    CreateUpdateTechAssessmentWithOrganizationDtoFromJSON,
    CreateUpdateTechAssessmentWithOrganizationDtoToJSON,
    CreateWithJudgmentDto,
    CreateWithJudgmentDtoFromJSON,
    CreateWithJudgmentDtoToJSON,
    CreateWithNewProducerAndProductTypeParams,
    CreateWithNewProducerAndProductTypeParamsFromJSON,
    CreateWithNewProducerAndProductTypeParamsToJSON,
    CreateWithNewProductTypeParams,
    CreateWithNewProductTypeParamsFromJSON,
    CreateWithNewProductTypeParamsToJSON,
    FilterData,
    FilterDataFromJSON,
    FilterDataToJSON,
    FilterDataExtended,
    FilterDataExtendedFromJSON,
    FilterDataExtendedToJSON,
    FilterDatesProductTypeReportData,
    FilterDatesProductTypeReportDataFromJSON,
    FilterDatesProductTypeReportDataToJSON,
    FilterNegativeTestDataExtended,
    FilterNegativeTestDataExtendedFromJSON,
    FilterNegativeTestDataExtendedToJSON,
    FilterYearData,
    FilterYearDataFromJSON,
    FilterYearDataToJSON,
    FormalIrregulationsType,
    FormalIrregulationsTypeFromJSON,
    FormalIrregulationsTypeToJSON,
    GetAttachmentDto,
    GetAttachmentDtoFromJSON,
    GetAttachmentDtoToJSON,
    GetCertificateDto,
    GetCertificateDtoFromJSON,
    GetCertificateDtoToJSON,
    GetCorrectiveActionDto,
    GetCorrectiveActionDtoFromJSON,
    GetCorrectiveActionDtoToJSON,
    GetCustomsFeedbackDto,
    GetCustomsFeedbackDtoFromJSON,
    GetCustomsFeedbackDtoToJSON,
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoToJSON,
    GetDictionaryTypeDto,
    GetDictionaryTypeDtoFromJSON,
    GetDictionaryTypeDtoToJSON,
    GetDocumentationDto,
    GetDocumentationDtoFromJSON,
    GetDocumentationDtoToJSON,
    GetEmployeeDto,
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoToJSON,
    GetEssentialFeatureDto,
    GetEssentialFeatureDtoFromJSON,
    GetEssentialFeatureDtoToJSON,
    GetExamResultDto,
    GetExamResultDtoFromJSON,
    GetExamResultDtoToJSON,
    GetExamResultHistoryDto,
    GetExamResultHistoryDtoFromJSON,
    GetExamResultHistoryDtoToJSON,
    GetInspectedProductDto,
    GetInspectedProductDtoFromJSON,
    GetInspectedProductDtoToJSON,
    GetInspectionDto,
    GetInspectionDtoFromJSON,
    GetInspectionDtoToJSON,
    GetInspectionExtendedDto,
    GetInspectionExtendedDtoFromJSON,
    GetInspectionExtendedDtoToJSON,
    GetInspectionMetricDto,
    GetInspectionMetricDtoFromJSON,
    GetInspectionMetricDtoToJSON,
    GetInspectionPlanDto,
    GetInspectionPlanDtoFromJSON,
    GetInspectionPlanDtoToJSON,
    GetInspectionPlanGroupDto,
    GetInspectionPlanGroupDtoFromJSON,
    GetInspectionPlanGroupDtoToJSON,
    GetInspectionWithDetailsDto,
    GetInspectionWithDetailsDtoFromJSON,
    GetInspectionWithDetailsDtoToJSON,
    GetInspectorDto,
    GetInspectorDtoFromJSON,
    GetInspectorDtoToJSON,
    GetJudgmentDto,
    GetJudgmentDtoFromJSON,
    GetJudgmentDtoToJSON,
    GetKwzDto,
    GetKwzDtoFromJSON,
    GetKwzDtoToJSON,
    GetKwzHistoryDto,
    GetKwzHistoryDtoFromJSON,
    GetKwzHistoryDtoToJSON,
    GetKwzWithPartiesDto,
    GetKwzWithPartiesDtoFromJSON,
    GetKwzWithPartiesDtoToJSON,
    GetLastApprovalDateDto,
    GetLastApprovalDateDtoFromJSON,
    GetLastApprovalDateDtoToJSON,
    GetLinkedExamToSampleDto,
    GetLinkedExamToSampleDtoFromJSON,
    GetLinkedExamToSampleDtoToJSON,
    GetMemoDto,
    GetMemoDtoFromJSON,
    GetMemoDtoToJSON,
    GetMisstatementDto,
    GetMisstatementDtoFromJSON,
    GetMisstatementDtoToJSON,
    GetNarrowedKwzDto,
    GetNarrowedKwzDtoFromJSON,
    GetNarrowedKwzDtoToJSON,
    GetObjectHistoryDto,
    GetObjectHistoryDtoFromJSON,
    GetObjectHistoryDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoToJSON,
    GetPakDto,
    GetPakDtoFromJSON,
    GetPakDtoToJSON,
    GetPakExtendedDto,
    GetPakExtendedDtoFromJSON,
    GetPakExtendedDtoToJSON,
    GetPakIdsWithAnotherSourceDto,
    GetPakIdsWithAnotherSourceDtoFromJSON,
    GetPakIdsWithAnotherSourceDtoToJSON,
    GetPakWithPartiesDto,
    GetPakWithPartiesDtoFromJSON,
    GetPakWithPartiesDtoToJSON,
    GetParams,
    GetParamsFromJSON,
    GetParamsToJSON,
    GetPartyToProceedingDto,
    GetPartyToProceedingDtoFromJSON,
    GetPartyToProceedingDtoToJSON,
    GetPdfTemplateDto,
    GetPdfTemplateDtoFromJSON,
    GetPdfTemplateDtoToJSON,
    GetProceedingDto,
    GetProceedingDtoFromJSON,
    GetProceedingDtoToJSON,
    GetProceedingExtendedDto,
    GetProceedingExtendedDtoFromJSON,
    GetProceedingExtendedDtoToJSON,
    GetProceedingWithPartiesDto,
    GetProceedingWithPartiesDtoFromJSON,
    GetProceedingWithPartiesDtoToJSON,
    GetProductTypeDto,
    GetProductTypeDtoFromJSON,
    GetProductTypeDtoToJSON,
    GetProtocolDuringInspectionDto,
    GetProtocolDuringInspectionDtoFromJSON,
    GetProtocolDuringInspectionDtoToJSON,
    GetSampleCollectDto,
    GetSampleCollectDtoFromJSON,
    GetSampleCollectDtoToJSON,
    GetSampleCollectExtendedDto,
    GetSampleCollectExtendedDtoFromJSON,
    GetSampleCollectExtendedDtoToJSON,
    GetSampleCollectWithExamsDto,
    GetSampleCollectWithExamsDtoFromJSON,
    GetSampleCollectWithExamsDtoToJSON,
    GetSampleDto,
    GetSampleDtoFromJSON,
    GetSampleDtoToJSON,
    GetSampleExamDto,
    GetSampleExamDtoFromJSON,
    GetSampleExamDtoToJSON,
    GetSampleExamExtendedDto,
    GetSampleExamExtendedDtoFromJSON,
    GetSampleExamExtendedDtoToJSON,
    GetSampleExamViewModel,
    GetSampleExamViewModelFromJSON,
    GetSampleExamViewModelToJSON,
    GetSampleExtendedDto,
    GetSampleExtendedDtoFromJSON,
    GetSampleExtendedDtoToJSON,
    GetSubjectThreadStatsDto,
    GetSubjectThreadStatsDtoFromJSON,
    GetSubjectThreadStatsDtoToJSON,
    GetTechAssessmentDto,
    GetTechAssessmentDtoFromJSON,
    GetTechAssessmentDtoToJSON,
    GetThreadDto,
    GetThreadDtoFromJSON,
    GetThreadDtoToJSON,
    GetThreadsStatsDto,
    GetThreadsStatsDtoFromJSON,
    GetThreadsStatsDtoToJSON,
    GetVersionInfoDto,
    GetVersionInfoDtoFromJSON,
    GetVersionInfoDtoToJSON,
    InspectionPlanProgressDataForChartType,
    InspectionPlanProgressDataForChartTypeFromJSON,
    InspectionPlanProgressDataForChartTypeToJSON,
    PakAnalysisMoneyPenaltiesType,
    PakAnalysisMoneyPenaltiesTypeFromJSON,
    PakAnalysisMoneyPenaltiesTypeToJSON,
    ReportCorrectiveActionsType,
    ReportCorrectiveActionsTypeFromJSON,
    ReportCorrectiveActionsTypeToJSON,
    ReportCostSampleExamsType,
    ReportCostSampleExamsTypeFromJSON,
    ReportCostSampleExamsTypeToJSON,
    ReportCustomsFeedbacksType,
    ReportCustomsFeedbacksTypeFromJSON,
    ReportCustomsFeedbacksTypeToJSON,
    ReportInspected,
    ReportInspectedFromJSON,
    ReportInspectedToJSON,
    ReportInspectedOrganizations,
    ReportInspectedOrganizationsFromJSON,
    ReportInspectedOrganizationsToJSON,
    ReportInspectedProductsByRange,
    ReportInspectedProductsByRangeFromJSON,
    ReportInspectedProductsByRangeToJSON,
    ReportInspectionPlanForInspectedProductType,
    ReportInspectionPlanForInspectedProductTypeFromJSON,
    ReportInspectionPlanForInspectedProductTypeToJSON,
    ReportInspectionPlanForOrganizationType,
    ReportInspectionPlanForOrganizationTypeFromJSON,
    ReportInspectionPlanForOrganizationTypeToJSON,
    ReportJudgmentsCountType,
    ReportJudgmentsCountTypeFromJSON,
    ReportJudgmentsCountTypeToJSON,
    ReportNegativeTestType,
    ReportNegativeTestTypeFromJSON,
    ReportNegativeTestTypeToJSON,
    ReportOrderedSampleExamsType,
    ReportOrderedSampleExamsTypeFromJSON,
    ReportOrderedSampleExamsTypeToJSON,
    ReportPakAmountType,
    ReportPakAmountTypeFromJSON,
    ReportPakAmountTypeToJSON,
    ReportPakJudgmentType,
    ReportPakJudgmentTypeFromJSON,
    ReportPakJudgmentTypeToJSON,
    ReportProceedingType,
    ReportProceedingTypeFromJSON,
    ReportProceedingTypeToJSON,
    ReportProductsByMisstatement,
    ReportProductsByMisstatementFromJSON,
    ReportProductsByMisstatementToJSON,
    ReportProductsBySign,
    ReportProductsBySignFromJSON,
    ReportProductsBySignToJSON,
    ReportProductsByType,
    ReportProductsByTypeFromJSON,
    ReportProductsByTypeToJSON,
    ReportProductsGroupsType,
    ReportProductsGroupsTypeFromJSON,
    ReportProductsGroupsTypeToJSON,
    ReportPublicationsType,
    ReportPublicationsTypeFromJSON,
    ReportPublicationsTypeToJSON,
    RevertApproveDto,
    RevertApproveDtoFromJSON,
    RevertApproveDtoToJSON,
    SendPrivateMemoToManyDto,
    SendPrivateMemoToManyDtoFromJSON,
    SendPrivateMemoToManyDtoToJSON,
    TimelineEventType,
    TimelineEventTypeFromJSON,
    TimelineEventTypeToJSON,
    TimelineProcessType,
    TimelineProcessTypeFromJSON,
    TimelineProcessTypeToJSON,
    ValidationStatus,
    ValidationStatusFromJSON,
    ValidationStatusToJSON,
} from '../models';

export interface AttachmentControllerCreateRequest {
    createUpdateAttachmentDto: CreateUpdateAttachmentDto;
}

export interface AttachmentControllerDeleteRequest {
    id: string;
}

export interface AttachmentControllerGetRequest {
    id: string;
}

export interface AttachmentControllerGetAllForObjectRequest {
    objectType: string;
    objectId: string;
}

export interface AttachmentControllerGetFileDownloadRequest {
    id: string;
}

export interface CertificateControllerCreateRequest {
    createUpdateCertificateDto: CreateUpdateCertificateDto;
}

export interface CertificateControllerCreateWithOrganizationRequest {
    createUpdateCertificateWithOrganizationDto: CreateUpdateCertificateWithOrganizationDto;
}

export interface CertificateControllerDeleteRequest {
    id: string;
}

export interface CertificateControllerGetRequest {
    id: string;
}

export interface CertificateControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface CertificateControllerGetAllCountRequest {
    filterText: string;
}

export interface CertificateControllerGetByOrganizationRequest {
    id: string;
}

export interface CertificateControllerUpdateRequest {
    id: string;
    createUpdateCertificateDto: CreateUpdateCertificateDto;
}

export interface CorrectiveActionControllerApproveRequest {
    id: string;
}

export interface CorrectiveActionControllerCreateRequest {
    createUpdateCorrectiveActionDto: CreateUpdateCorrectiveActionDto;
}

export interface CorrectiveActionControllerDeleteRequest {
    id: string;
}

export interface CorrectiveActionControllerGetRequest {
    id: string;
}

export interface CorrectiveActionControllerGetByInspectedProductRequest {
    id: string;
}

export interface CorrectiveActionControllerGetByProceedingRequest {
    id: string;
}

export interface CorrectiveActionControllerRevertApproveRequest {
    id: string;
}

export interface CorrectiveActionControllerUpdateRequest {
    id: string;
    createUpdateCorrectiveActionDto: CreateUpdateCorrectiveActionDto;
}

export interface CustomsFeedbackControllerCreateRequest {
    createUpdateCustomsFeedbackDto: CreateUpdateCustomsFeedbackDto;
}

export interface CustomsFeedbackControllerCreateWithOrganizationRequest {
    createUpdateCustomsFeedbackWithOrganizationDto: CreateUpdateCustomsFeedbackWithOrganizationDto;
}

export interface CustomsFeedbackControllerDeleteRequest {
    id: string;
}

export interface CustomsFeedbackControllerGetRequest {
    id: string;
}

export interface CustomsFeedbackControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface CustomsFeedbackControllerGetAllCountRequest {
    filterText: string;
}

export interface CustomsFeedbackControllerGetByOrganizationRequest {
    id: string;
}

export interface CustomsFeedbackControllerUpdateRequest {
    id: string;
    createUpdateCustomsFeedbackDto: CreateUpdateCustomsFeedbackDto;
}

export interface DictionaryControllerChangeToInactiveRequest {
    id: string;
}

export interface DictionaryControllerCreateRequest {
    createUpdateDictionaryDto: CreateUpdateDictionaryDto;
}

export interface DictionaryControllerGetRequest {
    id: string;
}

export interface DictionaryControllerGetByTypeRequest {
    name: string;
}

export interface DictionaryControllerGetByTypeAndValueAndFirstLevelRequest {
    name: string;
    value: string;
}

export interface DictionaryControllerUpdateRequest {
    id: string;
    createUpdateDictionaryDto: CreateUpdateDictionaryDto;
}

export interface DictionaryTypeControllerGetRequest {
    id: string;
}

export interface DocumentationControllerGetForFilenameRequest {
    filename: string;
}

export interface EmployeeControllerChangeToInactiveRequest {
    id: string;
}

export interface EmployeeControllerCreateRequest {
    createUpdateEmployeeDto: CreateUpdateEmployeeDto;
}

export interface EmployeeControllerGetRequest {
    id: string;
}

export interface EmployeeControllerGetAllByOrganizationalUnitRequest {
    id: string;
}

export interface EmployeeControllerUpdateRequest {
    id: string;
    createUpdateEmployeeDto: CreateUpdateEmployeeDto;
}

export interface EssentialFeatureControllerCreateRequest {
    createUpdateEssentialFeatureDto: CreateUpdateEssentialFeatureDto;
}

export interface EssentialFeatureControllerDeleteRequest {
    id: string;
}

export interface EssentialFeatureControllerGetRequest {
    id: string;
}

export interface EssentialFeatureControllerGetBySampleExamRequest {
    id: string;
}

export interface EssentialFeatureControllerGetExamMethodsByPatternRequest {
    pattern: string;
    limit: number;
}

export interface EssentialFeatureControllerGetFeaturesByPatternRequest {
    pattern: string;
    limit: number;
}

export interface EssentialFeatureControllerGetFunctionalPropertiesByPatternRequest {
    pattern: string;
    limit: number;
}

export interface EssentialFeatureControllerGetUniqueExamMethodsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface EssentialFeatureControllerGetUniqueExamMethodsCountRequest {
    filterText: string;
}

export interface EssentialFeatureControllerGetUniqueFeaturesByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface EssentialFeatureControllerGetUniqueFeaturesCountRequest {
    filterText: string;
}

export interface EssentialFeatureControllerGetUniqueFunctionalPropertiesByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface EssentialFeatureControllerGetUniqueFunctionalPropertiesCountRequest {
    filterText: string;
}

export interface EssentialFeatureControllerOverwriteExamMethodsRequest {
    oldExamMethod: string;
    newExamMethod: string;
}

export interface EssentialFeatureControllerOverwriteFeaturesRequest {
    oldFeature: string;
    newFeature: string;
}

export interface EssentialFeatureControllerOverwriteFunctionalPropertiesRequest {
    oldFunctionalProperty: string;
    newFunctionalProperty: string;
}

export interface EssentialFeatureControllerUpdateRequest {
    id: string;
    createUpdateEssentialFeatureDto: CreateUpdateEssentialFeatureDto;
}

export interface ExamResultControllerApproveRequest {
    id: string;
}

export interface ExamResultControllerCreateRequest {
    createUpdateExamResultDto: CreateUpdateExamResultDto;
}

export interface ExamResultControllerDeleteRequest {
    id: string;
}

export interface ExamResultControllerGetRequest {
    id: string;
}

export interface ExamResultControllerGetBySampleIdRequest {
    id: string;
}

export interface ExamResultControllerMarkAsUnpublishableRequest {
    id: string;
}

export interface ExamResultControllerMayBeApprovedRequest {
    id: string;
}

export interface ExamResultControllerMayBePublishedRequest {
    id: string;
}

export interface ExamResultControllerPublishRequest {
    id: string;
}

export interface ExamResultControllerRevertApproveRequest {
    revertApproveDto: RevertApproveDto;
}

export interface ExamResultControllerRevertMarkAsUnpublishableRequest {
    id: string;
}

export interface ExamResultControllerRevertPublishRequest {
    id: string;
}

export interface ExamResultControllerUpdateRequest {
    id: string;
    createUpdateExamResultDto: CreateUpdateExamResultDto;
}

export interface ExamResultHistoryControllerCreateRequest {
    createUpdateExamResultHistoryDto: CreateUpdateExamResultHistoryDto;
}

export interface ExamResultHistoryControllerGetBySampleRequest {
    id: string;
}

export interface ExamResultHistoryControllerGetLastApprovalDateRequest {
    id: string;
}

export interface GusApiControllerGetByNipRequest {
    nip: string;
}

export interface InspectedProductControllerCreateBasedOnProductTypeRequest {
    createBasedOnProductTypeParams: CreateBasedOnProductTypeParams;
}

export interface InspectedProductControllerCreateWithNewProducerAndProductTypeRequest {
    createWithNewProducerAndProductTypeParams: CreateWithNewProducerAndProductTypeParams;
}

export interface InspectedProductControllerCreateWithNewProductTypeRequest {
    createWithNewProductTypeParams: CreateWithNewProductTypeParams;
}

export interface InspectedProductControllerDeleteRequest {
    id: string;
}

export interface InspectedProductControllerGetRequest {
    id: string;
}

export interface InspectedProductControllerGetByInspectionRequest {
    id: string;
}

export interface InspectedProductControllerGetByPakRequest {
    id: string;
}

export interface InspectedProductControllerGetBySampleExamRequest {
    id: string;
}

export interface InspectedProductControllerUpdateRequest {
    id: string;
    createUpdateInspectedProductDto: CreateUpdateInspectedProductDto;
}

export interface InspectedProductControllerValidateNewRequest {
    createBasedOnProductTypeParams: CreateBasedOnProductTypeParams;
}

export interface InspectionControllerCreateBasedOnMetricRequest {
    createInspectionBasedOnMetric: CreateInspectionBasedOnMetric;
}

export interface InspectionControllerCreateBasedOnMetricWithOrganizationRequest {
    createInspectionBasedOnMetricWithOrganization: CreateInspectionBasedOnMetricWithOrganization;
}

export interface InspectionControllerDeleteRequest {
    id: string;
}

export interface InspectionControllerGetRequest {
    id: string;
}

export interface InspectionControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    organizationalUnit: string;
    organization: string;
    inspectionPlace: string;
    actionDateFrom: string;
    actionDateTo: string;
    fileNumber: string;
}

export interface InspectionControllerGetAllCountRequest {
    filterText: string;
}

export interface InspectionControllerGetByMetricRequest {
    id: string;
}

export interface InspectionControllerGetByPakRequest {
    id: string;
}

export interface InspectionControllerGetSignedByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface InspectionControllerGetSignedCountRequest {
    filterText: string;
}

export interface InspectionControllerGetWithDetailsRequest {
    id: string;
}

export interface InspectionControllerMayDeleteRequest {
    id: string;
}

export interface InspectionControllerReadyToSignRequest {
    id: string;
}

export interface InspectionControllerRevertSignRequest {
    id: string;
}

export interface InspectionControllerSignRequest {
    id: string;
}

export interface InspectionControllerUpdateRequest {
    id: string;
    createUpdateInspectionDto: CreateUpdateInspectionDto;
}

export interface InspectionMetricControllerApproveRequest {
    id: string;
}

export interface InspectionMetricControllerGetRequest {
    id: string;
}

export interface InspectionMetricControllerRevertApproveRequest {
    id: string;
}

export interface InspectionMetricControllerUpdateRequest {
    id: string;
    createUpdateInspectionMetricDto: CreateUpdateInspectionMetricDto;
}

export interface InspectionPlanControllerCreateRequest {
    createUpdateInspectionPlanDto: CreateUpdateInspectionPlanDto;
}

export interface InspectionPlanControllerDeleteRequest {
    id: string;
}

export interface InspectionPlanControllerGetRequest {
    id: string;
}

export interface InspectionPlanControllerUpdateRequest {
    id: string;
    createUpdateInspectionPlanDto: CreateUpdateInspectionPlanDto;
}

export interface InspectionPlanGroupControllerCreateRequest {
    createUpdateInspectionPlanGroupDto: CreateUpdateInspectionPlanGroupDto;
}

export interface InspectionPlanGroupControllerDeleteRequest {
    id: string;
}

export interface InspectionPlanGroupControllerGetRequest {
    id: string;
}

export interface InspectionPlanGroupControllerGetByPlanRequest {
    id: string;
}

export interface InspectionPlanGroupControllerUpdateRequest {
    id: string;
    createUpdateInspectionPlanGroupDto: CreateUpdateInspectionPlanGroupDto;
}

export interface InspectorControllerCreateRequest {
    createUpdateInspectorDto: CreateUpdateInspectorDto;
}

export interface InspectorControllerDeleteRequest {
    id: string;
}

export interface InspectorControllerGetRequest {
    id: string;
}

export interface InspectorControllerGetByInspectionRequest {
    id: string;
}

export interface InspectorControllerUpdateRequest {
    id: string;
    createUpdateInspectorDto: CreateUpdateInspectorDto;
}

export interface InspectorControllerValidateCreateRequest {
    createUpdateInspectorDto: CreateUpdateInspectorDto;
}

export interface JudgmentControllerApproveRequest {
    id: string;
}

export interface JudgmentControllerCreateRequest {
    createUpdateJudgmentDto: CreateUpdateJudgmentDto;
}

export interface JudgmentControllerDeleteRequest {
    id: string;
}

export interface JudgmentControllerGetRequest {
    id: string;
}

export interface JudgmentControllerGetByInspectedProductRequest {
    id: string;
}

export interface JudgmentControllerGetByPakRequest {
    id: string;
}

export interface JudgmentControllerGetByProceedingRequest {
    id: string;
}

export interface JudgmentControllerGetBySampleRequest {
    id: string;
}

export interface JudgmentControllerGetWithProceedingRequest {
    id: string;
}

export interface JudgmentControllerRevertApproveRequest {
    id: string;
}

export interface JudgmentControllerUpdateRequest {
    id: string;
    createUpdateJudgmentDto: CreateUpdateJudgmentDto;
}

export interface KwzControllerAddRequestForWithdrawalRequest {
    id: string;
}

export interface KwzControllerApproveRequest {
    id: string;
}

export interface KwzControllerApproveRequestForWithdrawalRequest {
    id: string;
}

export interface KwzControllerCreateWithJudgmentRequest {
    createWithJudgmentDto: CreateWithJudgmentDto;
}

export interface KwzControllerDeleteWithJudgmentRequest {
    id: string;
}

export interface KwzControllerGetWithJudgmentRequest {
    id: string;
}

export interface KwzControllerGetWithPartiesRequest {
    id: string;
}

export interface KwzControllerPublishRequest {
    id: string;
}

export interface KwzControllerRemovePublishRequest {
    id: string;
}

export interface KwzControllerRemoveRequestForWithdrawalRequest {
    id: string;
}

export interface KwzControllerRevertApproveRequest {
    id: string;
}

export interface KwzControllerRevertPublishRequest {
    id: string;
}

export interface KwzControllerRevertRemovePublishRequest {
    id: string;
}

export interface KwzControllerRevertRequestForWithdrawalRequest {
    id: string;
}

export interface KwzControllerUpdateRequest {
    id: string;
    createUpdateKwzDto: CreateUpdateKwzDto;
}

export interface KwzHistoryControllerCreateRequest {
    createUpdateKwzHistoryDto: CreateUpdateKwzHistoryDto;
}

export interface KwzHistoryControllerGetByKwzRequest {
    id: string;
}

export interface MemoControllerCreateBpNotificationMemoRequest {
    createBpNotificationMemoDto: CreateBpNotificationMemoDto;
}

export interface MemoControllerCreatePrivateMemoRequest {
    createPrivateMemoDto: CreatePrivateMemoDto;
}

export interface MemoControllerCreatePublicMemoRequest {
    createPublicMemoDto: CreatePublicMemoDto;
}

export interface MemoControllerGetSubjectThreadStatsRequest {
    subjectType: MemoControllerGetSubjectThreadStatsSubjectTypeEnum;
    id: string;
}

export interface MemoControllerGetThreadMemosRequest {
    id: string;
    count: number;
}

export interface MemoControllerGetThreadsForUserRequest {
    threadsCount: number;
    filter: number;
}

export interface MemoControllerSendPrivateMemoToManyRequest {
    sendPrivateMemoToManyDto: SendPrivateMemoToManyDto;
}

export interface MisstatementControllerCreateRequest {
    createUpdateMisstatementDto: CreateUpdateMisstatementDto;
}

export interface MisstatementControllerDeleteRequest {
    id: string;
}

export interface MisstatementControllerGetRequest {
    id: string;
}

export interface MisstatementControllerGetByInspectedProductRequest {
    id: string;
}

export interface MisstatementControllerUpdateRequest {
    id: string;
    createUpdateMisstatementDto: CreateUpdateMisstatementDto;
}

export interface MisstatementControllerValidateNewRequest {
    createUpdateMisstatementDto: CreateUpdateMisstatementDto;
}

export interface ObjectHistoryControllerGetRequest {
    id: string;
}

export interface ObjectHistoryControllerGetHistoryForObjectAndTypeRequest {
    objectId: string;
    objectType: string;
}

export interface ObjectHistoryControllerGetPreviousEventRequest {
    id: string;
}

export interface OrganizationControllerCreateRequest {
    createUpdateOrganizationDto: CreateUpdateOrganizationDto;
}

export interface OrganizationControllerGetRequest {
    id: string;
}

export interface OrganizationControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface OrganizationControllerGetAllCountRequest {
    filterText: string;
}

export interface OrganizationControllerGetByNipRequest {
    pattern: string;
    limit: number;
}

export interface OrganizationControllerGetByPatternRequest {
    pattern: string;
    limit: number;
}

export interface OrganizationControllerGetDistinctCountriesRequest {
    pattern: string;
    limit: number;
}

export interface OrganizationControllerGetDistinctPlacesRequest {
    pattern: string;
    limit: number;
}

export interface OrganizationControllerGetDistinctPostcodesRequest {
    pattern: string;
    limit: number;
}

export interface OrganizationControllerMergeOrganizationsRequest {
    orgDeleteId: string;
    orgStayId: string;
}

export interface OrganizationControllerUpdateRequest {
    id: string;
    createUpdateOrganizationDto: CreateUpdateOrganizationDto;
}

export interface OrganizationControllerValidateNewRequest {
    createUpdateOrganizationDto: CreateUpdateOrganizationDto;
}

export interface OrganizationalUnitControllerChangeToInactiveRequest {
    id: string;
}

export interface OrganizationalUnitControllerCreateRequest {
    createUpdateOrganizationalUnitDto: CreateUpdateOrganizationalUnitDto;
}

export interface OrganizationalUnitControllerGetRequest {
    id: string;
}

export interface OrganizationalUnitControllerGetManyRequest {
    options: number;
}

export interface OrganizationalUnitControllerUpdateRequest {
    id: string;
    createUpdateOrganizationalUnitDto: CreateUpdateOrganizationalUnitDto;
}

export interface PakControllerApproveRequest {
    id: string;
}

export interface PakControllerCreateBasedOnInspectedProductRequest {
    getParams: GetParams;
}

export interface PakControllerCreateBasedOnProceedingRequest {
    createBasedOnProceedingParams: CreateBasedOnProceedingParams;
}

export interface PakControllerCreateBasedOnSampleRequest {
    getParams: GetParams;
}

export interface PakControllerDeleteRequest {
    id: string;
}

export interface PakControllerGetRequest {
    id: string;
}

export interface PakControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    organizationalUnit: string;
    organization: string;
    productType: string;
    startDateFrom: string;
    startDateTo: string;
    endDateFrom: string;
    endDateTo: string;
    fileNumber: string;
}

export interface PakControllerGetAllCountRequest {
    filterText: string;
}

export interface PakControllerGetAllSourceIdsRequest {
    id: string;
}

export interface PakControllerGetByInspectedProductRequest {
    id: string;
}

export interface PakControllerGetByProceedingRequest {
    id: string;
}

export interface PakControllerGetBySampleRequest {
    id: string;
}

export interface PakControllerMayBeApprovedRequest {
    id: string;
}

export interface PakControllerRevertApproveRequest {
    id: string;
}

export interface PakControllerUpdateRequest {
    id: string;
    createUpdatePakDto: CreateUpdatePakDto;
}

export interface PartyToProceedingControllerCreateRequest {
    createUpdatePartyToProceedingDto: CreateUpdatePartyToProceedingDto;
}

export interface PartyToProceedingControllerCreateWithOrganizationRequest {
    createUpdatePartyWithOrganizationDto: CreateUpdatePartyWithOrganizationDto;
}

export interface PartyToProceedingControllerDeleteRequest {
    id: string;
}

export interface PartyToProceedingControllerGetRequest {
    id: string;
}

export interface PartyToProceedingControllerGetByPakRequest {
    id: string;
}

export interface PartyToProceedingControllerGetByProceedingRequest {
    id: string;
}

export interface PartyToProceedingControllerUpdateRequest {
    id: string;
    createUpdatePartyToProceedingDto: CreateUpdatePartyToProceedingDto;
}

export interface PartyToProceedingControllerValidateNewRequest {
    createUpdatePartyToProceedingDto: CreateUpdatePartyToProceedingDto;
}

export interface PdfPrinterControllerGetExamActivityProtocolRequest {
    id: string;
}

export interface PdfPrinterControllerGetExamResultRequest {
    sampleId: string;
}

export interface PdfPrinterControllerGetExaminationProtocolRequest {
    id: string;
}

export interface PdfPrinterControllerGetExaminationProtocolOnTheFlyRequest {
    getProtocolDuringInspectionDto: GetProtocolDuringInspectionDto;
}

export interface PdfPrinterControllerGetInspectionProtocolRequest {
    id: string;
}

export interface PdfPrinterControllerGetInventoryProtocolRequest {
    id: string;
}

export interface PdfPrinterControllerGetInventoryProtocolOnTheFlyRequest {
    getProtocolDuringInspectionDto: GetProtocolDuringInspectionDto;
}

export interface PdfPrinterControllerGetKwzwbRequest {
    id: string;
}

export interface PdfPrinterControllerGetSampleExamProtocolRequest {
    id: string;
}

export interface PdfTemplateControllerGetRequest {
    id: string;
}

export interface PdfTemplateControllerUpdateRequest {
    id: string;
    createUpdatePdfTemplateDto: CreateUpdatePdfTemplateDto;
}

export interface ProceedingControllerApproveRequest {
    id: string;
}

export interface ProceedingControllerControlSampleExamRequest {
    id: string;
}

export interface ProceedingControllerCreateBasedOnMetricRequest {
    id: string;
}

export interface ProceedingControllerDeleteRequest {
    id: string;
}

export interface ProceedingControllerGetRequest {
    id: string;
}

export interface ProceedingControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    organizationalUnit: string;
    organization: string;
    productType: string;
    startDateFrom: string;
    startDateTo: string;
    endDateFrom: string;
    endDateTo: string;
    fileNumber: string;
}

export interface ProceedingControllerGetAllCountRequest {
    filterText: string;
}

export interface ProceedingControllerGetByInspectionRequest {
    id: string;
}

export interface ProceedingControllerGetByPakRequest {
    id: string;
}

export interface ProceedingControllerGetBySampleExamRequest {
    id: string;
}

export interface ProceedingControllerGetWithinspectedProductRequest {
    id: string;
}

export interface ProceedingControllerMayBeApprovedRequest {
    id: string;
}

export interface ProceedingControllerMayExamControlSampleRequest {
    id: string;
}

export interface ProceedingControllerRevertApproveRequest {
    id: string;
}

export interface ProceedingControllerRevertControlSampleExamRequest {
    id: string;
}

export interface ProceedingControllerUpdateRequest {
    id: string;
    createUpdateProceedingDto: CreateUpdateProceedingDto;
}

export interface ProductTypeControllerApproveRequest {
    id: string;
    validationStatus: ValidationStatus;
}

export interface ProductTypeControllerCreateRequest {
    createUpdateProductTypeDto: CreateUpdateProductTypeDto;
}

export interface ProductTypeControllerGetRequest {
    id: string;
}

export interface ProductTypeControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ProductTypeControllerGetAllCountRequest {
    filterText: string;
}

export interface ProductTypeControllerGetByOrganizationByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    organizationId: string;
}

export interface ProductTypeControllerGetByOrganizationCountRequest {
    filterText: string;
    organizationId: string;
}

export interface ProductTypeControllerGetByPatternRequest {
    pattern: string;
    limit: number;
}

export interface ProductTypeControllerGetByProducerAndPatternRequest {
    producerId: string;
    pattern: string;
    limit: number;
}

export interface ProductTypeControllerGetLinkedToExamSampleRequest {
    examId: string;
    typeId: string;
    pattern: string;
    limit: number;
}

export interface ProductTypeControllerMayBeApprovedRequest {
    id: string;
}

export interface ProductTypeControllerMergeProductTypesRequest {
    prodDeleteId: string;
    prodStayId: string;
}

export interface ProductTypeControllerRevertApproveRequest {
    id: string;
}

export interface ProductTypeControllerUpdateRequest {
    id: string;
    createUpdateProductTypeDto: CreateUpdateProductTypeDto;
}

export interface ProductTypeControllerValidateNewRequest {
    createUpdateProductTypeDto: CreateUpdateProductTypeDto;
}

export interface ProtocolDuringInspectionControllerApproveRequest {
    id: string;
}

export interface ProtocolDuringInspectionControllerCreateRequest {
    createUpdateProtocolDuringInspectionDto: CreateUpdateProtocolDuringInspectionDto;
}

export interface ProtocolDuringInspectionControllerDeleteRequest {
    id: string;
}

export interface ProtocolDuringInspectionControllerGetRequest {
    id: string;
}

export interface ProtocolDuringInspectionControllerGetByInspectedProductRequest {
    id: string;
}

export interface ProtocolDuringInspectionControllerRevertApproveRequest {
    id: string;
}

export interface ProtocolDuringInspectionControllerUpdateRequest {
    id: string;
    createUpdateProtocolDuringInspectionDto: CreateUpdateProtocolDuringInspectionDto;
}

export interface PubControllerGetAttachmentForExamResultRequest {
    id: string;
}

export interface PubControllerGetEssentialFeaturesForExamRequest {
    id: string;
}

export interface PubControllerGetPrintedKwzCardRequest {
    id: string;
}

export interface ReportControllerGetExamResultHistoryTimelineRequest {
    examResultId: string;
}

export interface ReportControllerGetKwzHistoryTimelineRequest {
    kwzId: string;
}

export interface ReportControllerGetTimelineByProcessRequest {
    objectId: string;
    objectType: string;
}

export interface ReportControllerGetTimelineByProductTypeRequest {
    productTypeId: string;
}

export interface ReportControllerGetTimelineBySampleExamRequest {
    sampleExamId: string;
}

export interface ReportControllerNegativeTestResultsRequest {
    filterNegativeTestDataExtended: FilterNegativeTestDataExtended;
}

export interface ReportControllerReportConductedInspectionsRequest {
    filterData: FilterData;
}

export interface ReportControllerReportControlSamlesRequest {
    filterNegativeTestDataExtended: FilterNegativeTestDataExtended;
}

export interface ReportControllerReportCorrectiveActionsRequest {
    filterData: FilterData;
}

export interface ReportControllerReportCostSampleExamsRequest {
    filterData: FilterData;
}

export interface ReportControllerReportCustomsFeedbacksRequest {
    filterData: FilterData;
}

export interface ReportControllerReportFormalIrregulationsRequest {
    filterDataExtended: FilterDataExtended;
}

export interface ReportControllerReportInspectedOrganizationsRequest {
    filterData: FilterData;
}

export interface ReportControllerReportInspectedOrganizationsByProductsRequest {
    filterData: FilterData;
}

export interface ReportControllerReportInspectedOrganizationsByProductsWithMisstatementRequest {
    filterData: FilterData;
}

export interface ReportControllerReportInspectedProductsRequest {
    filterData: FilterData;
}

export interface ReportControllerReportInspectionPlanByInspectedProductRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportInspectionPlanByOrganizationRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportInspectionPlanProgressByInspectedProductRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportInspectionPlanProgressByInspectedProductCprRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportInspectionPlanProgressByInspectedProductGroupRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportInspectionPlanProgressByOrganizationRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportJudgmentsCountRequest {
    filterData: FilterData;
}

export interface ReportControllerReportJudgmentsInProceedingAndPakRequest {
    filterData: FilterData;
}

export interface ReportControllerReportOrderedSampleExamsRequest {
    filterData: FilterData;
}

export interface ReportControllerReportPakAmountRequest {
    filterData: FilterData;
}

export interface ReportControllerReportPakAnalysisMoneyPenaltiesRequest {
    filterDataExtended: FilterDataExtended;
}

export interface ReportControllerReportPakByJudgmentRequest {
    filterData: FilterData;
}

export interface ReportControllerReportProceedingRequest {
    filterData: FilterData;
}

export interface ReportControllerReportProductsByMisstatementRequest {
    filterData: FilterData;
}

export interface ReportControllerReportProductsByRangeRequest {
    filterData: FilterData;
}

export interface ReportControllerReportProductsBySignRequest {
    filterData: FilterData;
}

export interface ReportControllerReportProductsByTypeRequest {
    filterDatesProductTypeReportData: FilterDatesProductTypeReportData;
}

export interface ReportControllerReportProductsGroupsRequest {
    filterData: FilterData;
}

export interface ReportControllerReportPublicationsRequest {
    filterData: FilterData;
}

export interface SampleCollectControllerCreateNewRequest {
    createUpdateSampleCollectDto: CreateUpdateSampleCollectDto;
}

export interface SampleCollectControllerCreateWithNewOrganizationRequest {
    body: object;
}

export interface SampleCollectControllerDeleteRequest {
    id: string;
}

export interface SampleCollectControllerGetRequest {
    id: string;
}

export interface SampleCollectControllerGetPlacesForOrganizationRequest {
    id: string;
}

export interface SampleCollectControllerGetWithExamsRequest {
    id: string;
}

export interface SampleCollectControllerMayDeleteRequest {
    id: string;
}

export interface SampleCollectControllerReadyToSignRequest {
    id: string;
}

export interface SampleCollectControllerRevertSignRequest {
    id: string;
}

export interface SampleCollectControllerSignRequest {
    id: string;
}

export interface SampleCollectControllerUpdateRequest {
    id: string;
    createUpdateSampleCollectDto: CreateUpdateSampleCollectDto;
}

export interface SampleCollectControllerUpdateWithNewOrganizationRequest {
    id: string;
    createUpdateOrganizationDto: CreateUpdateOrganizationDto;
}

export interface SampleControllerCreateRequest {
    createUpdateSampleDto: CreateUpdateSampleDto;
}

export interface SampleControllerDeleteRequest {
    id: string;
}

export interface SampleControllerGetRequest {
    id: string;
}

export interface SampleControllerGetByPakRequest {
    id: string;
}

export interface SampleControllerGetBySampleExamRequest {
    id: string;
}

export interface SampleControllerGetControlsSampleByDateOrgUnitRequest {
    dateFrom?: Date;
    dateTo?: Date;
    organizationalUnitId?: string;
}

export interface SampleControllerUpdateRequest {
    id: string;
    createUpdateSampleDto: CreateUpdateSampleDto;
}

export interface SampleExamControllerCreateBasedOnInspectedProductRequest {
    body: object;
}

export interface SampleExamControllerCreateBasedOnProductTypeRequest {
    body: object;
}

export interface SampleExamControllerCreateBasedOnProductTypeWithLinkedInspectionRequest {
    body: object;
}

export interface SampleExamControllerCreateWithNewProductTypeRequest {
    body: object;
}

export interface SampleExamControllerCreateWithNewProductTypeAndOrganizationRequest {
    body: object;
}

export interface SampleExamControllerDeleteRequest {
    id: string;
}

export interface SampleExamControllerGetRequest {
    id: string;
}

export interface SampleExamControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    organizationalUnit: string;
    organization: string;
    productType: string;
    collectDateFrom: string;
    collectDateTo: string;
    fileNumber: string;
    sysNumber: string;
}

export interface SampleExamControllerGetAllCountRequest {
    filterText: string;
}

export interface SampleExamControllerGetByInspectedProductRequest {
    id: string;
}

export interface SampleExamControllerGetExamsInRevisionByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface SampleExamControllerGetExamsInRevisionCountRequest {
    filterText: string;
}

export interface SampleExamControllerGetExamsWithoutApprovedResultsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface SampleExamControllerGetExamsWithoutApprovedResultsCountRequest {
    filterText: string;
}

export interface SampleExamControllerGetNotInspectedForUserRequest {
    id: string;
}

export interface SampleExamControllerGetPublishedByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface SampleExamControllerGetPublishedCountRequest {
    filterText: string;
}

export interface SampleExamControllerGetReadyForPublicationByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface SampleExamControllerGetReadyForPublicationCountRequest {
    filterText: string;
}

export interface SampleExamControllerGetViewModelRequest {
    id: string;
}

export interface SampleExamControllerGetWithdrawalExamsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface SampleExamControllerGetWithdrawalExamsCountRequest {
    filterText: string;
}

export interface SampleExamControllerMayDeleteRequest {
    id: string;
}

export interface SampleExamControllerMayFinishRequest {
    id: string;
}

export interface SampleExamControllerMayRevertSigningRequest {
    id: string;
}

export interface SampleExamControllerReadyToSignRequest {
    id: string;
}

export interface SampleExamControllerRevertSignRequest {
    id: string;
}

export interface SampleExamControllerSignRequest {
    id: string;
}

export interface SampleExamControllerUpdateRequest {
    id: string;
    createUpdateSampleExamDto: CreateUpdateSampleExamDto;
}

export interface SampleExamControllerUpdateWithNewSupplierRequest {
    id: string;
    createUpdateOrganizationDto: CreateUpdateOrganizationDto;
}

export interface TechAssessmentControllerCreateRequest {
    createUpdateTechAssessmentDto: CreateUpdateTechAssessmentDto;
}

export interface TechAssessmentControllerCreateWithOrganizationRequest {
    createUpdateTechAssessmentWithOrganizationDto: CreateUpdateTechAssessmentWithOrganizationDto;
}

export interface TechAssessmentControllerDeleteRequest {
    id: string;
}

export interface TechAssessmentControllerGetRequest {
    id: string;
}

export interface TechAssessmentControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface TechAssessmentControllerGetAllCountRequest {
    filterText: string;
}

export interface TechAssessmentControllerGetByOrganizationRequest {
    id: string;
}

export interface TechAssessmentControllerUpdateRequest {
    id: string;
    createUpdateTechAssessmentDto: CreateUpdateTechAssessmentDto;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async attachmentControllerCreateRaw(requestParameters: AttachmentControllerCreateRequest): Promise<runtime.ApiResponse<GetAttachmentDto>> {
        if (requestParameters.createUpdateAttachmentDto === null || requestParameters.createUpdateAttachmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateAttachmentDto','Required parameter requestParameters.createUpdateAttachmentDto was null or undefined when calling attachmentControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateAttachmentDtoToJSON(requestParameters.createUpdateAttachmentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAttachmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerCreate(createUpdateAttachmentDto: CreateUpdateAttachmentDto): Promise<GetAttachmentDto> {
        const response = await this.attachmentControllerCreateRaw({ createUpdateAttachmentDto: createUpdateAttachmentDto });
        return await response.value();
    }

    /**
     */
    async attachmentControllerDeleteRaw(requestParameters: AttachmentControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async attachmentControllerDelete(id: string): Promise<void> {
        await this.attachmentControllerDeleteRaw({ id: id });
    }

    /**
     */
    async attachmentControllerGetRaw(requestParameters: AttachmentControllerGetRequest): Promise<runtime.ApiResponse<GetAttachmentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAttachmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerGet(id: string): Promise<GetAttachmentDto> {
        const response = await this.attachmentControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetAllForObjectRaw(requestParameters: AttachmentControllerGetAllForObjectRequest): Promise<runtime.ApiResponse<Array<GetAttachmentDto>>> {
        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling attachmentControllerGetAllForObject.');
        }

        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling attachmentControllerGetAllForObject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.objectType !== undefined) {
            queryParameters['objectType'] = requestParameters.objectType;
        }

        if (requestParameters.objectId !== undefined) {
            queryParameters['objectId'] = requestParameters.objectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/forObject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAttachmentDtoFromJSON));
    }

    /**
     */
    async attachmentControllerGetAllForObject(objectType: string, objectId: string): Promise<Array<GetAttachmentDto>> {
        const response = await this.attachmentControllerGetAllForObjectRaw({ objectType: objectType, objectId: objectId });
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetFileDownloadRaw(requestParameters: AttachmentControllerGetFileDownloadRequest): Promise<runtime.ApiResponse<AttachmentFileContent>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerGetFileDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}/fileDownload`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFileContentFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerGetFileDownload(id: string): Promise<AttachmentFileContent> {
        const response = await this.attachmentControllerGetFileDownloadRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async certificateControllerCreateRaw(requestParameters: CertificateControllerCreateRequest): Promise<runtime.ApiResponse<GetCertificateDto>> {
        if (requestParameters.createUpdateCertificateDto === null || requestParameters.createUpdateCertificateDto === undefined) {
            throw new runtime.RequiredError('createUpdateCertificateDto','Required parameter requestParameters.createUpdateCertificateDto was null or undefined when calling certificateControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/certificate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCertificateDtoToJSON(requestParameters.createUpdateCertificateDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCertificateDtoFromJSON(jsonValue));
    }

    /**
     */
    async certificateControllerCreate(createUpdateCertificateDto: CreateUpdateCertificateDto): Promise<GetCertificateDto> {
        const response = await this.certificateControllerCreateRaw({ createUpdateCertificateDto: createUpdateCertificateDto });
        return await response.value();
    }

    /**
     */
    async certificateControllerCreateWithOrganizationRaw(requestParameters: CertificateControllerCreateWithOrganizationRequest): Promise<runtime.ApiResponse<GetCertificateDto>> {
        if (requestParameters.createUpdateCertificateWithOrganizationDto === null || requestParameters.createUpdateCertificateWithOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdateCertificateWithOrganizationDto','Required parameter requestParameters.createUpdateCertificateWithOrganizationDto was null or undefined when calling certificateControllerCreateWithOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/certificate/withOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCertificateWithOrganizationDtoToJSON(requestParameters.createUpdateCertificateWithOrganizationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCertificateDtoFromJSON(jsonValue));
    }

    /**
     */
    async certificateControllerCreateWithOrganization(createUpdateCertificateWithOrganizationDto: CreateUpdateCertificateWithOrganizationDto): Promise<GetCertificateDto> {
        const response = await this.certificateControllerCreateWithOrganizationRaw({ createUpdateCertificateWithOrganizationDto: createUpdateCertificateWithOrganizationDto });
        return await response.value();
    }

    /**
     */
    async certificateControllerDeleteRaw(requestParameters: CertificateControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling certificateControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/certificate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async certificateControllerDelete(id: string): Promise<void> {
        await this.certificateControllerDeleteRaw({ id: id });
    }

    /**
     */
    async certificateControllerGetRaw(requestParameters: CertificateControllerGetRequest): Promise<runtime.ApiResponse<GetCertificateDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling certificateControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/certificate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCertificateDtoFromJSON(jsonValue));
    }

    /**
     */
    async certificateControllerGet(id: string): Promise<GetCertificateDto> {
        const response = await this.certificateControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async certificateControllerGetAllByPortionRaw(requestParameters: CertificateControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetCertificateDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling certificateControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling certificateControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling certificateControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling certificateControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling certificateControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/certificate/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCertificateDtoFromJSON));
    }

    /**
     */
    async certificateControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetCertificateDto>> {
        const response = await this.certificateControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async certificateControllerGetAllCountRaw(requestParameters: CertificateControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling certificateControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/certificate/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async certificateControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.certificateControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async certificateControllerGetByOrganizationRaw(requestParameters: CertificateControllerGetByOrganizationRequest): Promise<runtime.ApiResponse<Array<GetCertificateDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling certificateControllerGetByOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/certificate/byOrganization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCertificateDtoFromJSON));
    }

    /**
     */
    async certificateControllerGetByOrganization(id: string): Promise<Array<GetCertificateDto>> {
        const response = await this.certificateControllerGetByOrganizationRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async certificateControllerUpdateRaw(requestParameters: CertificateControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling certificateControllerUpdate.');
        }

        if (requestParameters.createUpdateCertificateDto === null || requestParameters.createUpdateCertificateDto === undefined) {
            throw new runtime.RequiredError('createUpdateCertificateDto','Required parameter requestParameters.createUpdateCertificateDto was null or undefined when calling certificateControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/certificate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCertificateDtoToJSON(requestParameters.createUpdateCertificateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async certificateControllerUpdate(id: string, createUpdateCertificateDto: CreateUpdateCertificateDto): Promise<void> {
        await this.certificateControllerUpdateRaw({ id: id, createUpdateCertificateDto: createUpdateCertificateDto });
    }

    /**
     */
    async correctiveActionControllerApproveRaw(requestParameters: CorrectiveActionControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling correctiveActionControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/correctiveAction/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async correctiveActionControllerApprove(id: string): Promise<void> {
        await this.correctiveActionControllerApproveRaw({ id: id });
    }

    /**
     */
    async correctiveActionControllerCreateRaw(requestParameters: CorrectiveActionControllerCreateRequest): Promise<runtime.ApiResponse<GetCorrectiveActionDto>> {
        if (requestParameters.createUpdateCorrectiveActionDto === null || requestParameters.createUpdateCorrectiveActionDto === undefined) {
            throw new runtime.RequiredError('createUpdateCorrectiveActionDto','Required parameter requestParameters.createUpdateCorrectiveActionDto was null or undefined when calling correctiveActionControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/correctiveAction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCorrectiveActionDtoToJSON(requestParameters.createUpdateCorrectiveActionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorrectiveActionDtoFromJSON(jsonValue));
    }

    /**
     */
    async correctiveActionControllerCreate(createUpdateCorrectiveActionDto: CreateUpdateCorrectiveActionDto): Promise<GetCorrectiveActionDto> {
        const response = await this.correctiveActionControllerCreateRaw({ createUpdateCorrectiveActionDto: createUpdateCorrectiveActionDto });
        return await response.value();
    }

    /**
     */
    async correctiveActionControllerDeleteRaw(requestParameters: CorrectiveActionControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling correctiveActionControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/correctiveAction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async correctiveActionControllerDelete(id: string): Promise<void> {
        await this.correctiveActionControllerDeleteRaw({ id: id });
    }

    /**
     */
    async correctiveActionControllerGetRaw(requestParameters: CorrectiveActionControllerGetRequest): Promise<runtime.ApiResponse<GetCorrectiveActionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling correctiveActionControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/correctiveAction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorrectiveActionDtoFromJSON(jsonValue));
    }

    /**
     */
    async correctiveActionControllerGet(id: string): Promise<GetCorrectiveActionDto> {
        const response = await this.correctiveActionControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async correctiveActionControllerGetByInspectedProductRaw(requestParameters: CorrectiveActionControllerGetByInspectedProductRequest): Promise<runtime.ApiResponse<Array<GetCorrectiveActionDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling correctiveActionControllerGetByInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/correctiveAction/byInspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCorrectiveActionDtoFromJSON));
    }

    /**
     */
    async correctiveActionControllerGetByInspectedProduct(id: string): Promise<Array<GetCorrectiveActionDto>> {
        const response = await this.correctiveActionControllerGetByInspectedProductRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async correctiveActionControllerGetByProceedingRaw(requestParameters: CorrectiveActionControllerGetByProceedingRequest): Promise<runtime.ApiResponse<Array<GetCorrectiveActionDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling correctiveActionControllerGetByProceeding.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/correctiveAction/byProceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCorrectiveActionDtoFromJSON));
    }

    /**
     */
    async correctiveActionControllerGetByProceeding(id: string): Promise<Array<GetCorrectiveActionDto>> {
        const response = await this.correctiveActionControllerGetByProceedingRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async correctiveActionControllerRevertApproveRaw(requestParameters: CorrectiveActionControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling correctiveActionControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/correctiveAction/revertApprove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async correctiveActionControllerRevertApprove(id: string): Promise<void> {
        await this.correctiveActionControllerRevertApproveRaw({ id: id });
    }

    /**
     */
    async correctiveActionControllerUpdateRaw(requestParameters: CorrectiveActionControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling correctiveActionControllerUpdate.');
        }

        if (requestParameters.createUpdateCorrectiveActionDto === null || requestParameters.createUpdateCorrectiveActionDto === undefined) {
            throw new runtime.RequiredError('createUpdateCorrectiveActionDto','Required parameter requestParameters.createUpdateCorrectiveActionDto was null or undefined when calling correctiveActionControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/correctiveAction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCorrectiveActionDtoToJSON(requestParameters.createUpdateCorrectiveActionDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async correctiveActionControllerUpdate(id: string, createUpdateCorrectiveActionDto: CreateUpdateCorrectiveActionDto): Promise<void> {
        await this.correctiveActionControllerUpdateRaw({ id: id, createUpdateCorrectiveActionDto: createUpdateCorrectiveActionDto });
    }

    /**
     */
    async customsFeedbackControllerCreateRaw(requestParameters: CustomsFeedbackControllerCreateRequest): Promise<runtime.ApiResponse<GetCustomsFeedbackDto>> {
        if (requestParameters.createUpdateCustomsFeedbackDto === null || requestParameters.createUpdateCustomsFeedbackDto === undefined) {
            throw new runtime.RequiredError('createUpdateCustomsFeedbackDto','Required parameter requestParameters.createUpdateCustomsFeedbackDto was null or undefined when calling customsFeedbackControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customsFeedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCustomsFeedbackDtoToJSON(requestParameters.createUpdateCustomsFeedbackDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCustomsFeedbackDtoFromJSON(jsonValue));
    }

    /**
     */
    async customsFeedbackControllerCreate(createUpdateCustomsFeedbackDto: CreateUpdateCustomsFeedbackDto): Promise<GetCustomsFeedbackDto> {
        const response = await this.customsFeedbackControllerCreateRaw({ createUpdateCustomsFeedbackDto: createUpdateCustomsFeedbackDto });
        return await response.value();
    }

    /**
     */
    async customsFeedbackControllerCreateWithOrganizationRaw(requestParameters: CustomsFeedbackControllerCreateWithOrganizationRequest): Promise<runtime.ApiResponse<GetCustomsFeedbackDto>> {
        if (requestParameters.createUpdateCustomsFeedbackWithOrganizationDto === null || requestParameters.createUpdateCustomsFeedbackWithOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdateCustomsFeedbackWithOrganizationDto','Required parameter requestParameters.createUpdateCustomsFeedbackWithOrganizationDto was null or undefined when calling customsFeedbackControllerCreateWithOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customsFeedback/withOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCustomsFeedbackWithOrganizationDtoToJSON(requestParameters.createUpdateCustomsFeedbackWithOrganizationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCustomsFeedbackDtoFromJSON(jsonValue));
    }

    /**
     */
    async customsFeedbackControllerCreateWithOrganization(createUpdateCustomsFeedbackWithOrganizationDto: CreateUpdateCustomsFeedbackWithOrganizationDto): Promise<GetCustomsFeedbackDto> {
        const response = await this.customsFeedbackControllerCreateWithOrganizationRaw({ createUpdateCustomsFeedbackWithOrganizationDto: createUpdateCustomsFeedbackWithOrganizationDto });
        return await response.value();
    }

    /**
     */
    async customsFeedbackControllerDeleteRaw(requestParameters: CustomsFeedbackControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customsFeedbackControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customsFeedback/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async customsFeedbackControllerDelete(id: string): Promise<void> {
        await this.customsFeedbackControllerDeleteRaw({ id: id });
    }

    /**
     */
    async customsFeedbackControllerGetRaw(requestParameters: CustomsFeedbackControllerGetRequest): Promise<runtime.ApiResponse<GetCustomsFeedbackDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customsFeedbackControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customsFeedback/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCustomsFeedbackDtoFromJSON(jsonValue));
    }

    /**
     */
    async customsFeedbackControllerGet(id: string): Promise<GetCustomsFeedbackDto> {
        const response = await this.customsFeedbackControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async customsFeedbackControllerGetAllByPortionRaw(requestParameters: CustomsFeedbackControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetCustomsFeedbackDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling customsFeedbackControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling customsFeedbackControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling customsFeedbackControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling customsFeedbackControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling customsFeedbackControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customsFeedback/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCustomsFeedbackDtoFromJSON));
    }

    /**
     */
    async customsFeedbackControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetCustomsFeedbackDto>> {
        const response = await this.customsFeedbackControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async customsFeedbackControllerGetAllCountRaw(requestParameters: CustomsFeedbackControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling customsFeedbackControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customsFeedback/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async customsFeedbackControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.customsFeedbackControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async customsFeedbackControllerGetByOrganizationRaw(requestParameters: CustomsFeedbackControllerGetByOrganizationRequest): Promise<runtime.ApiResponse<Array<GetCustomsFeedbackDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customsFeedbackControllerGetByOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customsFeedback/byOrganization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCustomsFeedbackDtoFromJSON));
    }

    /**
     */
    async customsFeedbackControllerGetByOrganization(id: string): Promise<Array<GetCustomsFeedbackDto>> {
        const response = await this.customsFeedbackControllerGetByOrganizationRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async customsFeedbackControllerUpdateRaw(requestParameters: CustomsFeedbackControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customsFeedbackControllerUpdate.');
        }

        if (requestParameters.createUpdateCustomsFeedbackDto === null || requestParameters.createUpdateCustomsFeedbackDto === undefined) {
            throw new runtime.RequiredError('createUpdateCustomsFeedbackDto','Required parameter requestParameters.createUpdateCustomsFeedbackDto was null or undefined when calling customsFeedbackControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customsFeedback/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCustomsFeedbackDtoToJSON(requestParameters.createUpdateCustomsFeedbackDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async customsFeedbackControllerUpdate(id: string, createUpdateCustomsFeedbackDto: CreateUpdateCustomsFeedbackDto): Promise<void> {
        await this.customsFeedbackControllerUpdateRaw({ id: id, createUpdateCustomsFeedbackDto: createUpdateCustomsFeedbackDto });
    }

    /**
     */
    async dictionaryControllerChangeToInactiveRaw(requestParameters: DictionaryControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async dictionaryControllerChangeToInactive(id: string): Promise<void> {
        await this.dictionaryControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async dictionaryControllerCreateRaw(requestParameters: DictionaryControllerCreateRequest): Promise<runtime.ApiResponse<GetDictionaryDto>> {
        if (requestParameters.createUpdateDictionaryDto === null || requestParameters.createUpdateDictionaryDto === undefined) {
            throw new runtime.RequiredError('createUpdateDictionaryDto','Required parameter requestParameters.createUpdateDictionaryDto was null or undefined when calling dictionaryControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateDictionaryDtoToJSON(requestParameters.createUpdateDictionaryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryControllerCreate(createUpdateDictionaryDto: CreateUpdateDictionaryDto): Promise<GetDictionaryDto> {
        const response = await this.dictionaryControllerCreateRaw({ createUpdateDictionaryDto: createUpdateDictionaryDto });
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetRaw(requestParameters: DictionaryControllerGetRequest): Promise<runtime.ApiResponse<GetDictionaryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryControllerGet(id: string): Promise<GetDictionaryDto> {
        const response = await this.dictionaryControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetActiveRaw(): Promise<runtime.ApiResponse<Array<GetDictionaryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryDtoFromJSON));
    }

    /**
     */
    async dictionaryControllerGetActive(): Promise<Array<GetDictionaryDto>> {
        const response = await this.dictionaryControllerGetActiveRaw();
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetByTypeRaw(requestParameters: DictionaryControllerGetByTypeRequest): Promise<runtime.ApiResponse<Array<GetDictionaryDto>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling dictionaryControllerGetByType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/get/byType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryDtoFromJSON));
    }

    /**
     */
    async dictionaryControllerGetByType(name: string): Promise<Array<GetDictionaryDto>> {
        const response = await this.dictionaryControllerGetByTypeRaw({ name: name });
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetByTypeAndValueAndFirstLevelRaw(requestParameters: DictionaryControllerGetByTypeAndValueAndFirstLevelRequest): Promise<runtime.ApiResponse<Array<GetDictionaryDto>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling dictionaryControllerGetByTypeAndValueAndFirstLevel.');
        }

        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value','Required parameter requestParameters.value was null or undefined when calling dictionaryControllerGetByTypeAndValueAndFirstLevel.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.value !== undefined) {
            queryParameters['value'] = requestParameters.value;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/get/byTypeAndValueAndFirstLevel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryDtoFromJSON));
    }

    /**
     */
    async dictionaryControllerGetByTypeAndValueAndFirstLevel(name: string, value: string): Promise<Array<GetDictionaryDto>> {
        const response = await this.dictionaryControllerGetByTypeAndValueAndFirstLevelRaw({ name: name, value: value });
        return await response.value();
    }

    /**
     */
    async dictionaryControllerUpdateRaw(requestParameters: DictionaryControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerUpdate.');
        }

        if (requestParameters.createUpdateDictionaryDto === null || requestParameters.createUpdateDictionaryDto === undefined) {
            throw new runtime.RequiredError('createUpdateDictionaryDto','Required parameter requestParameters.createUpdateDictionaryDto was null or undefined when calling dictionaryControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateDictionaryDtoToJSON(requestParameters.createUpdateDictionaryDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async dictionaryControllerUpdate(id: string, createUpdateDictionaryDto: CreateUpdateDictionaryDto): Promise<void> {
        await this.dictionaryControllerUpdateRaw({ id: id, createUpdateDictionaryDto: createUpdateDictionaryDto });
    }

    /**
     */
    async dictionaryTypeControllerGetRaw(requestParameters: DictionaryTypeControllerGetRequest): Promise<runtime.ApiResponse<GetDictionaryTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryTypeControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionaryType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryTypeControllerGet(id: string): Promise<GetDictionaryTypeDto> {
        const response = await this.dictionaryTypeControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dictionaryTypeControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetDictionaryTypeDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionaryType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryTypeDtoFromJSON));
    }

    /**
     */
    async dictionaryTypeControllerGetAll(): Promise<Array<GetDictionaryTypeDto>> {
        const response = await this.dictionaryTypeControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async documentationControllerGetForFilenameRaw(requestParameters: DocumentationControllerGetForFilenameRequest): Promise<runtime.ApiResponse<GetDocumentationDto>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling documentationControllerGetForFilename.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filename !== undefined) {
            queryParameters['filename'] = requestParameters.filename;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documentation/byFilename`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDocumentationDtoFromJSON(jsonValue));
    }

    /**
     */
    async documentationControllerGetForFilename(filename: string): Promise<GetDocumentationDto> {
        const response = await this.documentationControllerGetForFilenameRaw({ filename: filename });
        return await response.value();
    }

    /**
     */
    async documentationControllerGetVersionInfoRaw(): Promise<runtime.ApiResponse<GetVersionInfoDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documentation/getVersionInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVersionInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async documentationControllerGetVersionInfo(): Promise<GetVersionInfoDto> {
        const response = await this.documentationControllerGetVersionInfoRaw();
        return await response.value();
    }

    /**
     */
    async employeeControllerChangeToInactiveRaw(requestParameters: EmployeeControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async employeeControllerChangeToInactive(id: string): Promise<void> {
        await this.employeeControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async employeeControllerCreateRaw(requestParameters: EmployeeControllerCreateRequest): Promise<runtime.ApiResponse<GetEmployeeDto>> {
        if (requestParameters.createUpdateEmployeeDto === null || requestParameters.createUpdateEmployeeDto === undefined) {
            throw new runtime.RequiredError('createUpdateEmployeeDto','Required parameter requestParameters.createUpdateEmployeeDto was null or undefined when calling employeeControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEmployeeDtoToJSON(requestParameters.createUpdateEmployeeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmployeeDtoFromJSON(jsonValue));
    }

    /**
     */
    async employeeControllerCreate(createUpdateEmployeeDto: CreateUpdateEmployeeDto): Promise<GetEmployeeDto> {
        const response = await this.employeeControllerCreateRaw({ createUpdateEmployeeDto: createUpdateEmployeeDto });
        return await response.value();
    }

    /**
     */
    async employeeControllerGetRaw(requestParameters: EmployeeControllerGetRequest): Promise<runtime.ApiResponse<GetEmployeeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmployeeDtoFromJSON(jsonValue));
    }

    /**
     */
    async employeeControllerGet(id: string): Promise<GetEmployeeDto> {
        const response = await this.employeeControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async employeeControllerGetActiveRaw(): Promise<runtime.ApiResponse<Array<GetEmployeeDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeDtoFromJSON));
    }

    /**
     */
    async employeeControllerGetActive(): Promise<Array<GetEmployeeDto>> {
        const response = await this.employeeControllerGetActiveRaw();
        return await response.value();
    }

    /**
     */
    async employeeControllerGetAllByOrganizationalUnitRaw(requestParameters: EmployeeControllerGetAllByOrganizationalUnitRequest): Promise<runtime.ApiResponse<Array<GetEmployeeDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeControllerGetAllByOrganizationalUnit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/getAllByOrganizationalUnit/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeDtoFromJSON));
    }

    /**
     */
    async employeeControllerGetAllByOrganizationalUnit(id: string): Promise<Array<GetEmployeeDto>> {
        const response = await this.employeeControllerGetAllByOrganizationalUnitRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async employeeControllerGetCurrentRaw(): Promise<runtime.ApiResponse<GetEmployeeDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/loggedIn`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmployeeDtoFromJSON(jsonValue));
    }

    /**
     */
    async employeeControllerGetCurrent(): Promise<GetEmployeeDto> {
        const response = await this.employeeControllerGetCurrentRaw();
        return await response.value();
    }

    /**
     */
    async employeeControllerGetForCurrentUserRaw(): Promise<runtime.ApiResponse<Array<GetEmployeeDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/for/currentUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeDtoFromJSON));
    }

    /**
     */
    async employeeControllerGetForCurrentUser(): Promise<Array<GetEmployeeDto>> {
        const response = await this.employeeControllerGetForCurrentUserRaw();
        return await response.value();
    }

    /**
     */
    async employeeControllerUpdateRaw(requestParameters: EmployeeControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeControllerUpdate.');
        }

        if (requestParameters.createUpdateEmployeeDto === null || requestParameters.createUpdateEmployeeDto === undefined) {
            throw new runtime.RequiredError('createUpdateEmployeeDto','Required parameter requestParameters.createUpdateEmployeeDto was null or undefined when calling employeeControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEmployeeDtoToJSON(requestParameters.createUpdateEmployeeDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async employeeControllerUpdate(id: string, createUpdateEmployeeDto: CreateUpdateEmployeeDto): Promise<void> {
        await this.employeeControllerUpdateRaw({ id: id, createUpdateEmployeeDto: createUpdateEmployeeDto });
    }

    /**
     */
    async essentialFeatureControllerCreateRaw(requestParameters: EssentialFeatureControllerCreateRequest): Promise<runtime.ApiResponse<GetEssentialFeatureDto>> {
        if (requestParameters.createUpdateEssentialFeatureDto === null || requestParameters.createUpdateEssentialFeatureDto === undefined) {
            throw new runtime.RequiredError('createUpdateEssentialFeatureDto','Required parameter requestParameters.createUpdateEssentialFeatureDto was null or undefined when calling essentialFeatureControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEssentialFeatureDtoToJSON(requestParameters.createUpdateEssentialFeatureDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEssentialFeatureDtoFromJSON(jsonValue));
    }

    /**
     */
    async essentialFeatureControllerCreate(createUpdateEssentialFeatureDto: CreateUpdateEssentialFeatureDto): Promise<GetEssentialFeatureDto> {
        const response = await this.essentialFeatureControllerCreateRaw({ createUpdateEssentialFeatureDto: createUpdateEssentialFeatureDto });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerDeleteRaw(requestParameters: EssentialFeatureControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling essentialFeatureControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async essentialFeatureControllerDelete(id: string): Promise<void> {
        await this.essentialFeatureControllerDeleteRaw({ id: id });
    }

    /**
     */
    async essentialFeatureControllerGetRaw(requestParameters: EssentialFeatureControllerGetRequest): Promise<runtime.ApiResponse<GetEssentialFeatureDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling essentialFeatureControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEssentialFeatureDtoFromJSON(jsonValue));
    }

    /**
     */
    async essentialFeatureControllerGet(id: string): Promise<GetEssentialFeatureDto> {
        const response = await this.essentialFeatureControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetBySampleExamRaw(requestParameters: EssentialFeatureControllerGetBySampleExamRequest): Promise<runtime.ApiResponse<Array<GetEssentialFeatureDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling essentialFeatureControllerGetBySampleExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/bySampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEssentialFeatureDtoFromJSON));
    }

    /**
     */
    async essentialFeatureControllerGetBySampleExam(id: string): Promise<Array<GetEssentialFeatureDto>> {
        const response = await this.essentialFeatureControllerGetBySampleExamRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetExamMethodsByPatternRaw(requestParameters: EssentialFeatureControllerGetExamMethodsByPatternRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling essentialFeatureControllerGetExamMethodsByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling essentialFeatureControllerGetExamMethodsByPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/get/examMethods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async essentialFeatureControllerGetExamMethodsByPattern(pattern: string, limit: number): Promise<Array<string>> {
        const response = await this.essentialFeatureControllerGetExamMethodsByPatternRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetFeaturesByPatternRaw(requestParameters: EssentialFeatureControllerGetFeaturesByPatternRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling essentialFeatureControllerGetFeaturesByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling essentialFeatureControllerGetFeaturesByPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/get/features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async essentialFeatureControllerGetFeaturesByPattern(pattern: string, limit: number): Promise<Array<string>> {
        const response = await this.essentialFeatureControllerGetFeaturesByPatternRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetFunctionalPropertiesByPatternRaw(requestParameters: EssentialFeatureControllerGetFunctionalPropertiesByPatternRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling essentialFeatureControllerGetFunctionalPropertiesByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling essentialFeatureControllerGetFunctionalPropertiesByPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/get/functionalProperties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async essentialFeatureControllerGetFunctionalPropertiesByPattern(pattern: string, limit: number): Promise<Array<string>> {
        const response = await this.essentialFeatureControllerGetFunctionalPropertiesByPatternRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetUniqueExamMethodsByPortionRaw(requestParameters: EssentialFeatureControllerGetUniqueExamMethodsByPortionRequest): Promise<runtime.ApiResponse<Array<GetEssentialFeatureDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling essentialFeatureControllerGetUniqueExamMethodsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling essentialFeatureControllerGetUniqueExamMethodsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling essentialFeatureControllerGetUniqueExamMethodsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling essentialFeatureControllerGetUniqueExamMethodsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling essentialFeatureControllerGetUniqueExamMethodsByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/searchExamMethods/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEssentialFeatureDtoFromJSON));
    }

    /**
     */
    async essentialFeatureControllerGetUniqueExamMethodsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetEssentialFeatureDto>> {
        const response = await this.essentialFeatureControllerGetUniqueExamMethodsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetUniqueExamMethodsCountRaw(requestParameters: EssentialFeatureControllerGetUniqueExamMethodsCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling essentialFeatureControllerGetUniqueExamMethodsCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/searchExamMethods/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async essentialFeatureControllerGetUniqueExamMethodsCount(filterText: string): Promise<number> {
        const response = await this.essentialFeatureControllerGetUniqueExamMethodsCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetUniqueFeaturesByPortionRaw(requestParameters: EssentialFeatureControllerGetUniqueFeaturesByPortionRequest): Promise<runtime.ApiResponse<Array<GetEssentialFeatureDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling essentialFeatureControllerGetUniqueFeaturesByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling essentialFeatureControllerGetUniqueFeaturesByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling essentialFeatureControllerGetUniqueFeaturesByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling essentialFeatureControllerGetUniqueFeaturesByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling essentialFeatureControllerGetUniqueFeaturesByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/searchFeatures/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEssentialFeatureDtoFromJSON));
    }

    /**
     */
    async essentialFeatureControllerGetUniqueFeaturesByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetEssentialFeatureDto>> {
        const response = await this.essentialFeatureControllerGetUniqueFeaturesByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetUniqueFeaturesCountRaw(requestParameters: EssentialFeatureControllerGetUniqueFeaturesCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling essentialFeatureControllerGetUniqueFeaturesCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/searchFeatures/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async essentialFeatureControllerGetUniqueFeaturesCount(filterText: string): Promise<number> {
        const response = await this.essentialFeatureControllerGetUniqueFeaturesCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetUniqueFunctionalPropertiesByPortionRaw(requestParameters: EssentialFeatureControllerGetUniqueFunctionalPropertiesByPortionRequest): Promise<runtime.ApiResponse<Array<GetEssentialFeatureDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling essentialFeatureControllerGetUniqueFunctionalPropertiesByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling essentialFeatureControllerGetUniqueFunctionalPropertiesByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling essentialFeatureControllerGetUniqueFunctionalPropertiesByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling essentialFeatureControllerGetUniqueFunctionalPropertiesByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling essentialFeatureControllerGetUniqueFunctionalPropertiesByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/searchFunctionalProperties/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEssentialFeatureDtoFromJSON));
    }

    /**
     */
    async essentialFeatureControllerGetUniqueFunctionalPropertiesByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetEssentialFeatureDto>> {
        const response = await this.essentialFeatureControllerGetUniqueFunctionalPropertiesByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerGetUniqueFunctionalPropertiesCountRaw(requestParameters: EssentialFeatureControllerGetUniqueFunctionalPropertiesCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling essentialFeatureControllerGetUniqueFunctionalPropertiesCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/searchFunctionalProperties/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async essentialFeatureControllerGetUniqueFunctionalPropertiesCount(filterText: string): Promise<number> {
        const response = await this.essentialFeatureControllerGetUniqueFunctionalPropertiesCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async essentialFeatureControllerOverwriteExamMethodsRaw(requestParameters: EssentialFeatureControllerOverwriteExamMethodsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.oldExamMethod === null || requestParameters.oldExamMethod === undefined) {
            throw new runtime.RequiredError('oldExamMethod','Required parameter requestParameters.oldExamMethod was null or undefined when calling essentialFeatureControllerOverwriteExamMethods.');
        }

        if (requestParameters.newExamMethod === null || requestParameters.newExamMethod === undefined) {
            throw new runtime.RequiredError('newExamMethod','Required parameter requestParameters.newExamMethod was null or undefined when calling essentialFeatureControllerOverwriteExamMethods.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.oldExamMethod !== undefined) {
            queryParameters['oldExamMethod'] = requestParameters.oldExamMethod;
        }

        if (requestParameters.newExamMethod !== undefined) {
            queryParameters['newExamMethod'] = requestParameters.newExamMethod;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/overwriteExamMethods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async essentialFeatureControllerOverwriteExamMethods(oldExamMethod: string, newExamMethod: string): Promise<void> {
        await this.essentialFeatureControllerOverwriteExamMethodsRaw({ oldExamMethod: oldExamMethod, newExamMethod: newExamMethod });
    }

    /**
     */
    async essentialFeatureControllerOverwriteFeaturesRaw(requestParameters: EssentialFeatureControllerOverwriteFeaturesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.oldFeature === null || requestParameters.oldFeature === undefined) {
            throw new runtime.RequiredError('oldFeature','Required parameter requestParameters.oldFeature was null or undefined when calling essentialFeatureControllerOverwriteFeatures.');
        }

        if (requestParameters.newFeature === null || requestParameters.newFeature === undefined) {
            throw new runtime.RequiredError('newFeature','Required parameter requestParameters.newFeature was null or undefined when calling essentialFeatureControllerOverwriteFeatures.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.oldFeature !== undefined) {
            queryParameters['oldFeature'] = requestParameters.oldFeature;
        }

        if (requestParameters.newFeature !== undefined) {
            queryParameters['newFeature'] = requestParameters.newFeature;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/overwriteFeatures`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async essentialFeatureControllerOverwriteFeatures(oldFeature: string, newFeature: string): Promise<void> {
        await this.essentialFeatureControllerOverwriteFeaturesRaw({ oldFeature: oldFeature, newFeature: newFeature });
    }

    /**
     */
    async essentialFeatureControllerOverwriteFunctionalPropertiesRaw(requestParameters: EssentialFeatureControllerOverwriteFunctionalPropertiesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.oldFunctionalProperty === null || requestParameters.oldFunctionalProperty === undefined) {
            throw new runtime.RequiredError('oldFunctionalProperty','Required parameter requestParameters.oldFunctionalProperty was null or undefined when calling essentialFeatureControllerOverwriteFunctionalProperties.');
        }

        if (requestParameters.newFunctionalProperty === null || requestParameters.newFunctionalProperty === undefined) {
            throw new runtime.RequiredError('newFunctionalProperty','Required parameter requestParameters.newFunctionalProperty was null or undefined when calling essentialFeatureControllerOverwriteFunctionalProperties.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.oldFunctionalProperty !== undefined) {
            queryParameters['oldFunctionalProperty'] = requestParameters.oldFunctionalProperty;
        }

        if (requestParameters.newFunctionalProperty !== undefined) {
            queryParameters['newFunctionalProperty'] = requestParameters.newFunctionalProperty;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/overwriteFunctionalProperties`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async essentialFeatureControllerOverwriteFunctionalProperties(oldFunctionalProperty: string, newFunctionalProperty: string): Promise<void> {
        await this.essentialFeatureControllerOverwriteFunctionalPropertiesRaw({ oldFunctionalProperty: oldFunctionalProperty, newFunctionalProperty: newFunctionalProperty });
    }

    /**
     */
    async essentialFeatureControllerUpdateRaw(requestParameters: EssentialFeatureControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling essentialFeatureControllerUpdate.');
        }

        if (requestParameters.createUpdateEssentialFeatureDto === null || requestParameters.createUpdateEssentialFeatureDto === undefined) {
            throw new runtime.RequiredError('createUpdateEssentialFeatureDto','Required parameter requestParameters.createUpdateEssentialFeatureDto was null or undefined when calling essentialFeatureControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/essentialFeature/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEssentialFeatureDtoToJSON(requestParameters.createUpdateEssentialFeatureDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async essentialFeatureControllerUpdate(id: string, createUpdateEssentialFeatureDto: CreateUpdateEssentialFeatureDto): Promise<void> {
        await this.essentialFeatureControllerUpdateRaw({ id: id, createUpdateEssentialFeatureDto: createUpdateEssentialFeatureDto });
    }

    /**
     */
    async examResultControllerApproveRaw(requestParameters: ExamResultControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examResultControllerApprove(id: string): Promise<void> {
        await this.examResultControllerApproveRaw({ id: id });
    }

    /**
     */
    async examResultControllerCreateRaw(requestParameters: ExamResultControllerCreateRequest): Promise<runtime.ApiResponse<GetExamResultDto>> {
        if (requestParameters.createUpdateExamResultDto === null || requestParameters.createUpdateExamResultDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamResultDto','Required parameter requestParameters.createUpdateExamResultDto was null or undefined when calling examResultControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamResultDtoToJSON(requestParameters.createUpdateExamResultDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async examResultControllerCreate(createUpdateExamResultDto: CreateUpdateExamResultDto): Promise<GetExamResultDto> {
        const response = await this.examResultControllerCreateRaw({ createUpdateExamResultDto: createUpdateExamResultDto });
        return await response.value();
    }

    /**
     */
    async examResultControllerDeleteRaw(requestParameters: ExamResultControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examResultControllerDelete(id: string): Promise<void> {
        await this.examResultControllerDeleteRaw({ id: id });
    }

    /**
     */
    async examResultControllerGetRaw(requestParameters: ExamResultControllerGetRequest): Promise<runtime.ApiResponse<GetExamResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async examResultControllerGet(id: string): Promise<GetExamResultDto> {
        const response = await this.examResultControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examResultControllerGetBySampleIdRaw(requestParameters: ExamResultControllerGetBySampleIdRequest): Promise<runtime.ApiResponse<GetExamResultDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerGetBySampleId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/bySample/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async examResultControllerGetBySampleId(id: string): Promise<GetExamResultDto> {
        const response = await this.examResultControllerGetBySampleIdRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examResultControllerMarkAsUnpublishableRaw(requestParameters: ExamResultControllerMarkAsUnpublishableRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerMarkAsUnpublishable.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/markAsUnpublishable/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examResultControllerMarkAsUnpublishable(id: string): Promise<void> {
        await this.examResultControllerMarkAsUnpublishableRaw({ id: id });
    }

    /**
     */
    async examResultControllerMayBeApprovedRaw(requestParameters: ExamResultControllerMayBeApprovedRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerMayBeApproved.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/mayBeApproved/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async examResultControllerMayBeApproved(id: string): Promise<ValidationStatus> {
        const response = await this.examResultControllerMayBeApprovedRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examResultControllerMayBePublishedRaw(requestParameters: ExamResultControllerMayBePublishedRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerMayBePublished.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/mayBePublished/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async examResultControllerMayBePublished(id: string): Promise<ValidationStatus> {
        const response = await this.examResultControllerMayBePublishedRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examResultControllerPublishRaw(requestParameters: ExamResultControllerPublishRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/publish/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examResultControllerPublish(id: string): Promise<void> {
        await this.examResultControllerPublishRaw({ id: id });
    }

    /**
     */
    async examResultControllerRevertApproveRaw(requestParameters: ExamResultControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.revertApproveDto === null || requestParameters.revertApproveDto === undefined) {
            throw new runtime.RequiredError('revertApproveDto','Required parameter requestParameters.revertApproveDto was null or undefined when calling examResultControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/revertApprove/withAReason`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RevertApproveDtoToJSON(requestParameters.revertApproveDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examResultControllerRevertApprove(revertApproveDto: RevertApproveDto): Promise<void> {
        await this.examResultControllerRevertApproveRaw({ revertApproveDto: revertApproveDto });
    }

    /**
     */
    async examResultControllerRevertMarkAsUnpublishableRaw(requestParameters: ExamResultControllerRevertMarkAsUnpublishableRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerRevertMarkAsUnpublishable.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/revertMarkAsUnpublishable/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examResultControllerRevertMarkAsUnpublishable(id: string): Promise<void> {
        await this.examResultControllerRevertMarkAsUnpublishableRaw({ id: id });
    }

    /**
     */
    async examResultControllerRevertPublishRaw(requestParameters: ExamResultControllerRevertPublishRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerRevertPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/revertPublish/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examResultControllerRevertPublish(id: string): Promise<void> {
        await this.examResultControllerRevertPublishRaw({ id: id });
    }

    /**
     */
    async examResultControllerUpdateRaw(requestParameters: ExamResultControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultControllerUpdate.');
        }

        if (requestParameters.createUpdateExamResultDto === null || requestParameters.createUpdateExamResultDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamResultDto','Required parameter requestParameters.createUpdateExamResultDto was null or undefined when calling examResultControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResult/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamResultDtoToJSON(requestParameters.createUpdateExamResultDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examResultControllerUpdate(id: string, createUpdateExamResultDto: CreateUpdateExamResultDto): Promise<void> {
        await this.examResultControllerUpdateRaw({ id: id, createUpdateExamResultDto: createUpdateExamResultDto });
    }

    /**
     */
    async examResultHistoryControllerCreateRaw(requestParameters: ExamResultHistoryControllerCreateRequest): Promise<runtime.ApiResponse<GetExamResultHistoryDto>> {
        if (requestParameters.createUpdateExamResultHistoryDto === null || requestParameters.createUpdateExamResultHistoryDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamResultHistoryDto','Required parameter requestParameters.createUpdateExamResultHistoryDto was null or undefined when calling examResultHistoryControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResultHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamResultHistoryDtoToJSON(requestParameters.createUpdateExamResultHistoryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamResultHistoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async examResultHistoryControllerCreate(createUpdateExamResultHistoryDto: CreateUpdateExamResultHistoryDto): Promise<GetExamResultHistoryDto> {
        const response = await this.examResultHistoryControllerCreateRaw({ createUpdateExamResultHistoryDto: createUpdateExamResultHistoryDto });
        return await response.value();
    }

    /**
     */
    async examResultHistoryControllerGetBySampleRaw(requestParameters: ExamResultHistoryControllerGetBySampleRequest): Promise<runtime.ApiResponse<Array<GetExamResultHistoryDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultHistoryControllerGetBySample.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResultHistory/bySample/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamResultHistoryDtoFromJSON));
    }

    /**
     */
    async examResultHistoryControllerGetBySample(id: string): Promise<Array<GetExamResultHistoryDto>> {
        const response = await this.examResultHistoryControllerGetBySampleRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examResultHistoryControllerGetLastApprovalDateRaw(requestParameters: ExamResultHistoryControllerGetLastApprovalDateRequest): Promise<runtime.ApiResponse<GetLastApprovalDateDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examResultHistoryControllerGetLastApprovalDate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examResultHistory/{id}/lastApprovalDate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastApprovalDateDtoFromJSON(jsonValue));
    }

    /**
     */
    async examResultHistoryControllerGetLastApprovalDate(id: string): Promise<GetLastApprovalDateDto> {
        const response = await this.examResultHistoryControllerGetLastApprovalDateRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async gusApiControllerGetByNipRaw(requestParameters: GusApiControllerGetByNipRequest): Promise<runtime.ApiResponse<CompanyDto>> {
        if (requestParameters.nip === null || requestParameters.nip === undefined) {
            throw new runtime.RequiredError('nip','Required parameter requestParameters.nip was null or undefined when calling gusApiControllerGetByNip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.nip !== undefined) {
            queryParameters['nip'] = requestParameters.nip;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/gusApi/nip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async gusApiControllerGetByNip(nip: string): Promise<CompanyDto> {
        const response = await this.gusApiControllerGetByNipRaw({ nip: nip });
        return await response.value();
    }

    /**
     */
    async inspectedProductControllerCreateBasedOnProductTypeRaw(requestParameters: InspectedProductControllerCreateBasedOnProductTypeRequest): Promise<runtime.ApiResponse<GetInspectedProductDto>> {
        if (requestParameters.createBasedOnProductTypeParams === null || requestParameters.createBasedOnProductTypeParams === undefined) {
            throw new runtime.RequiredError('createBasedOnProductTypeParams','Required parameter requestParameters.createBasedOnProductTypeParams was null or undefined when calling inspectedProductControllerCreateBasedOnProductType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/basedOnProductType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBasedOnProductTypeParamsToJSON(requestParameters.createBasedOnProductTypeParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectedProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectedProductControllerCreateBasedOnProductType(createBasedOnProductTypeParams: CreateBasedOnProductTypeParams): Promise<GetInspectedProductDto> {
        const response = await this.inspectedProductControllerCreateBasedOnProductTypeRaw({ createBasedOnProductTypeParams: createBasedOnProductTypeParams });
        return await response.value();
    }

    /**
     */
    async inspectedProductControllerCreateWithNewProducerAndProductTypeRaw(requestParameters: InspectedProductControllerCreateWithNewProducerAndProductTypeRequest): Promise<runtime.ApiResponse<GetInspectedProductDto>> {
        if (requestParameters.createWithNewProducerAndProductTypeParams === null || requestParameters.createWithNewProducerAndProductTypeParams === undefined) {
            throw new runtime.RequiredError('createWithNewProducerAndProductTypeParams','Required parameter requestParameters.createWithNewProducerAndProductTypeParams was null or undefined when calling inspectedProductControllerCreateWithNewProducerAndProductType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/withNewProducerAndProductType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWithNewProducerAndProductTypeParamsToJSON(requestParameters.createWithNewProducerAndProductTypeParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectedProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectedProductControllerCreateWithNewProducerAndProductType(createWithNewProducerAndProductTypeParams: CreateWithNewProducerAndProductTypeParams): Promise<GetInspectedProductDto> {
        const response = await this.inspectedProductControllerCreateWithNewProducerAndProductTypeRaw({ createWithNewProducerAndProductTypeParams: createWithNewProducerAndProductTypeParams });
        return await response.value();
    }

    /**
     */
    async inspectedProductControllerCreateWithNewProductTypeRaw(requestParameters: InspectedProductControllerCreateWithNewProductTypeRequest): Promise<runtime.ApiResponse<GetInspectedProductDto>> {
        if (requestParameters.createWithNewProductTypeParams === null || requestParameters.createWithNewProductTypeParams === undefined) {
            throw new runtime.RequiredError('createWithNewProductTypeParams','Required parameter requestParameters.createWithNewProductTypeParams was null or undefined when calling inspectedProductControllerCreateWithNewProductType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/withNewProductType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWithNewProductTypeParamsToJSON(requestParameters.createWithNewProductTypeParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectedProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectedProductControllerCreateWithNewProductType(createWithNewProductTypeParams: CreateWithNewProductTypeParams): Promise<GetInspectedProductDto> {
        const response = await this.inspectedProductControllerCreateWithNewProductTypeRaw({ createWithNewProductTypeParams: createWithNewProductTypeParams });
        return await response.value();
    }

    /**
     */
    async inspectedProductControllerDeleteRaw(requestParameters: InspectedProductControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectedProductControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectedProductControllerDelete(id: string): Promise<void> {
        await this.inspectedProductControllerDeleteRaw({ id: id });
    }

    /**
     */
    async inspectedProductControllerGetRaw(requestParameters: InspectedProductControllerGetRequest): Promise<runtime.ApiResponse<GetInspectedProductDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectedProductControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectedProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectedProductControllerGet(id: string): Promise<GetInspectedProductDto> {
        const response = await this.inspectedProductControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectedProductControllerGetByInspectionRaw(requestParameters: InspectedProductControllerGetByInspectionRequest): Promise<runtime.ApiResponse<Array<GetInspectedProductDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectedProductControllerGetByInspection.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/byInspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectedProductDtoFromJSON));
    }

    /**
     */
    async inspectedProductControllerGetByInspection(id: string): Promise<Array<GetInspectedProductDto>> {
        const response = await this.inspectedProductControllerGetByInspectionRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectedProductControllerGetByPakRaw(requestParameters: InspectedProductControllerGetByPakRequest): Promise<runtime.ApiResponse<Array<GetInspectedProductDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectedProductControllerGetByPak.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/byPak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectedProductDtoFromJSON));
    }

    /**
     */
    async inspectedProductControllerGetByPak(id: string): Promise<Array<GetInspectedProductDto>> {
        const response = await this.inspectedProductControllerGetByPakRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectedProductControllerGetBySampleExamRaw(requestParameters: InspectedProductControllerGetBySampleExamRequest): Promise<runtime.ApiResponse<Array<GetInspectedProductDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectedProductControllerGetBySampleExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/bySampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectedProductDtoFromJSON));
    }

    /**
     */
    async inspectedProductControllerGetBySampleExam(id: string): Promise<Array<GetInspectedProductDto>> {
        const response = await this.inspectedProductControllerGetBySampleExamRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectedProductControllerUpdateRaw(requestParameters: InspectedProductControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectedProductControllerUpdate.');
        }

        if (requestParameters.createUpdateInspectedProductDto === null || requestParameters.createUpdateInspectedProductDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectedProductDto','Required parameter requestParameters.createUpdateInspectedProductDto was null or undefined when calling inspectedProductControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectedProductDtoToJSON(requestParameters.createUpdateInspectedProductDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectedProductControllerUpdate(id: string, createUpdateInspectedProductDto: CreateUpdateInspectedProductDto): Promise<void> {
        await this.inspectedProductControllerUpdateRaw({ id: id, createUpdateInspectedProductDto: createUpdateInspectedProductDto });
    }

    /**
     */
    async inspectedProductControllerValidateNewRaw(requestParameters: InspectedProductControllerValidateNewRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.createBasedOnProductTypeParams === null || requestParameters.createBasedOnProductTypeParams === undefined) {
            throw new runtime.RequiredError('createBasedOnProductTypeParams','Required parameter requestParameters.createBasedOnProductTypeParams was null or undefined when calling inspectedProductControllerValidateNew.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectedProduct/validateNew`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBasedOnProductTypeParamsToJSON(requestParameters.createBasedOnProductTypeParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async inspectedProductControllerValidateNew(createBasedOnProductTypeParams: CreateBasedOnProductTypeParams): Promise<ValidationStatus> {
        const response = await this.inspectedProductControllerValidateNewRaw({ createBasedOnProductTypeParams: createBasedOnProductTypeParams });
        return await response.value();
    }

    /**
     */
    async inspectionControllerCreateBasedOnMetricRaw(requestParameters: InspectionControllerCreateBasedOnMetricRequest): Promise<runtime.ApiResponse<GetInspectionDto>> {
        if (requestParameters.createInspectionBasedOnMetric === null || requestParameters.createInspectionBasedOnMetric === undefined) {
            throw new runtime.RequiredError('createInspectionBasedOnMetric','Required parameter requestParameters.createInspectionBasedOnMetric was null or undefined when calling inspectionControllerCreateBasedOnMetric.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/basedOnMetric`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInspectionBasedOnMetricToJSON(requestParameters.createInspectionBasedOnMetric),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionControllerCreateBasedOnMetric(createInspectionBasedOnMetric: CreateInspectionBasedOnMetric): Promise<GetInspectionDto> {
        const response = await this.inspectionControllerCreateBasedOnMetricRaw({ createInspectionBasedOnMetric: createInspectionBasedOnMetric });
        return await response.value();
    }

    /**
     */
    async inspectionControllerCreateBasedOnMetricWithOrganizationRaw(requestParameters: InspectionControllerCreateBasedOnMetricWithOrganizationRequest): Promise<runtime.ApiResponse<GetInspectionDto>> {
        if (requestParameters.createInspectionBasedOnMetricWithOrganization === null || requestParameters.createInspectionBasedOnMetricWithOrganization === undefined) {
            throw new runtime.RequiredError('createInspectionBasedOnMetricWithOrganization','Required parameter requestParameters.createInspectionBasedOnMetricWithOrganization was null or undefined when calling inspectionControllerCreateBasedOnMetricWithOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/basedOnMetricWithOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInspectionBasedOnMetricWithOrganizationToJSON(requestParameters.createInspectionBasedOnMetricWithOrganization),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionControllerCreateBasedOnMetricWithOrganization(createInspectionBasedOnMetricWithOrganization: CreateInspectionBasedOnMetricWithOrganization): Promise<GetInspectionDto> {
        const response = await this.inspectionControllerCreateBasedOnMetricWithOrganizationRaw({ createInspectionBasedOnMetricWithOrganization: createInspectionBasedOnMetricWithOrganization });
        return await response.value();
    }

    /**
     */
    async inspectionControllerDeleteRaw(requestParameters: InspectionControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionControllerDelete(id: string): Promise<void> {
        await this.inspectionControllerDeleteRaw({ id: id });
    }

    /**
     */
    async inspectionControllerGetRaw(requestParameters: InspectionControllerGetRequest): Promise<runtime.ApiResponse<GetInspectionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionControllerGet(id: string): Promise<GetInspectionDto> {
        const response = await this.inspectionControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionControllerGetAllByPortionRaw(requestParameters: InspectionControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetInspectionExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.organizationalUnit === null || requestParameters.organizationalUnit === undefined) {
            throw new runtime.RequiredError('organizationalUnit','Required parameter requestParameters.organizationalUnit was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.organization === null || requestParameters.organization === undefined) {
            throw new runtime.RequiredError('organization','Required parameter requestParameters.organization was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.inspectionPlace === null || requestParameters.inspectionPlace === undefined) {
            throw new runtime.RequiredError('inspectionPlace','Required parameter requestParameters.inspectionPlace was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.actionDateFrom === null || requestParameters.actionDateFrom === undefined) {
            throw new runtime.RequiredError('actionDateFrom','Required parameter requestParameters.actionDateFrom was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.actionDateTo === null || requestParameters.actionDateTo === undefined) {
            throw new runtime.RequiredError('actionDateTo','Required parameter requestParameters.actionDateTo was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        if (requestParameters.fileNumber === null || requestParameters.fileNumber === undefined) {
            throw new runtime.RequiredError('fileNumber','Required parameter requestParameters.fileNumber was null or undefined when calling inspectionControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.organizationalUnit !== undefined) {
            queryParameters['organizationalUnit'] = requestParameters.organizationalUnit;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.inspectionPlace !== undefined) {
            queryParameters['inspectionPlace'] = requestParameters.inspectionPlace;
        }

        if (requestParameters.actionDateFrom !== undefined) {
            queryParameters['actionDateFrom'] = requestParameters.actionDateFrom;
        }

        if (requestParameters.actionDateTo !== undefined) {
            queryParameters['actionDateTo'] = requestParameters.actionDateTo;
        }

        if (requestParameters.fileNumber !== undefined) {
            queryParameters['fileNumber'] = requestParameters.fileNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectionExtendedDtoFromJSON));
    }

    /**
     */
    async inspectionControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, organizationalUnit: string, organization: string, inspectionPlace: string, actionDateFrom: string, actionDateTo: string, fileNumber: string): Promise<Array<GetInspectionExtendedDto>> {
        const response = await this.inspectionControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, organizationalUnit: organizationalUnit, organization: organization, inspectionPlace: inspectionPlace, actionDateFrom: actionDateFrom, actionDateTo: actionDateTo, fileNumber: fileNumber });
        return await response.value();
    }

    /**
     */
    async inspectionControllerGetAllCountRaw(requestParameters: InspectionControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling inspectionControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async inspectionControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.inspectionControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async inspectionControllerGetByMetricRaw(requestParameters: InspectionControllerGetByMetricRequest): Promise<runtime.ApiResponse<GetInspectionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerGetByMetric.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/byMetric/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionControllerGetByMetric(id: string): Promise<GetInspectionDto> {
        const response = await this.inspectionControllerGetByMetricRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionControllerGetByPakRaw(requestParameters: InspectionControllerGetByPakRequest): Promise<runtime.ApiResponse<Array<GetInspectionDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerGetByPak.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/byPak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectionDtoFromJSON));
    }

    /**
     */
    async inspectionControllerGetByPak(id: string): Promise<Array<GetInspectionDto>> {
        const response = await this.inspectionControllerGetByPakRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionControllerGetPendingForUserRaw(): Promise<runtime.ApiResponse<Array<GetInspectionExtendedDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/pending/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectionExtendedDtoFromJSON));
    }

    /**
     */
    async inspectionControllerGetPendingForUser(): Promise<Array<GetInspectionExtendedDto>> {
        const response = await this.inspectionControllerGetPendingForUserRaw();
        return await response.value();
    }

    /**
     */
    async inspectionControllerGetSignedByPortionRaw(requestParameters: InspectionControllerGetSignedByPortionRequest): Promise<runtime.ApiResponse<Array<GetInspectionExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling inspectionControllerGetSignedByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling inspectionControllerGetSignedByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling inspectionControllerGetSignedByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling inspectionControllerGetSignedByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling inspectionControllerGetSignedByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/searchSigned/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectionExtendedDtoFromJSON));
    }

    /**
     */
    async inspectionControllerGetSignedByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetInspectionExtendedDto>> {
        const response = await this.inspectionControllerGetSignedByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async inspectionControllerGetSignedCountRaw(requestParameters: InspectionControllerGetSignedCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling inspectionControllerGetSignedCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/searchSigned/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async inspectionControllerGetSignedCount(filterText: string): Promise<number> {
        const response = await this.inspectionControllerGetSignedCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async inspectionControllerGetWithDetailsRaw(requestParameters: InspectionControllerGetWithDetailsRequest): Promise<runtime.ApiResponse<GetInspectionWithDetailsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerGetWithDetails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/withDetails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionWithDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionControllerGetWithDetails(id: string): Promise<GetInspectionWithDetailsDto> {
        const response = await this.inspectionControllerGetWithDetailsRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionControllerMayDeleteRaw(requestParameters: InspectionControllerMayDeleteRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerMayDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/mayDelete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async inspectionControllerMayDelete(id: string): Promise<ValidationStatus> {
        const response = await this.inspectionControllerMayDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionControllerReadyToSignRaw(requestParameters: InspectionControllerReadyToSignRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerReadyToSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/readyToSign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async inspectionControllerReadyToSign(id: string): Promise<ValidationStatus> {
        const response = await this.inspectionControllerReadyToSignRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionControllerRevertSignRaw(requestParameters: InspectionControllerRevertSignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerRevertSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/revertSign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionControllerRevertSign(id: string): Promise<void> {
        await this.inspectionControllerRevertSignRaw({ id: id });
    }

    /**
     */
    async inspectionControllerSignRaw(requestParameters: InspectionControllerSignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/sign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionControllerSign(id: string): Promise<void> {
        await this.inspectionControllerSignRaw({ id: id });
    }

    /**
     */
    async inspectionControllerUpdateRaw(requestParameters: InspectionControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionControllerUpdate.');
        }

        if (requestParameters.createUpdateInspectionDto === null || requestParameters.createUpdateInspectionDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectionDto','Required parameter requestParameters.createUpdateInspectionDto was null or undefined when calling inspectionControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectionDtoToJSON(requestParameters.createUpdateInspectionDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionControllerUpdate(id: string, createUpdateInspectionDto: CreateUpdateInspectionDto): Promise<void> {
        await this.inspectionControllerUpdateRaw({ id: id, createUpdateInspectionDto: createUpdateInspectionDto });
    }

    /**
     */
    async inspectionMetricControllerApproveRaw(requestParameters: InspectionMetricControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionMetricControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionMetric/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionMetricControllerApprove(id: string): Promise<void> {
        await this.inspectionMetricControllerApproveRaw({ id: id });
    }

    /**
     */
    async inspectionMetricControllerGetRaw(requestParameters: InspectionMetricControllerGetRequest): Promise<runtime.ApiResponse<GetInspectionMetricDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionMetricControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionMetric/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionMetricDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionMetricControllerGet(id: string): Promise<GetInspectionMetricDto> {
        const response = await this.inspectionMetricControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionMetricControllerRevertApproveRaw(requestParameters: InspectionMetricControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionMetricControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionMetric/revertApprove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionMetricControllerRevertApprove(id: string): Promise<void> {
        await this.inspectionMetricControllerRevertApproveRaw({ id: id });
    }

    /**
     */
    async inspectionMetricControllerUpdateRaw(requestParameters: InspectionMetricControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionMetricControllerUpdate.');
        }

        if (requestParameters.createUpdateInspectionMetricDto === null || requestParameters.createUpdateInspectionMetricDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectionMetricDto','Required parameter requestParameters.createUpdateInspectionMetricDto was null or undefined when calling inspectionMetricControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionMetric/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectionMetricDtoToJSON(requestParameters.createUpdateInspectionMetricDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionMetricControllerUpdate(id: string, createUpdateInspectionMetricDto: CreateUpdateInspectionMetricDto): Promise<void> {
        await this.inspectionMetricControllerUpdateRaw({ id: id, createUpdateInspectionMetricDto: createUpdateInspectionMetricDto });
    }

    /**
     */
    async inspectionPlanControllerCreateRaw(requestParameters: InspectionPlanControllerCreateRequest): Promise<runtime.ApiResponse<GetInspectionPlanDto>> {
        if (requestParameters.createUpdateInspectionPlanDto === null || requestParameters.createUpdateInspectionPlanDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectionPlanDto','Required parameter requestParameters.createUpdateInspectionPlanDto was null or undefined when calling inspectionPlanControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectionPlanDtoToJSON(requestParameters.createUpdateInspectionPlanDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionPlanDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionPlanControllerCreate(createUpdateInspectionPlanDto: CreateUpdateInspectionPlanDto): Promise<GetInspectionPlanDto> {
        const response = await this.inspectionPlanControllerCreateRaw({ createUpdateInspectionPlanDto: createUpdateInspectionPlanDto });
        return await response.value();
    }

    /**
     */
    async inspectionPlanControllerDeleteRaw(requestParameters: InspectionPlanControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionPlanControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionPlanControllerDelete(id: string): Promise<void> {
        await this.inspectionPlanControllerDeleteRaw({ id: id });
    }

    /**
     */
    async inspectionPlanControllerGetRaw(requestParameters: InspectionPlanControllerGetRequest): Promise<runtime.ApiResponse<GetInspectionPlanDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionPlanControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionPlanDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionPlanControllerGet(id: string): Promise<GetInspectionPlanDto> {
        const response = await this.inspectionPlanControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionPlanControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetInspectionPlanDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlan`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectionPlanDtoFromJSON));
    }

    /**
     */
    async inspectionPlanControllerGetAll(): Promise<Array<GetInspectionPlanDto>> {
        const response = await this.inspectionPlanControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async inspectionPlanControllerUpdateRaw(requestParameters: InspectionPlanControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionPlanControllerUpdate.');
        }

        if (requestParameters.createUpdateInspectionPlanDto === null || requestParameters.createUpdateInspectionPlanDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectionPlanDto','Required parameter requestParameters.createUpdateInspectionPlanDto was null or undefined when calling inspectionPlanControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectionPlanDtoToJSON(requestParameters.createUpdateInspectionPlanDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionPlanControllerUpdate(id: string, createUpdateInspectionPlanDto: CreateUpdateInspectionPlanDto): Promise<void> {
        await this.inspectionPlanControllerUpdateRaw({ id: id, createUpdateInspectionPlanDto: createUpdateInspectionPlanDto });
    }

    /**
     */
    async inspectionPlanGroupControllerCreateRaw(requestParameters: InspectionPlanGroupControllerCreateRequest): Promise<runtime.ApiResponse<GetInspectionPlanGroupDto>> {
        if (requestParameters.createUpdateInspectionPlanGroupDto === null || requestParameters.createUpdateInspectionPlanGroupDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectionPlanGroupDto','Required parameter requestParameters.createUpdateInspectionPlanGroupDto was null or undefined when calling inspectionPlanGroupControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlanGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectionPlanGroupDtoToJSON(requestParameters.createUpdateInspectionPlanGroupDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionPlanGroupDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionPlanGroupControllerCreate(createUpdateInspectionPlanGroupDto: CreateUpdateInspectionPlanGroupDto): Promise<GetInspectionPlanGroupDto> {
        const response = await this.inspectionPlanGroupControllerCreateRaw({ createUpdateInspectionPlanGroupDto: createUpdateInspectionPlanGroupDto });
        return await response.value();
    }

    /**
     */
    async inspectionPlanGroupControllerDeleteRaw(requestParameters: InspectionPlanGroupControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionPlanGroupControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlanGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionPlanGroupControllerDelete(id: string): Promise<void> {
        await this.inspectionPlanGroupControllerDeleteRaw({ id: id });
    }

    /**
     */
    async inspectionPlanGroupControllerGetRaw(requestParameters: InspectionPlanGroupControllerGetRequest): Promise<runtime.ApiResponse<GetInspectionPlanGroupDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionPlanGroupControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlanGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectionPlanGroupDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectionPlanGroupControllerGet(id: string): Promise<GetInspectionPlanGroupDto> {
        const response = await this.inspectionPlanGroupControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionPlanGroupControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetInspectionPlanGroupDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlanGroup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectionPlanGroupDtoFromJSON));
    }

    /**
     */
    async inspectionPlanGroupControllerGetAll(): Promise<Array<GetInspectionPlanGroupDto>> {
        const response = await this.inspectionPlanGroupControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async inspectionPlanGroupControllerGetByPlanRaw(requestParameters: InspectionPlanGroupControllerGetByPlanRequest): Promise<runtime.ApiResponse<Array<GetInspectionPlanGroupDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionPlanGroupControllerGetByPlan.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlanGroup/byPlan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectionPlanGroupDtoFromJSON));
    }

    /**
     */
    async inspectionPlanGroupControllerGetByPlan(id: string): Promise<Array<GetInspectionPlanGroupDto>> {
        const response = await this.inspectionPlanGroupControllerGetByPlanRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectionPlanGroupControllerUpdateRaw(requestParameters: InspectionPlanGroupControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectionPlanGroupControllerUpdate.');
        }

        if (requestParameters.createUpdateInspectionPlanGroupDto === null || requestParameters.createUpdateInspectionPlanGroupDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectionPlanGroupDto','Required parameter requestParameters.createUpdateInspectionPlanGroupDto was null or undefined when calling inspectionPlanGroupControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspectionPlanGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectionPlanGroupDtoToJSON(requestParameters.createUpdateInspectionPlanGroupDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectionPlanGroupControllerUpdate(id: string, createUpdateInspectionPlanGroupDto: CreateUpdateInspectionPlanGroupDto): Promise<void> {
        await this.inspectionPlanGroupControllerUpdateRaw({ id: id, createUpdateInspectionPlanGroupDto: createUpdateInspectionPlanGroupDto });
    }

    /**
     */
    async inspectorControllerCreateRaw(requestParameters: InspectorControllerCreateRequest): Promise<runtime.ApiResponse<GetInspectorDto>> {
        if (requestParameters.createUpdateInspectorDto === null || requestParameters.createUpdateInspectorDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectorDto','Required parameter requestParameters.createUpdateInspectorDto was null or undefined when calling inspectorControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspector`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectorDtoToJSON(requestParameters.createUpdateInspectorDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectorDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectorControllerCreate(createUpdateInspectorDto: CreateUpdateInspectorDto): Promise<GetInspectorDto> {
        const response = await this.inspectorControllerCreateRaw({ createUpdateInspectorDto: createUpdateInspectorDto });
        return await response.value();
    }

    /**
     */
    async inspectorControllerDeleteRaw(requestParameters: InspectorControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectorControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspector/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectorControllerDelete(id: string): Promise<void> {
        await this.inspectorControllerDeleteRaw({ id: id });
    }

    /**
     */
    async inspectorControllerGetRaw(requestParameters: InspectorControllerGetRequest): Promise<runtime.ApiResponse<GetInspectorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectorControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspector/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInspectorDtoFromJSON(jsonValue));
    }

    /**
     */
    async inspectorControllerGet(id: string): Promise<GetInspectorDto> {
        const response = await this.inspectorControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectorControllerGetByInspectionRaw(requestParameters: InspectorControllerGetByInspectionRequest): Promise<runtime.ApiResponse<Array<GetInspectorDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectorControllerGetByInspection.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspector/byInspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInspectorDtoFromJSON));
    }

    /**
     */
    async inspectorControllerGetByInspection(id: string): Promise<Array<GetInspectorDto>> {
        const response = await this.inspectorControllerGetByInspectionRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async inspectorControllerUpdateRaw(requestParameters: InspectorControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inspectorControllerUpdate.');
        }

        if (requestParameters.createUpdateInspectorDto === null || requestParameters.createUpdateInspectorDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectorDto','Required parameter requestParameters.createUpdateInspectorDto was null or undefined when calling inspectorControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspector/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectorDtoToJSON(requestParameters.createUpdateInspectorDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inspectorControllerUpdate(id: string, createUpdateInspectorDto: CreateUpdateInspectorDto): Promise<void> {
        await this.inspectorControllerUpdateRaw({ id: id, createUpdateInspectorDto: createUpdateInspectorDto });
    }

    /**
     */
    async inspectorControllerValidateCreateRaw(requestParameters: InspectorControllerValidateCreateRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.createUpdateInspectorDto === null || requestParameters.createUpdateInspectorDto === undefined) {
            throw new runtime.RequiredError('createUpdateInspectorDto','Required parameter requestParameters.createUpdateInspectorDto was null or undefined when calling inspectorControllerValidateCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/inspector/validateCreate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInspectorDtoToJSON(requestParameters.createUpdateInspectorDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async inspectorControllerValidateCreate(createUpdateInspectorDto: CreateUpdateInspectorDto): Promise<ValidationStatus> {
        const response = await this.inspectorControllerValidateCreateRaw({ createUpdateInspectorDto: createUpdateInspectorDto });
        return await response.value();
    }

    /**
     */
    async judgmentControllerApproveRaw(requestParameters: JudgmentControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async judgmentControllerApprove(id: string): Promise<void> {
        await this.judgmentControllerApproveRaw({ id: id });
    }

    /**
     */
    async judgmentControllerCreateRaw(requestParameters: JudgmentControllerCreateRequest): Promise<runtime.ApiResponse<GetJudgmentDto>> {
        if (requestParameters.createUpdateJudgmentDto === null || requestParameters.createUpdateJudgmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateJudgmentDto','Required parameter requestParameters.createUpdateJudgmentDto was null or undefined when calling judgmentControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateJudgmentDtoToJSON(requestParameters.createUpdateJudgmentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJudgmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async judgmentControllerCreate(createUpdateJudgmentDto: CreateUpdateJudgmentDto): Promise<GetJudgmentDto> {
        const response = await this.judgmentControllerCreateRaw({ createUpdateJudgmentDto: createUpdateJudgmentDto });
        return await response.value();
    }

    /**
     */
    async judgmentControllerDeleteRaw(requestParameters: JudgmentControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async judgmentControllerDelete(id: string): Promise<void> {
        await this.judgmentControllerDeleteRaw({ id: id });
    }

    /**
     */
    async judgmentControllerGetRaw(requestParameters: JudgmentControllerGetRequest): Promise<runtime.ApiResponse<GetJudgmentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJudgmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async judgmentControllerGet(id: string): Promise<GetJudgmentDto> {
        const response = await this.judgmentControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetByInspectedProductRaw(requestParameters: JudgmentControllerGetByInspectedProductRequest): Promise<runtime.ApiResponse<Array<GetJudgmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerGetByInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/byInspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetByInspectedProduct(id: string): Promise<Array<GetJudgmentDto>> {
        const response = await this.judgmentControllerGetByInspectedProductRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetByPakRaw(requestParameters: JudgmentControllerGetByPakRequest): Promise<runtime.ApiResponse<Array<GetJudgmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerGetByPak.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/byPak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetByPak(id: string): Promise<Array<GetJudgmentDto>> {
        const response = await this.judgmentControllerGetByPakRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetByProceedingRaw(requestParameters: JudgmentControllerGetByProceedingRequest): Promise<runtime.ApiResponse<Array<GetJudgmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerGetByProceeding.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/byProceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetByProceeding(id: string): Promise<Array<GetJudgmentDto>> {
        const response = await this.judgmentControllerGetByProceedingRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetBySampleRaw(requestParameters: JudgmentControllerGetBySampleRequest): Promise<runtime.ApiResponse<Array<GetJudgmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerGetBySample.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/bySample/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetBySample(id: string): Promise<Array<GetJudgmentDto>> {
        const response = await this.judgmentControllerGetBySampleRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetWithProceedingRaw(requestParameters: JudgmentControllerGetWithProceedingRequest): Promise<runtime.ApiResponse<Array<GetJudgmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerGetWithProceeding.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/withProceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetWithProceeding(id: string): Promise<Array<GetJudgmentDto>> {
        const response = await this.judgmentControllerGetWithProceedingRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentControllerRevertApproveRaw(requestParameters: JudgmentControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/revertApprove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async judgmentControllerRevertApprove(id: string): Promise<void> {
        await this.judgmentControllerRevertApproveRaw({ id: id });
    }

    /**
     */
    async judgmentControllerUpdateRaw(requestParameters: JudgmentControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerUpdate.');
        }

        if (requestParameters.createUpdateJudgmentDto === null || requestParameters.createUpdateJudgmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateJudgmentDto','Required parameter requestParameters.createUpdateJudgmentDto was null or undefined when calling judgmentControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateJudgmentDtoToJSON(requestParameters.createUpdateJudgmentDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async judgmentControllerUpdate(id: string, createUpdateJudgmentDto: CreateUpdateJudgmentDto): Promise<void> {
        await this.judgmentControllerUpdateRaw({ id: id, createUpdateJudgmentDto: createUpdateJudgmentDto });
    }

    /**
     */
    async kwzControllerAddRequestForWithdrawalRaw(requestParameters: KwzControllerAddRequestForWithdrawalRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerAddRequestForWithdrawal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/addRequestForWithdrawal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerAddRequestForWithdrawal(id: string): Promise<void> {
        await this.kwzControllerAddRequestForWithdrawalRaw({ id: id });
    }

    /**
     */
    async kwzControllerApproveRaw(requestParameters: KwzControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerApprove(id: string): Promise<void> {
        await this.kwzControllerApproveRaw({ id: id });
    }

    /**
     */
    async kwzControllerApproveRequestForWithdrawalRaw(requestParameters: KwzControllerApproveRequestForWithdrawalRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerApproveRequestForWithdrawal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/approveRequestForWithdrawal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerApproveRequestForWithdrawal(id: string): Promise<void> {
        await this.kwzControllerApproveRequestForWithdrawalRaw({ id: id });
    }

    /**
     */
    async kwzControllerCreateWithJudgmentRaw(requestParameters: KwzControllerCreateWithJudgmentRequest): Promise<runtime.ApiResponse<GetKwzDto>> {
        if (requestParameters.createWithJudgmentDto === null || requestParameters.createWithJudgmentDto === undefined) {
            throw new runtime.RequiredError('createWithJudgmentDto','Required parameter requestParameters.createWithJudgmentDto was null or undefined when calling kwzControllerCreateWithJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/createWithJudgment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWithJudgmentDtoToJSON(requestParameters.createWithJudgmentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKwzDtoFromJSON(jsonValue));
    }

    /**
     */
    async kwzControllerCreateWithJudgment(createWithJudgmentDto: CreateWithJudgmentDto): Promise<GetKwzDto> {
        const response = await this.kwzControllerCreateWithJudgmentRaw({ createWithJudgmentDto: createWithJudgmentDto });
        return await response.value();
    }

    /**
     */
    async kwzControllerDeleteWithJudgmentRaw(requestParameters: KwzControllerDeleteWithJudgmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerDeleteWithJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerDeleteWithJudgment(id: string): Promise<void> {
        await this.kwzControllerDeleteWithJudgmentRaw({ id: id });
    }

    /**
     */
    async kwzControllerGetAllPublishedRaw(): Promise<runtime.ApiResponse<Array<GetNarrowedKwzDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/allPublished`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetNarrowedKwzDtoFromJSON));
    }

    /**
     */
    async kwzControllerGetAllPublished(): Promise<Array<GetNarrowedKwzDto>> {
        const response = await this.kwzControllerGetAllPublishedRaw();
        return await response.value();
    }

    /**
     */
    async kwzControllerGetAllReadyToPublishRaw(): Promise<runtime.ApiResponse<Array<GetNarrowedKwzDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/allReadyToPublish`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetNarrowedKwzDtoFromJSON));
    }

    /**
     */
    async kwzControllerGetAllReadyToPublish(): Promise<Array<GetNarrowedKwzDto>> {
        const response = await this.kwzControllerGetAllReadyToPublishRaw();
        return await response.value();
    }

    /**
     */
    async kwzControllerGetAllRequestToRemovePublishRaw(): Promise<runtime.ApiResponse<Array<GetNarrowedKwzDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/allRequestToRemovePublish`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetNarrowedKwzDtoFromJSON));
    }

    /**
     */
    async kwzControllerGetAllRequestToRemovePublish(): Promise<Array<GetNarrowedKwzDto>> {
        const response = await this.kwzControllerGetAllRequestToRemovePublishRaw();
        return await response.value();
    }

    /**
     */
    async kwzControllerGetAllWithJudgmentRaw(): Promise<runtime.ApiResponse<Array<GetNarrowedKwzDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/withJudgment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetNarrowedKwzDtoFromJSON));
    }

    /**
     */
    async kwzControllerGetAllWithJudgment(): Promise<Array<GetNarrowedKwzDto>> {
        const response = await this.kwzControllerGetAllWithJudgmentRaw();
        return await response.value();
    }

    /**
     */
    async kwzControllerGetPendingForUserRaw(): Promise<runtime.ApiResponse<Array<GetNarrowedKwzDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/pending/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetNarrowedKwzDtoFromJSON));
    }

    /**
     */
    async kwzControllerGetPendingForUser(): Promise<Array<GetNarrowedKwzDto>> {
        const response = await this.kwzControllerGetPendingForUserRaw();
        return await response.value();
    }

    /**
     */
    async kwzControllerGetWaitingForApprovalRaw(): Promise<runtime.ApiResponse<Array<GetNarrowedKwzDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/waitingForApproval`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetNarrowedKwzDtoFromJSON));
    }

    /**
     */
    async kwzControllerGetWaitingForApproval(): Promise<Array<GetNarrowedKwzDto>> {
        const response = await this.kwzControllerGetWaitingForApprovalRaw();
        return await response.value();
    }

    /**
     */
    async kwzControllerGetWithJudgmentRaw(requestParameters: KwzControllerGetWithJudgmentRequest): Promise<runtime.ApiResponse<GetKwzDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerGetWithJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/withJudgment{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKwzDtoFromJSON(jsonValue));
    }

    /**
     */
    async kwzControllerGetWithJudgment(id: string): Promise<GetKwzDto> {
        const response = await this.kwzControllerGetWithJudgmentRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async kwzControllerGetWithPartiesRaw(requestParameters: KwzControllerGetWithPartiesRequest): Promise<runtime.ApiResponse<GetKwzWithPartiesDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerGetWithParties.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/withParties{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKwzWithPartiesDtoFromJSON(jsonValue));
    }

    /**
     */
    async kwzControllerGetWithParties(id: string): Promise<GetKwzWithPartiesDto> {
        const response = await this.kwzControllerGetWithPartiesRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async kwzControllerPublishRaw(requestParameters: KwzControllerPublishRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/publish/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerPublish(id: string): Promise<void> {
        await this.kwzControllerPublishRaw({ id: id });
    }

    /**
     */
    async kwzControllerRemovePublishRaw(requestParameters: KwzControllerRemovePublishRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerRemovePublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/removePublish/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerRemovePublish(id: string): Promise<void> {
        await this.kwzControllerRemovePublishRaw({ id: id });
    }

    /**
     */
    async kwzControllerRemoveRequestForWithdrawalRaw(requestParameters: KwzControllerRemoveRequestForWithdrawalRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerRemoveRequestForWithdrawal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/removeRequestForWithdrawal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerRemoveRequestForWithdrawal(id: string): Promise<void> {
        await this.kwzControllerRemoveRequestForWithdrawalRaw({ id: id });
    }

    /**
     */
    async kwzControllerRevertApproveRaw(requestParameters: KwzControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/revertApprove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerRevertApprove(id: string): Promise<void> {
        await this.kwzControllerRevertApproveRaw({ id: id });
    }

    /**
     */
    async kwzControllerRevertPublishRaw(requestParameters: KwzControllerRevertPublishRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerRevertPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/revertPublish/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerRevertPublish(id: string): Promise<void> {
        await this.kwzControllerRevertPublishRaw({ id: id });
    }

    /**
     */
    async kwzControllerRevertRemovePublishRaw(requestParameters: KwzControllerRevertRemovePublishRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerRevertRemovePublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/revertRemovePublish/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerRevertRemovePublish(id: string): Promise<void> {
        await this.kwzControllerRevertRemovePublishRaw({ id: id });
    }

    /**
     */
    async kwzControllerRevertRequestForWithdrawalRaw(requestParameters: KwzControllerRevertRequestForWithdrawalRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerRevertRequestForWithdrawal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/revertRequestForWithdrawal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerRevertRequestForWithdrawal(id: string): Promise<void> {
        await this.kwzControllerRevertRequestForWithdrawalRaw({ id: id });
    }

    /**
     */
    async kwzControllerUpdateRaw(requestParameters: KwzControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzControllerUpdate.');
        }

        if (requestParameters.createUpdateKwzDto === null || requestParameters.createUpdateKwzDto === undefined) {
            throw new runtime.RequiredError('createUpdateKwzDto','Required parameter requestParameters.createUpdateKwzDto was null or undefined when calling kwzControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwz/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateKwzDtoToJSON(requestParameters.createUpdateKwzDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async kwzControllerUpdate(id: string, createUpdateKwzDto: CreateUpdateKwzDto): Promise<void> {
        await this.kwzControllerUpdateRaw({ id: id, createUpdateKwzDto: createUpdateKwzDto });
    }

    /**
     */
    async kwzHistoryControllerCreateRaw(requestParameters: KwzHistoryControllerCreateRequest): Promise<runtime.ApiResponse<GetKwzHistoryDto>> {
        if (requestParameters.createUpdateKwzHistoryDto === null || requestParameters.createUpdateKwzHistoryDto === undefined) {
            throw new runtime.RequiredError('createUpdateKwzHistoryDto','Required parameter requestParameters.createUpdateKwzHistoryDto was null or undefined when calling kwzHistoryControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwzHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateKwzHistoryDtoToJSON(requestParameters.createUpdateKwzHistoryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKwzHistoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async kwzHistoryControllerCreate(createUpdateKwzHistoryDto: CreateUpdateKwzHistoryDto): Promise<GetKwzHistoryDto> {
        const response = await this.kwzHistoryControllerCreateRaw({ createUpdateKwzHistoryDto: createUpdateKwzHistoryDto });
        return await response.value();
    }

    /**
     */
    async kwzHistoryControllerGetByKwzRaw(requestParameters: KwzHistoryControllerGetByKwzRequest): Promise<runtime.ApiResponse<Array<GetKwzHistoryDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling kwzHistoryControllerGetByKwz.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kwzHistory/byKwz/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetKwzHistoryDtoFromJSON));
    }

    /**
     */
    async kwzHistoryControllerGetByKwz(id: string): Promise<Array<GetKwzHistoryDto>> {
        const response = await this.kwzHistoryControllerGetByKwzRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async memoControllerCreateBpNotificationMemoRaw(requestParameters: MemoControllerCreateBpNotificationMemoRequest): Promise<runtime.ApiResponse<GetMemoDto>> {
        if (requestParameters.createBpNotificationMemoDto === null || requestParameters.createBpNotificationMemoDto === undefined) {
            throw new runtime.RequiredError('createBpNotificationMemoDto','Required parameter requestParameters.createBpNotificationMemoDto was null or undefined when calling memoControllerCreateBpNotificationMemo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memo/createBpNotificationMemo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBpNotificationMemoDtoToJSON(requestParameters.createBpNotificationMemoDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMemoDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerCreateBpNotificationMemo(createBpNotificationMemoDto: CreateBpNotificationMemoDto): Promise<GetMemoDto> {
        const response = await this.memoControllerCreateBpNotificationMemoRaw({ createBpNotificationMemoDto: createBpNotificationMemoDto });
        return await response.value();
    }

    /**
     */
    async memoControllerCreatePrivateMemoRaw(requestParameters: MemoControllerCreatePrivateMemoRequest): Promise<runtime.ApiResponse<GetMemoDto>> {
        if (requestParameters.createPrivateMemoDto === null || requestParameters.createPrivateMemoDto === undefined) {
            throw new runtime.RequiredError('createPrivateMemoDto','Required parameter requestParameters.createPrivateMemoDto was null or undefined when calling memoControllerCreatePrivateMemo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memo/createPrivateMemo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePrivateMemoDtoToJSON(requestParameters.createPrivateMemoDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMemoDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerCreatePrivateMemo(createPrivateMemoDto: CreatePrivateMemoDto): Promise<GetMemoDto> {
        const response = await this.memoControllerCreatePrivateMemoRaw({ createPrivateMemoDto: createPrivateMemoDto });
        return await response.value();
    }

    /**
     */
    async memoControllerCreatePublicMemoRaw(requestParameters: MemoControllerCreatePublicMemoRequest): Promise<runtime.ApiResponse<GetMemoDto>> {
        if (requestParameters.createPublicMemoDto === null || requestParameters.createPublicMemoDto === undefined) {
            throw new runtime.RequiredError('createPublicMemoDto','Required parameter requestParameters.createPublicMemoDto was null or undefined when calling memoControllerCreatePublicMemo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memo/createPublicMemo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePublicMemoDtoToJSON(requestParameters.createPublicMemoDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMemoDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerCreatePublicMemo(createPublicMemoDto: CreatePublicMemoDto): Promise<GetMemoDto> {
        const response = await this.memoControllerCreatePublicMemoRaw({ createPublicMemoDto: createPublicMemoDto });
        return await response.value();
    }

    /**
     */
    async memoControllerGetSubjectThreadStatsRaw(requestParameters: MemoControllerGetSubjectThreadStatsRequest): Promise<runtime.ApiResponse<GetSubjectThreadStatsDto>> {
        if (requestParameters.subjectType === null || requestParameters.subjectType === undefined) {
            throw new runtime.RequiredError('subjectType','Required parameter requestParameters.subjectType was null or undefined when calling memoControllerGetSubjectThreadStats.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memoControllerGetSubjectThreadStats.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memo/subjectThreadStats/{subjectType}/{id}`.replace(`{${"subjectType"}}`, encodeURIComponent(String(requestParameters.subjectType))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSubjectThreadStatsDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerGetSubjectThreadStats(subjectType: MemoControllerGetSubjectThreadStatsSubjectTypeEnum, id: string): Promise<GetSubjectThreadStatsDto> {
        const response = await this.memoControllerGetSubjectThreadStatsRaw({ subjectType: subjectType, id: id });
        return await response.value();
    }

    /**
     */
    async memoControllerGetThreadMemosRaw(requestParameters: MemoControllerGetThreadMemosRequest): Promise<runtime.ApiResponse<Array<GetMemoDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memoControllerGetThreadMemos.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling memoControllerGetThreadMemos.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memo/thread/{id}/{count}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"count"}}`, encodeURIComponent(String(requestParameters.count))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetMemoDtoFromJSON));
    }

    /**
     */
    async memoControllerGetThreadMemos(id: string, count: number): Promise<Array<GetMemoDto>> {
        const response = await this.memoControllerGetThreadMemosRaw({ id: id, count: count });
        return await response.value();
    }

    /**
     */
    async memoControllerGetThreadsForUserRaw(requestParameters: MemoControllerGetThreadsForUserRequest): Promise<runtime.ApiResponse<Array<GetThreadDto>>> {
        if (requestParameters.threadsCount === null || requestParameters.threadsCount === undefined) {
            throw new runtime.RequiredError('threadsCount','Required parameter requestParameters.threadsCount was null or undefined when calling memoControllerGetThreadsForUser.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling memoControllerGetThreadsForUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memo/threads/forUser/{threadsCount}/{filter}`.replace(`{${"threadsCount"}}`, encodeURIComponent(String(requestParameters.threadsCount))).replace(`{${"filter"}}`, encodeURIComponent(String(requestParameters.filter))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetThreadDtoFromJSON));
    }

    /**
     */
    async memoControllerGetThreadsForUser(threadsCount: number, filter: number): Promise<Array<GetThreadDto>> {
        const response = await this.memoControllerGetThreadsForUserRaw({ threadsCount: threadsCount, filter: filter });
        return await response.value();
    }

    /**
     */
    async memoControllerGetThreadsForUserStatsRaw(): Promise<runtime.ApiResponse<GetThreadsStatsDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memo/threadsStats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThreadsStatsDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerGetThreadsForUserStats(): Promise<GetThreadsStatsDto> {
        const response = await this.memoControllerGetThreadsForUserStatsRaw();
        return await response.value();
    }

    /**
     */
    async memoControllerSendPrivateMemoToManyRaw(requestParameters: MemoControllerSendPrivateMemoToManyRequest): Promise<runtime.ApiResponse<GetMemoDto>> {
        if (requestParameters.sendPrivateMemoToManyDto === null || requestParameters.sendPrivateMemoToManyDto === undefined) {
            throw new runtime.RequiredError('sendPrivateMemoToManyDto','Required parameter requestParameters.sendPrivateMemoToManyDto was null or undefined when calling memoControllerSendPrivateMemoToMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memo/sendPrivateMemoToMany`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendPrivateMemoToManyDtoToJSON(requestParameters.sendPrivateMemoToManyDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMemoDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerSendPrivateMemoToMany(sendPrivateMemoToManyDto: SendPrivateMemoToManyDto): Promise<GetMemoDto> {
        const response = await this.memoControllerSendPrivateMemoToManyRaw({ sendPrivateMemoToManyDto: sendPrivateMemoToManyDto });
        return await response.value();
    }

    /**
     */
    async misstatementControllerCreateRaw(requestParameters: MisstatementControllerCreateRequest): Promise<runtime.ApiResponse<GetMisstatementDto>> {
        if (requestParameters.createUpdateMisstatementDto === null || requestParameters.createUpdateMisstatementDto === undefined) {
            throw new runtime.RequiredError('createUpdateMisstatementDto','Required parameter requestParameters.createUpdateMisstatementDto was null or undefined when calling misstatementControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/misstatement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateMisstatementDtoToJSON(requestParameters.createUpdateMisstatementDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMisstatementDtoFromJSON(jsonValue));
    }

    /**
     */
    async misstatementControllerCreate(createUpdateMisstatementDto: CreateUpdateMisstatementDto): Promise<GetMisstatementDto> {
        const response = await this.misstatementControllerCreateRaw({ createUpdateMisstatementDto: createUpdateMisstatementDto });
        return await response.value();
    }

    /**
     */
    async misstatementControllerDeleteRaw(requestParameters: MisstatementControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling misstatementControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/misstatement/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async misstatementControllerDelete(id: string): Promise<void> {
        await this.misstatementControllerDeleteRaw({ id: id });
    }

    /**
     */
    async misstatementControllerGetRaw(requestParameters: MisstatementControllerGetRequest): Promise<runtime.ApiResponse<GetMisstatementDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling misstatementControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/misstatement/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMisstatementDtoFromJSON(jsonValue));
    }

    /**
     */
    async misstatementControllerGet(id: string): Promise<GetMisstatementDto> {
        const response = await this.misstatementControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async misstatementControllerGetByInspectedProductRaw(requestParameters: MisstatementControllerGetByInspectedProductRequest): Promise<runtime.ApiResponse<Array<GetMisstatementDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling misstatementControllerGetByInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/misstatement/byInspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetMisstatementDtoFromJSON));
    }

    /**
     */
    async misstatementControllerGetByInspectedProduct(id: string): Promise<Array<GetMisstatementDto>> {
        const response = await this.misstatementControllerGetByInspectedProductRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async misstatementControllerUpdateRaw(requestParameters: MisstatementControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling misstatementControllerUpdate.');
        }

        if (requestParameters.createUpdateMisstatementDto === null || requestParameters.createUpdateMisstatementDto === undefined) {
            throw new runtime.RequiredError('createUpdateMisstatementDto','Required parameter requestParameters.createUpdateMisstatementDto was null or undefined when calling misstatementControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/misstatement/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateMisstatementDtoToJSON(requestParameters.createUpdateMisstatementDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async misstatementControllerUpdate(id: string, createUpdateMisstatementDto: CreateUpdateMisstatementDto): Promise<void> {
        await this.misstatementControllerUpdateRaw({ id: id, createUpdateMisstatementDto: createUpdateMisstatementDto });
    }

    /**
     */
    async misstatementControllerValidateNewRaw(requestParameters: MisstatementControllerValidateNewRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.createUpdateMisstatementDto === null || requestParameters.createUpdateMisstatementDto === undefined) {
            throw new runtime.RequiredError('createUpdateMisstatementDto','Required parameter requestParameters.createUpdateMisstatementDto was null or undefined when calling misstatementControllerValidateNew.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/misstatement/validateNew`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateMisstatementDtoToJSON(requestParameters.createUpdateMisstatementDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async misstatementControllerValidateNew(createUpdateMisstatementDto: CreateUpdateMisstatementDto): Promise<ValidationStatus> {
        const response = await this.misstatementControllerValidateNewRaw({ createUpdateMisstatementDto: createUpdateMisstatementDto });
        return await response.value();
    }

    /**
     */
    async objectHistoryControllerGetRaw(requestParameters: ObjectHistoryControllerGetRequest): Promise<runtime.ApiResponse<GetObjectHistoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling objectHistoryControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/objectHistory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetObjectHistoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async objectHistoryControllerGet(id: string): Promise<GetObjectHistoryDto> {
        const response = await this.objectHistoryControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async objectHistoryControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetObjectHistoryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/objectHistory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetObjectHistoryDtoFromJSON));
    }

    /**
     */
    async objectHistoryControllerGetAll(): Promise<Array<GetObjectHistoryDto>> {
        const response = await this.objectHistoryControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async objectHistoryControllerGetHistoryForObjectAndTypeRaw(requestParameters: ObjectHistoryControllerGetHistoryForObjectAndTypeRequest): Promise<runtime.ApiResponse<Array<GetObjectHistoryDto>>> {
        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling objectHistoryControllerGetHistoryForObjectAndType.');
        }

        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling objectHistoryControllerGetHistoryForObjectAndType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.objectId !== undefined) {
            queryParameters['objectId'] = requestParameters.objectId;
        }

        if (requestParameters.objectType !== undefined) {
            queryParameters['objectType'] = requestParameters.objectType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/objectHistory/forObjectAndType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetObjectHistoryDtoFromJSON));
    }

    /**
     */
    async objectHistoryControllerGetHistoryForObjectAndType(objectId: string, objectType: string): Promise<Array<GetObjectHistoryDto>> {
        const response = await this.objectHistoryControllerGetHistoryForObjectAndTypeRaw({ objectId: objectId, objectType: objectType });
        return await response.value();
    }

    /**
     */
    async objectHistoryControllerGetPreviousEventRaw(requestParameters: ObjectHistoryControllerGetPreviousEventRequest): Promise<runtime.ApiResponse<Array<GetObjectHistoryDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling objectHistoryControllerGetPreviousEvent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/objectHistory/prevFor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetObjectHistoryDtoFromJSON));
    }

    /**
     */
    async objectHistoryControllerGetPreviousEvent(id: string): Promise<Array<GetObjectHistoryDto>> {
        const response = await this.objectHistoryControllerGetPreviousEventRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async organizationControllerCreateRaw(requestParameters: OrganizationControllerCreateRequest): Promise<runtime.ApiResponse<GetOrganizationDto>> {
        if (requestParameters.createUpdateOrganizationDto === null || requestParameters.createUpdateOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrganizationDto','Required parameter requestParameters.createUpdateOrganizationDto was null or undefined when calling organizationControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrganizationDtoToJSON(requestParameters.createUpdateOrganizationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async organizationControllerCreate(createUpdateOrganizationDto: CreateUpdateOrganizationDto): Promise<GetOrganizationDto> {
        const response = await this.organizationControllerCreateRaw({ createUpdateOrganizationDto: createUpdateOrganizationDto });
        return await response.value();
    }

    /**
     */
    async organizationControllerGetRaw(requestParameters: OrganizationControllerGetRequest): Promise<runtime.ApiResponse<GetOrganizationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async organizationControllerGet(id: string): Promise<GetOrganizationDto> {
        const response = await this.organizationControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async organizationControllerGetAllByPortionRaw(requestParameters: OrganizationControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetOrganizationDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling organizationControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling organizationControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling organizationControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling organizationControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling organizationControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganizationDtoFromJSON));
    }

    /**
     */
    async organizationControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetOrganizationDto>> {
        const response = await this.organizationControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async organizationControllerGetAllCountRaw(requestParameters: OrganizationControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling organizationControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async organizationControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.organizationControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async organizationControllerGetByNipRaw(requestParameters: OrganizationControllerGetByNipRequest): Promise<runtime.ApiResponse<Array<GetOrganizationDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling organizationControllerGetByNip.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationControllerGetByNip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/search/byNip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganizationDtoFromJSON));
    }

    /**
     */
    async organizationControllerGetByNip(pattern: string, limit: number): Promise<Array<GetOrganizationDto>> {
        const response = await this.organizationControllerGetByNipRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async organizationControllerGetByPatternRaw(requestParameters: OrganizationControllerGetByPatternRequest): Promise<runtime.ApiResponse<Array<GetOrganizationDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling organizationControllerGetByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationControllerGetByPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/search/byName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganizationDtoFromJSON));
    }

    /**
     */
    async organizationControllerGetByPattern(pattern: string, limit: number): Promise<Array<GetOrganizationDto>> {
        const response = await this.organizationControllerGetByPatternRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async organizationControllerGetDistinctCountriesRaw(requestParameters: OrganizationControllerGetDistinctCountriesRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling organizationControllerGetDistinctCountries.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationControllerGetDistinctCountries.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/search/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async organizationControllerGetDistinctCountries(pattern: string, limit: number): Promise<Array<string>> {
        const response = await this.organizationControllerGetDistinctCountriesRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async organizationControllerGetDistinctPlacesRaw(requestParameters: OrganizationControllerGetDistinctPlacesRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling organizationControllerGetDistinctPlaces.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationControllerGetDistinctPlaces.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/search/places`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async organizationControllerGetDistinctPlaces(pattern: string, limit: number): Promise<Array<string>> {
        const response = await this.organizationControllerGetDistinctPlacesRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async organizationControllerGetDistinctPostcodesRaw(requestParameters: OrganizationControllerGetDistinctPostcodesRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling organizationControllerGetDistinctPostcodes.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling organizationControllerGetDistinctPostcodes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/search/postcodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async organizationControllerGetDistinctPostcodes(pattern: string, limit: number): Promise<Array<string>> {
        const response = await this.organizationControllerGetDistinctPostcodesRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async organizationControllerMergeOrganizationsRaw(requestParameters: OrganizationControllerMergeOrganizationsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgDeleteId === null || requestParameters.orgDeleteId === undefined) {
            throw new runtime.RequiredError('orgDeleteId','Required parameter requestParameters.orgDeleteId was null or undefined when calling organizationControllerMergeOrganizations.');
        }

        if (requestParameters.orgStayId === null || requestParameters.orgStayId === undefined) {
            throw new runtime.RequiredError('orgStayId','Required parameter requestParameters.orgStayId was null or undefined when calling organizationControllerMergeOrganizations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.orgDeleteId !== undefined) {
            queryParameters['orgDeleteId'] = requestParameters.orgDeleteId;
        }

        if (requestParameters.orgStayId !== undefined) {
            queryParameters['orgStayId'] = requestParameters.orgStayId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/merge/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationControllerMergeOrganizations(orgDeleteId: string, orgStayId: string): Promise<void> {
        await this.organizationControllerMergeOrganizationsRaw({ orgDeleteId: orgDeleteId, orgStayId: orgStayId });
    }

    /**
     */
    async organizationControllerUpdateRaw(requestParameters: OrganizationControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationControllerUpdate.');
        }

        if (requestParameters.createUpdateOrganizationDto === null || requestParameters.createUpdateOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrganizationDto','Required parameter requestParameters.createUpdateOrganizationDto was null or undefined when calling organizationControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrganizationDtoToJSON(requestParameters.createUpdateOrganizationDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationControllerUpdate(id: string, createUpdateOrganizationDto: CreateUpdateOrganizationDto): Promise<void> {
        await this.organizationControllerUpdateRaw({ id: id, createUpdateOrganizationDto: createUpdateOrganizationDto });
    }

    /**
     */
    async organizationControllerValidateNewRaw(requestParameters: OrganizationControllerValidateNewRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.createUpdateOrganizationDto === null || requestParameters.createUpdateOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrganizationDto','Required parameter requestParameters.createUpdateOrganizationDto was null or undefined when calling organizationControllerValidateNew.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/validateNew`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrganizationDtoToJSON(requestParameters.createUpdateOrganizationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async organizationControllerValidateNew(createUpdateOrganizationDto: CreateUpdateOrganizationDto): Promise<ValidationStatus> {
        const response = await this.organizationControllerValidateNewRaw({ createUpdateOrganizationDto: createUpdateOrganizationDto });
        return await response.value();
    }

    /**
     */
    async organizationalUnitControllerChangeToInactiveRaw(requestParameters: OrganizationalUnitControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationalUnitControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizationalUnit/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationalUnitControllerChangeToInactive(id: string): Promise<void> {
        await this.organizationalUnitControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async organizationalUnitControllerCreateRaw(requestParameters: OrganizationalUnitControllerCreateRequest): Promise<runtime.ApiResponse<GetOrganizationalUnitDto>> {
        if (requestParameters.createUpdateOrganizationalUnitDto === null || requestParameters.createUpdateOrganizationalUnitDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrganizationalUnitDto','Required parameter requestParameters.createUpdateOrganizationalUnitDto was null or undefined when calling organizationalUnitControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizationalUnit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrganizationalUnitDtoToJSON(requestParameters.createUpdateOrganizationalUnitDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganizationalUnitDtoFromJSON(jsonValue));
    }

    /**
     */
    async organizationalUnitControllerCreate(createUpdateOrganizationalUnitDto: CreateUpdateOrganizationalUnitDto): Promise<GetOrganizationalUnitDto> {
        const response = await this.organizationalUnitControllerCreateRaw({ createUpdateOrganizationalUnitDto: createUpdateOrganizationalUnitDto });
        return await response.value();
    }

    /**
     */
    async organizationalUnitControllerGetRaw(requestParameters: OrganizationalUnitControllerGetRequest): Promise<runtime.ApiResponse<GetOrganizationalUnitDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationalUnitControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizationalUnit/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganizationalUnitDtoFromJSON(jsonValue));
    }

    /**
     */
    async organizationalUnitControllerGet(id: string): Promise<GetOrganizationalUnitDto> {
        const response = await this.organizationalUnitControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async organizationalUnitControllerGetManyRaw(requestParameters: OrganizationalUnitControllerGetManyRequest): Promise<runtime.ApiResponse<Array<GetOrganizationalUnitDto>>> {
        if (requestParameters.options === null || requestParameters.options === undefined) {
            throw new runtime.RequiredError('options','Required parameter requestParameters.options was null or undefined when calling organizationalUnitControllerGetMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizationalUnit/get/{options}`.replace(`{${"options"}}`, encodeURIComponent(String(requestParameters.options))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganizationalUnitDtoFromJSON));
    }

    /**
     */
    async organizationalUnitControllerGetMany(options: number): Promise<Array<GetOrganizationalUnitDto>> {
        const response = await this.organizationalUnitControllerGetManyRaw({ options: options });
        return await response.value();
    }

    /**
     */
    async organizationalUnitControllerUpdateRaw(requestParameters: OrganizationalUnitControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling organizationalUnitControllerUpdate.');
        }

        if (requestParameters.createUpdateOrganizationalUnitDto === null || requestParameters.createUpdateOrganizationalUnitDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrganizationalUnitDto','Required parameter requestParameters.createUpdateOrganizationalUnitDto was null or undefined when calling organizationalUnitControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizationalUnit/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrganizationalUnitDtoToJSON(requestParameters.createUpdateOrganizationalUnitDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async organizationalUnitControllerUpdate(id: string, createUpdateOrganizationalUnitDto: CreateUpdateOrganizationalUnitDto): Promise<void> {
        await this.organizationalUnitControllerUpdateRaw({ id: id, createUpdateOrganizationalUnitDto: createUpdateOrganizationalUnitDto });
    }

    /**
     */
    async pakControllerApproveRaw(requestParameters: PakControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pakControllerApprove(id: string): Promise<void> {
        await this.pakControllerApproveRaw({ id: id });
    }

    /**
     */
    async pakControllerCreateBasedOnInspectedProductRaw(requestParameters: PakControllerCreateBasedOnInspectedProductRequest): Promise<runtime.ApiResponse<GetPakDto>> {
        if (requestParameters.getParams === null || requestParameters.getParams === undefined) {
            throw new runtime.RequiredError('getParams','Required parameter requestParameters.getParams was null or undefined when calling pakControllerCreateBasedOnInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/basedOnInspectedProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetParamsToJSON(requestParameters.getParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPakDtoFromJSON(jsonValue));
    }

    /**
     */
    async pakControllerCreateBasedOnInspectedProduct(getParams: GetParams): Promise<GetPakDto> {
        const response = await this.pakControllerCreateBasedOnInspectedProductRaw({ getParams: getParams });
        return await response.value();
    }

    /**
     */
    async pakControllerCreateBasedOnProceedingRaw(requestParameters: PakControllerCreateBasedOnProceedingRequest): Promise<runtime.ApiResponse<GetPakDto>> {
        if (requestParameters.createBasedOnProceedingParams === null || requestParameters.createBasedOnProceedingParams === undefined) {
            throw new runtime.RequiredError('createBasedOnProceedingParams','Required parameter requestParameters.createBasedOnProceedingParams was null or undefined when calling pakControllerCreateBasedOnProceeding.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/basedOnProceeding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBasedOnProceedingParamsToJSON(requestParameters.createBasedOnProceedingParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPakDtoFromJSON(jsonValue));
    }

    /**
     */
    async pakControllerCreateBasedOnProceeding(createBasedOnProceedingParams: CreateBasedOnProceedingParams): Promise<GetPakDto> {
        const response = await this.pakControllerCreateBasedOnProceedingRaw({ createBasedOnProceedingParams: createBasedOnProceedingParams });
        return await response.value();
    }

    /**
     */
    async pakControllerCreateBasedOnSampleRaw(requestParameters: PakControllerCreateBasedOnSampleRequest): Promise<runtime.ApiResponse<GetPakDto>> {
        if (requestParameters.getParams === null || requestParameters.getParams === undefined) {
            throw new runtime.RequiredError('getParams','Required parameter requestParameters.getParams was null or undefined when calling pakControllerCreateBasedOnSample.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/basedOnSample`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetParamsToJSON(requestParameters.getParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPakDtoFromJSON(jsonValue));
    }

    /**
     */
    async pakControllerCreateBasedOnSample(getParams: GetParams): Promise<GetPakDto> {
        const response = await this.pakControllerCreateBasedOnSampleRaw({ getParams: getParams });
        return await response.value();
    }

    /**
     */
    async pakControllerDeleteRaw(requestParameters: PakControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pakControllerDelete(id: string): Promise<void> {
        await this.pakControllerDeleteRaw({ id: id });
    }

    /**
     */
    async pakControllerGetRaw(requestParameters: PakControllerGetRequest): Promise<runtime.ApiResponse<GetPakDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPakDtoFromJSON(jsonValue));
    }

    /**
     */
    async pakControllerGet(id: string): Promise<GetPakDto> {
        const response = await this.pakControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pakControllerGetAllByPortionRaw(requestParameters: PakControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetPakWithPartiesDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.organizationalUnit === null || requestParameters.organizationalUnit === undefined) {
            throw new runtime.RequiredError('organizationalUnit','Required parameter requestParameters.organizationalUnit was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.organization === null || requestParameters.organization === undefined) {
            throw new runtime.RequiredError('organization','Required parameter requestParameters.organization was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.productType === null || requestParameters.productType === undefined) {
            throw new runtime.RequiredError('productType','Required parameter requestParameters.productType was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.startDateFrom === null || requestParameters.startDateFrom === undefined) {
            throw new runtime.RequiredError('startDateFrom','Required parameter requestParameters.startDateFrom was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.startDateTo === null || requestParameters.startDateTo === undefined) {
            throw new runtime.RequiredError('startDateTo','Required parameter requestParameters.startDateTo was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.endDateFrom === null || requestParameters.endDateFrom === undefined) {
            throw new runtime.RequiredError('endDateFrom','Required parameter requestParameters.endDateFrom was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.endDateTo === null || requestParameters.endDateTo === undefined) {
            throw new runtime.RequiredError('endDateTo','Required parameter requestParameters.endDateTo was null or undefined when calling pakControllerGetAllByPortion.');
        }

        if (requestParameters.fileNumber === null || requestParameters.fileNumber === undefined) {
            throw new runtime.RequiredError('fileNumber','Required parameter requestParameters.fileNumber was null or undefined when calling pakControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.organizationalUnit !== undefined) {
            queryParameters['organizationalUnit'] = requestParameters.organizationalUnit;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.productType !== undefined) {
            queryParameters['productType'] = requestParameters.productType;
        }

        if (requestParameters.startDateFrom !== undefined) {
            queryParameters['startDateFrom'] = requestParameters.startDateFrom;
        }

        if (requestParameters.startDateTo !== undefined) {
            queryParameters['startDateTo'] = requestParameters.startDateTo;
        }

        if (requestParameters.endDateFrom !== undefined) {
            queryParameters['endDateFrom'] = requestParameters.endDateFrom;
        }

        if (requestParameters.endDateTo !== undefined) {
            queryParameters['endDateTo'] = requestParameters.endDateTo;
        }

        if (requestParameters.fileNumber !== undefined) {
            queryParameters['fileNumber'] = requestParameters.fileNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPakWithPartiesDtoFromJSON));
    }

    /**
     */
    async pakControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, organizationalUnit: string, organization: string, productType: string, startDateFrom: string, startDateTo: string, endDateFrom: string, endDateTo: string, fileNumber: string): Promise<Array<GetPakWithPartiesDto>> {
        const response = await this.pakControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, organizationalUnit: organizationalUnit, organization: organization, productType: productType, startDateFrom: startDateFrom, startDateTo: startDateTo, endDateFrom: endDateFrom, endDateTo: endDateTo, fileNumber: fileNumber });
        return await response.value();
    }

    /**
     */
    async pakControllerGetAllCountRaw(requestParameters: PakControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling pakControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async pakControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.pakControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async pakControllerGetAllSourceIdsRaw(requestParameters: PakControllerGetAllSourceIdsRequest): Promise<runtime.ApiResponse<GetPakIdsWithAnotherSourceDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerGetAllSourceIds.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/paks/allSourceIds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPakIdsWithAnotherSourceDtoFromJSON(jsonValue));
    }

    /**
     */
    async pakControllerGetAllSourceIds(id: string): Promise<GetPakIdsWithAnotherSourceDto> {
        const response = await this.pakControllerGetAllSourceIdsRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pakControllerGetByInspectedProductRaw(requestParameters: PakControllerGetByInspectedProductRequest): Promise<runtime.ApiResponse<Array<GetPakDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerGetByInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/byInspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPakDtoFromJSON));
    }

    /**
     */
    async pakControllerGetByInspectedProduct(id: string): Promise<Array<GetPakDto>> {
        const response = await this.pakControllerGetByInspectedProductRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pakControllerGetByProceedingRaw(requestParameters: PakControllerGetByProceedingRequest): Promise<runtime.ApiResponse<Array<GetPakExtendedDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerGetByProceeding.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/byProceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPakExtendedDtoFromJSON));
    }

    /**
     */
    async pakControllerGetByProceeding(id: string): Promise<Array<GetPakExtendedDto>> {
        const response = await this.pakControllerGetByProceedingRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pakControllerGetBySampleRaw(requestParameters: PakControllerGetBySampleRequest): Promise<runtime.ApiResponse<Array<GetPakDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerGetBySample.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/bySample/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPakDtoFromJSON));
    }

    /**
     */
    async pakControllerGetBySample(id: string): Promise<Array<GetPakDto>> {
        const response = await this.pakControllerGetBySampleRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pakControllerGetFinishedForUserRaw(): Promise<runtime.ApiResponse<Array<GetPakExtendedDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/finished/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPakExtendedDtoFromJSON));
    }

    /**
     */
    async pakControllerGetFinishedForUser(): Promise<Array<GetPakExtendedDto>> {
        const response = await this.pakControllerGetFinishedForUserRaw();
        return await response.value();
    }

    /**
     */
    async pakControllerGetPendingForUserRaw(): Promise<runtime.ApiResponse<Array<GetPakExtendedDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/pending/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPakExtendedDtoFromJSON));
    }

    /**
     */
    async pakControllerGetPendingForUser(): Promise<Array<GetPakExtendedDto>> {
        const response = await this.pakControllerGetPendingForUserRaw();
        return await response.value();
    }

    /**
     */
    async pakControllerMayBeApprovedRaw(requestParameters: PakControllerMayBeApprovedRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerMayBeApproved.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/mayBeApproved/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async pakControllerMayBeApproved(id: string): Promise<ValidationStatus> {
        const response = await this.pakControllerMayBeApprovedRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pakControllerRevertApproveRaw(requestParameters: PakControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/revertApprove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pakControllerRevertApprove(id: string): Promise<void> {
        await this.pakControllerRevertApproveRaw({ id: id });
    }

    /**
     */
    async pakControllerUpdateRaw(requestParameters: PakControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pakControllerUpdate.');
        }

        if (requestParameters.createUpdatePakDto === null || requestParameters.createUpdatePakDto === undefined) {
            throw new runtime.RequiredError('createUpdatePakDto','Required parameter requestParameters.createUpdatePakDto was null or undefined when calling pakControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePakDtoToJSON(requestParameters.createUpdatePakDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pakControllerUpdate(id: string, createUpdatePakDto: CreateUpdatePakDto): Promise<void> {
        await this.pakControllerUpdateRaw({ id: id, createUpdatePakDto: createUpdatePakDto });
    }

    /**
     */
    async partyToProceedingControllerCreateRaw(requestParameters: PartyToProceedingControllerCreateRequest): Promise<runtime.ApiResponse<GetPartyToProceedingDto>> {
        if (requestParameters.createUpdatePartyToProceedingDto === null || requestParameters.createUpdatePartyToProceedingDto === undefined) {
            throw new runtime.RequiredError('createUpdatePartyToProceedingDto','Required parameter requestParameters.createUpdatePartyToProceedingDto was null or undefined when calling partyToProceedingControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partyToProceeding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePartyToProceedingDtoToJSON(requestParameters.createUpdatePartyToProceedingDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPartyToProceedingDtoFromJSON(jsonValue));
    }

    /**
     */
    async partyToProceedingControllerCreate(createUpdatePartyToProceedingDto: CreateUpdatePartyToProceedingDto): Promise<GetPartyToProceedingDto> {
        const response = await this.partyToProceedingControllerCreateRaw({ createUpdatePartyToProceedingDto: createUpdatePartyToProceedingDto });
        return await response.value();
    }

    /**
     */
    async partyToProceedingControllerCreateWithOrganizationRaw(requestParameters: PartyToProceedingControllerCreateWithOrganizationRequest): Promise<runtime.ApiResponse<GetPartyToProceedingDto>> {
        if (requestParameters.createUpdatePartyWithOrganizationDto === null || requestParameters.createUpdatePartyWithOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdatePartyWithOrganizationDto','Required parameter requestParameters.createUpdatePartyWithOrganizationDto was null or undefined when calling partyToProceedingControllerCreateWithOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partyToProceeding/withOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePartyWithOrganizationDtoToJSON(requestParameters.createUpdatePartyWithOrganizationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPartyToProceedingDtoFromJSON(jsonValue));
    }

    /**
     */
    async partyToProceedingControllerCreateWithOrganization(createUpdatePartyWithOrganizationDto: CreateUpdatePartyWithOrganizationDto): Promise<GetPartyToProceedingDto> {
        const response = await this.partyToProceedingControllerCreateWithOrganizationRaw({ createUpdatePartyWithOrganizationDto: createUpdatePartyWithOrganizationDto });
        return await response.value();
    }

    /**
     */
    async partyToProceedingControllerDeleteRaw(requestParameters: PartyToProceedingControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partyToProceedingControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partyToProceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async partyToProceedingControllerDelete(id: string): Promise<void> {
        await this.partyToProceedingControllerDeleteRaw({ id: id });
    }

    /**
     */
    async partyToProceedingControllerGetRaw(requestParameters: PartyToProceedingControllerGetRequest): Promise<runtime.ApiResponse<GetPartyToProceedingDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partyToProceedingControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partyToProceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPartyToProceedingDtoFromJSON(jsonValue));
    }

    /**
     */
    async partyToProceedingControllerGet(id: string): Promise<GetPartyToProceedingDto> {
        const response = await this.partyToProceedingControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async partyToProceedingControllerGetByPakRaw(requestParameters: PartyToProceedingControllerGetByPakRequest): Promise<runtime.ApiResponse<Array<GetPartyToProceedingDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partyToProceedingControllerGetByPak.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partyToProceeding/byPak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPartyToProceedingDtoFromJSON));
    }

    /**
     */
    async partyToProceedingControllerGetByPak(id: string): Promise<Array<GetPartyToProceedingDto>> {
        const response = await this.partyToProceedingControllerGetByPakRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async partyToProceedingControllerGetByProceedingRaw(requestParameters: PartyToProceedingControllerGetByProceedingRequest): Promise<runtime.ApiResponse<Array<GetPartyToProceedingDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partyToProceedingControllerGetByProceeding.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partyToProceeding/byProceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPartyToProceedingDtoFromJSON));
    }

    /**
     */
    async partyToProceedingControllerGetByProceeding(id: string): Promise<Array<GetPartyToProceedingDto>> {
        const response = await this.partyToProceedingControllerGetByProceedingRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async partyToProceedingControllerUpdateRaw(requestParameters: PartyToProceedingControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partyToProceedingControllerUpdate.');
        }

        if (requestParameters.createUpdatePartyToProceedingDto === null || requestParameters.createUpdatePartyToProceedingDto === undefined) {
            throw new runtime.RequiredError('createUpdatePartyToProceedingDto','Required parameter requestParameters.createUpdatePartyToProceedingDto was null or undefined when calling partyToProceedingControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partyToProceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePartyToProceedingDtoToJSON(requestParameters.createUpdatePartyToProceedingDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async partyToProceedingControllerUpdate(id: string, createUpdatePartyToProceedingDto: CreateUpdatePartyToProceedingDto): Promise<void> {
        await this.partyToProceedingControllerUpdateRaw({ id: id, createUpdatePartyToProceedingDto: createUpdatePartyToProceedingDto });
    }

    /**
     */
    async partyToProceedingControllerValidateNewRaw(requestParameters: PartyToProceedingControllerValidateNewRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.createUpdatePartyToProceedingDto === null || requestParameters.createUpdatePartyToProceedingDto === undefined) {
            throw new runtime.RequiredError('createUpdatePartyToProceedingDto','Required parameter requestParameters.createUpdatePartyToProceedingDto was null or undefined when calling partyToProceedingControllerValidateNew.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/partyToProceeding/validateNew`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePartyToProceedingDtoToJSON(requestParameters.createUpdatePartyToProceedingDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async partyToProceedingControllerValidateNew(createUpdatePartyToProceedingDto: CreateUpdatePartyToProceedingDto): Promise<ValidationStatus> {
        const response = await this.partyToProceedingControllerValidateNewRaw({ createUpdatePartyToProceedingDto: createUpdatePartyToProceedingDto });
        return await response.value();
    }

    /**
     */
    async pdfPrinterControllerGetExamActivityProtocolRaw(requestParameters: PdfPrinterControllerGetExamActivityProtocolRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerGetExamActivityProtocol.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/examActivityProtocol/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetExamActivityProtocol(id: string): Promise<void> {
        await this.pdfPrinterControllerGetExamActivityProtocolRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerGetExamResultRaw(requestParameters: PdfPrinterControllerGetExamResultRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId','Required parameter requestParameters.sampleId was null or undefined when calling pdfPrinterControllerGetExamResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/examResult/{sampleId}`.replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetExamResult(sampleId: string): Promise<void> {
        await this.pdfPrinterControllerGetExamResultRaw({ sampleId: sampleId });
    }

    /**
     */
    async pdfPrinterControllerGetExaminationProtocolRaw(requestParameters: PdfPrinterControllerGetExaminationProtocolRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerGetExaminationProtocol.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/examinationProtocol/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetExaminationProtocol(id: string): Promise<void> {
        await this.pdfPrinterControllerGetExaminationProtocolRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerGetExaminationProtocolOnTheFlyRaw(requestParameters: PdfPrinterControllerGetExaminationProtocolOnTheFlyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.getProtocolDuringInspectionDto === null || requestParameters.getProtocolDuringInspectionDto === undefined) {
            throw new runtime.RequiredError('getProtocolDuringInspectionDto','Required parameter requestParameters.getProtocolDuringInspectionDto was null or undefined when calling pdfPrinterControllerGetExaminationProtocolOnTheFly.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/examinationProtocolOnTheFly`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GetProtocolDuringInspectionDtoToJSON(requestParameters.getProtocolDuringInspectionDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetExaminationProtocolOnTheFly(getProtocolDuringInspectionDto: GetProtocolDuringInspectionDto): Promise<void> {
        await this.pdfPrinterControllerGetExaminationProtocolOnTheFlyRaw({ getProtocolDuringInspectionDto: getProtocolDuringInspectionDto });
    }

    /**
     */
    async pdfPrinterControllerGetInspectionProtocolRaw(requestParameters: PdfPrinterControllerGetInspectionProtocolRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerGetInspectionProtocol.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/inspectionProtocol/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetInspectionProtocol(id: string): Promise<void> {
        await this.pdfPrinterControllerGetInspectionProtocolRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerGetInventoryProtocolRaw(requestParameters: PdfPrinterControllerGetInventoryProtocolRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerGetInventoryProtocol.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/inventoryProtocol/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetInventoryProtocol(id: string): Promise<void> {
        await this.pdfPrinterControllerGetInventoryProtocolRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerGetInventoryProtocolOnTheFlyRaw(requestParameters: PdfPrinterControllerGetInventoryProtocolOnTheFlyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.getProtocolDuringInspectionDto === null || requestParameters.getProtocolDuringInspectionDto === undefined) {
            throw new runtime.RequiredError('getProtocolDuringInspectionDto','Required parameter requestParameters.getProtocolDuringInspectionDto was null or undefined when calling pdfPrinterControllerGetInventoryProtocolOnTheFly.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/inventoryProtocolOnTheFly`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GetProtocolDuringInspectionDtoToJSON(requestParameters.getProtocolDuringInspectionDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetInventoryProtocolOnTheFly(getProtocolDuringInspectionDto: GetProtocolDuringInspectionDto): Promise<void> {
        await this.pdfPrinterControllerGetInventoryProtocolOnTheFlyRaw({ getProtocolDuringInspectionDto: getProtocolDuringInspectionDto });
    }

    /**
     */
    async pdfPrinterControllerGetKwzwbRaw(requestParameters: PdfPrinterControllerGetKwzwbRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerGetKwzwb.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/kwzwb/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetKwzwb(id: string): Promise<void> {
        await this.pdfPrinterControllerGetKwzwbRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerGetSampleExamProtocolRaw(requestParameters: PdfPrinterControllerGetSampleExamProtocolRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerGetSampleExamProtocol.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfPrinter/sampleExamProtocol/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerGetSampleExamProtocol(id: string): Promise<void> {
        await this.pdfPrinterControllerGetSampleExamProtocolRaw({ id: id });
    }

    /**
     */
    async pdfTemplateControllerGetRaw(requestParameters: PdfTemplateControllerGetRequest): Promise<runtime.ApiResponse<GetPdfTemplateDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfTemplateControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfTemplate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPdfTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async pdfTemplateControllerGet(id: string): Promise<GetPdfTemplateDto> {
        const response = await this.pdfTemplateControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pdfTemplateControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetPdfTemplateDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfTemplate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPdfTemplateDtoFromJSON));
    }

    /**
     */
    async pdfTemplateControllerGetAll(): Promise<Array<GetPdfTemplateDto>> {
        const response = await this.pdfTemplateControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async pdfTemplateControllerUpdateRaw(requestParameters: PdfTemplateControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfTemplateControllerUpdate.');
        }

        if (requestParameters.createUpdatePdfTemplateDto === null || requestParameters.createUpdatePdfTemplateDto === undefined) {
            throw new runtime.RequiredError('createUpdatePdfTemplateDto','Required parameter requestParameters.createUpdatePdfTemplateDto was null or undefined when calling pdfTemplateControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfTemplate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePdfTemplateDtoToJSON(requestParameters.createUpdatePdfTemplateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfTemplateControllerUpdate(id: string, createUpdatePdfTemplateDto: CreateUpdatePdfTemplateDto): Promise<void> {
        await this.pdfTemplateControllerUpdateRaw({ id: id, createUpdatePdfTemplateDto: createUpdatePdfTemplateDto });
    }

    /**
     */
    async proceedingControllerApproveRaw(requestParameters: ProceedingControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async proceedingControllerApprove(id: string): Promise<void> {
        await this.proceedingControllerApproveRaw({ id: id });
    }

    /**
     */
    async proceedingControllerControlSampleExamRaw(requestParameters: ProceedingControllerControlSampleExamRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerControlSampleExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/controlSampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async proceedingControllerControlSampleExam(id: string): Promise<void> {
        await this.proceedingControllerControlSampleExamRaw({ id: id });
    }

    /**
     */
    async proceedingControllerCreateBasedOnMetricRaw(requestParameters: ProceedingControllerCreateBasedOnMetricRequest): Promise<runtime.ApiResponse<GetProceedingDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerCreateBasedOnMetric.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/basedOnInspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProceedingDtoFromJSON(jsonValue));
    }

    /**
     */
    async proceedingControllerCreateBasedOnMetric(id: string): Promise<GetProceedingDto> {
        const response = await this.proceedingControllerCreateBasedOnMetricRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async proceedingControllerDeleteRaw(requestParameters: ProceedingControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async proceedingControllerDelete(id: string): Promise<void> {
        await this.proceedingControllerDeleteRaw({ id: id });
    }

    /**
     */
    async proceedingControllerGetRaw(requestParameters: ProceedingControllerGetRequest): Promise<runtime.ApiResponse<GetProceedingDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProceedingDtoFromJSON(jsonValue));
    }

    /**
     */
    async proceedingControllerGet(id: string): Promise<GetProceedingDto> {
        const response = await this.proceedingControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async proceedingControllerGetAllByPortionRaw(requestParameters: ProceedingControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetProceedingWithPartiesDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.organizationalUnit === null || requestParameters.organizationalUnit === undefined) {
            throw new runtime.RequiredError('organizationalUnit','Required parameter requestParameters.organizationalUnit was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.organization === null || requestParameters.organization === undefined) {
            throw new runtime.RequiredError('organization','Required parameter requestParameters.organization was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.productType === null || requestParameters.productType === undefined) {
            throw new runtime.RequiredError('productType','Required parameter requestParameters.productType was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.startDateFrom === null || requestParameters.startDateFrom === undefined) {
            throw new runtime.RequiredError('startDateFrom','Required parameter requestParameters.startDateFrom was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.startDateTo === null || requestParameters.startDateTo === undefined) {
            throw new runtime.RequiredError('startDateTo','Required parameter requestParameters.startDateTo was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.endDateFrom === null || requestParameters.endDateFrom === undefined) {
            throw new runtime.RequiredError('endDateFrom','Required parameter requestParameters.endDateFrom was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.endDateTo === null || requestParameters.endDateTo === undefined) {
            throw new runtime.RequiredError('endDateTo','Required parameter requestParameters.endDateTo was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        if (requestParameters.fileNumber === null || requestParameters.fileNumber === undefined) {
            throw new runtime.RequiredError('fileNumber','Required parameter requestParameters.fileNumber was null or undefined when calling proceedingControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.organizationalUnit !== undefined) {
            queryParameters['organizationalUnit'] = requestParameters.organizationalUnit;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.productType !== undefined) {
            queryParameters['productType'] = requestParameters.productType;
        }

        if (requestParameters.startDateFrom !== undefined) {
            queryParameters['startDateFrom'] = requestParameters.startDateFrom;
        }

        if (requestParameters.startDateTo !== undefined) {
            queryParameters['startDateTo'] = requestParameters.startDateTo;
        }

        if (requestParameters.endDateFrom !== undefined) {
            queryParameters['endDateFrom'] = requestParameters.endDateFrom;
        }

        if (requestParameters.endDateTo !== undefined) {
            queryParameters['endDateTo'] = requestParameters.endDateTo;
        }

        if (requestParameters.fileNumber !== undefined) {
            queryParameters['fileNumber'] = requestParameters.fileNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProceedingWithPartiesDtoFromJSON));
    }

    /**
     */
    async proceedingControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, organizationalUnit: string, organization: string, productType: string, startDateFrom: string, startDateTo: string, endDateFrom: string, endDateTo: string, fileNumber: string): Promise<Array<GetProceedingWithPartiesDto>> {
        const response = await this.proceedingControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, organizationalUnit: organizationalUnit, organization: organization, productType: productType, startDateFrom: startDateFrom, startDateTo: startDateTo, endDateFrom: endDateFrom, endDateTo: endDateTo, fileNumber: fileNumber });
        return await response.value();
    }

    /**
     */
    async proceedingControllerGetAllCountRaw(requestParameters: ProceedingControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling proceedingControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async proceedingControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.proceedingControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async proceedingControllerGetByInspectionRaw(requestParameters: ProceedingControllerGetByInspectionRequest): Promise<runtime.ApiResponse<Array<GetProceedingDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerGetByInspection.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/byInspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProceedingDtoFromJSON));
    }

    /**
     */
    async proceedingControllerGetByInspection(id: string): Promise<Array<GetProceedingDto>> {
        const response = await this.proceedingControllerGetByInspectionRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async proceedingControllerGetByPakRaw(requestParameters: ProceedingControllerGetByPakRequest): Promise<runtime.ApiResponse<Array<GetProceedingDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerGetByPak.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/byPak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProceedingDtoFromJSON));
    }

    /**
     */
    async proceedingControllerGetByPak(id: string): Promise<Array<GetProceedingDto>> {
        const response = await this.proceedingControllerGetByPakRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async proceedingControllerGetBySampleExamRaw(requestParameters: ProceedingControllerGetBySampleExamRequest): Promise<runtime.ApiResponse<Array<GetProceedingDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerGetBySampleExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/bySampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProceedingDtoFromJSON));
    }

    /**
     */
    async proceedingControllerGetBySampleExam(id: string): Promise<Array<GetProceedingDto>> {
        const response = await this.proceedingControllerGetBySampleExamRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async proceedingControllerGetFinishedForUserRaw(): Promise<runtime.ApiResponse<Array<GetProceedingExtendedDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/finished/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProceedingExtendedDtoFromJSON));
    }

    /**
     */
    async proceedingControllerGetFinishedForUser(): Promise<Array<GetProceedingExtendedDto>> {
        const response = await this.proceedingControllerGetFinishedForUserRaw();
        return await response.value();
    }

    /**
     */
    async proceedingControllerGetPendingForUserRaw(): Promise<runtime.ApiResponse<Array<GetProceedingExtendedDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/pending/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProceedingExtendedDtoFromJSON));
    }

    /**
     */
    async proceedingControllerGetPendingForUser(): Promise<Array<GetProceedingExtendedDto>> {
        const response = await this.proceedingControllerGetPendingForUserRaw();
        return await response.value();
    }

    /**
     */
    async proceedingControllerGetWithinspectedProductRaw(requestParameters: ProceedingControllerGetWithinspectedProductRequest): Promise<runtime.ApiResponse<Array<GetProceedingDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerGetWithinspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/withInspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProceedingDtoFromJSON));
    }

    /**
     */
    async proceedingControllerGetWithinspectedProduct(id: string): Promise<Array<GetProceedingDto>> {
        const response = await this.proceedingControllerGetWithinspectedProductRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async proceedingControllerMayBeApprovedRaw(requestParameters: ProceedingControllerMayBeApprovedRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerMayBeApproved.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/mayBeApproved/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async proceedingControllerMayBeApproved(id: string): Promise<ValidationStatus> {
        const response = await this.proceedingControllerMayBeApprovedRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async proceedingControllerMayExamControlSampleRaw(requestParameters: ProceedingControllerMayExamControlSampleRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerMayExamControlSample.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/mayExamControlSample/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async proceedingControllerMayExamControlSample(id: string): Promise<ValidationStatus> {
        const response = await this.proceedingControllerMayExamControlSampleRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async proceedingControllerRevertApproveRaw(requestParameters: ProceedingControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/revertApprove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async proceedingControllerRevertApprove(id: string): Promise<void> {
        await this.proceedingControllerRevertApproveRaw({ id: id });
    }

    /**
     */
    async proceedingControllerRevertControlSampleExamRaw(requestParameters: ProceedingControllerRevertControlSampleExamRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerRevertControlSampleExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/revertControlSampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async proceedingControllerRevertControlSampleExam(id: string): Promise<void> {
        await this.proceedingControllerRevertControlSampleExamRaw({ id: id });
    }

    /**
     */
    async proceedingControllerUpdateRaw(requestParameters: ProceedingControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling proceedingControllerUpdate.');
        }

        if (requestParameters.createUpdateProceedingDto === null || requestParameters.createUpdateProceedingDto === undefined) {
            throw new runtime.RequiredError('createUpdateProceedingDto','Required parameter requestParameters.createUpdateProceedingDto was null or undefined when calling proceedingControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proceeding/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProceedingDtoToJSON(requestParameters.createUpdateProceedingDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async proceedingControllerUpdate(id: string, createUpdateProceedingDto: CreateUpdateProceedingDto): Promise<void> {
        await this.proceedingControllerUpdateRaw({ id: id, createUpdateProceedingDto: createUpdateProceedingDto });
    }

    /**
     */
    async productTypeControllerApproveRaw(requestParameters: ProductTypeControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productTypeControllerApprove.');
        }

        if (requestParameters.validationStatus === null || requestParameters.validationStatus === undefined) {
            throw new runtime.RequiredError('validationStatus','Required parameter requestParameters.validationStatus was null or undefined when calling productTypeControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ValidationStatusToJSON(requestParameters.validationStatus),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTypeControllerApprove(id: string, validationStatus: ValidationStatus): Promise<void> {
        await this.productTypeControllerApproveRaw({ id: id, validationStatus: validationStatus });
    }

    /**
     */
    async productTypeControllerCreateRaw(requestParameters: ProductTypeControllerCreateRequest): Promise<runtime.ApiResponse<GetProductTypeDto>> {
        if (requestParameters.createUpdateProductTypeDto === null || requestParameters.createUpdateProductTypeDto === undefined) {
            throw new runtime.RequiredError('createUpdateProductTypeDto','Required parameter requestParameters.createUpdateProductTypeDto was null or undefined when calling productTypeControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProductTypeDtoToJSON(requestParameters.createUpdateProductTypeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async productTypeControllerCreate(createUpdateProductTypeDto: CreateUpdateProductTypeDto): Promise<GetProductTypeDto> {
        const response = await this.productTypeControllerCreateRaw({ createUpdateProductTypeDto: createUpdateProductTypeDto });
        return await response.value();
    }

    /**
     */
    async productTypeControllerGetRaw(requestParameters: ProductTypeControllerGetRequest): Promise<runtime.ApiResponse<GetProductTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productTypeControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async productTypeControllerGet(id: string): Promise<GetProductTypeDto> {
        const response = await this.productTypeControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async productTypeControllerGetAllByPortionRaw(requestParameters: ProductTypeControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetProductTypeDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling productTypeControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling productTypeControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling productTypeControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling productTypeControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling productTypeControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProductTypeDtoFromJSON));
    }

    /**
     */
    async productTypeControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetProductTypeDto>> {
        const response = await this.productTypeControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async productTypeControllerGetAllCountRaw(requestParameters: ProductTypeControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling productTypeControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async productTypeControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.productTypeControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async productTypeControllerGetByOrganizationByPortionRaw(requestParameters: ProductTypeControllerGetByOrganizationByPortionRequest): Promise<runtime.ApiResponse<Array<GetProductTypeDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling productTypeControllerGetByOrganizationByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling productTypeControllerGetByOrganizationByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling productTypeControllerGetByOrganizationByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling productTypeControllerGetByOrganizationByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling productTypeControllerGetByOrganizationByPortion.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling productTypeControllerGetByOrganizationByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/searchByOrganization/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProductTypeDtoFromJSON));
    }

    /**
     */
    async productTypeControllerGetByOrganizationByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, organizationId: string): Promise<Array<GetProductTypeDto>> {
        const response = await this.productTypeControllerGetByOrganizationByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, organizationId: organizationId });
        return await response.value();
    }

    /**
     */
    async productTypeControllerGetByOrganizationCountRaw(requestParameters: ProductTypeControllerGetByOrganizationCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling productTypeControllerGetByOrganizationCount.');
        }

        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling productTypeControllerGetByOrganizationCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/searchByOrganization/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async productTypeControllerGetByOrganizationCount(filterText: string, organizationId: string): Promise<number> {
        const response = await this.productTypeControllerGetByOrganizationCountRaw({ filterText: filterText, organizationId: organizationId });
        return await response.value();
    }

    /**
     */
    async productTypeControllerGetByPatternRaw(requestParameters: ProductTypeControllerGetByPatternRequest): Promise<runtime.ApiResponse<Array<GetProductTypeDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling productTypeControllerGetByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling productTypeControllerGetByPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/search/byNkitw`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProductTypeDtoFromJSON));
    }

    /**
     */
    async productTypeControllerGetByPattern(pattern: string, limit: number): Promise<Array<GetProductTypeDto>> {
        const response = await this.productTypeControllerGetByPatternRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async productTypeControllerGetByProducerAndPatternRaw(requestParameters: ProductTypeControllerGetByProducerAndPatternRequest): Promise<runtime.ApiResponse<Array<GetProductTypeDto>>> {
        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
            throw new runtime.RequiredError('producerId','Required parameter requestParameters.producerId was null or undefined when calling productTypeControllerGetByProducerAndPattern.');
        }

        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling productTypeControllerGetByProducerAndPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling productTypeControllerGetByProducerAndPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.producerId !== undefined) {
            queryParameters['producerId'] = requestParameters.producerId;
        }

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/search/byProducerNkitw`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProductTypeDtoFromJSON));
    }

    /**
     */
    async productTypeControllerGetByProducerAndPattern(producerId: string, pattern: string, limit: number): Promise<Array<GetProductTypeDto>> {
        const response = await this.productTypeControllerGetByProducerAndPatternRaw({ producerId: producerId, pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async productTypeControllerGetLinkedToExamSampleRaw(requestParameters: ProductTypeControllerGetLinkedToExamSampleRequest): Promise<runtime.ApiResponse<Array<GetLinkedExamToSampleDto>>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling productTypeControllerGetLinkedToExamSample.');
        }

        if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
            throw new runtime.RequiredError('typeId','Required parameter requestParameters.typeId was null or undefined when calling productTypeControllerGetLinkedToExamSample.');
        }

        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling productTypeControllerGetLinkedToExamSample.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling productTypeControllerGetLinkedToExamSample.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/linkedToExamSample/{examId}/{typeId}`.replace(`{${"examId"}}`, encodeURIComponent(String(requestParameters.examId))).replace(`{${"typeId"}}`, encodeURIComponent(String(requestParameters.typeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetLinkedExamToSampleDtoFromJSON));
    }

    /**
     */
    async productTypeControllerGetLinkedToExamSample(examId: string, typeId: string, pattern: string, limit: number): Promise<Array<GetLinkedExamToSampleDto>> {
        const response = await this.productTypeControllerGetLinkedToExamSampleRaw({ examId: examId, typeId: typeId, pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async productTypeControllerMayBeApprovedRaw(requestParameters: ProductTypeControllerMayBeApprovedRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productTypeControllerMayBeApproved.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/mayBeApproved/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async productTypeControllerMayBeApproved(id: string): Promise<ValidationStatus> {
        const response = await this.productTypeControllerMayBeApprovedRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async productTypeControllerMergeProductTypesRaw(requestParameters: ProductTypeControllerMergeProductTypesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.prodDeleteId === null || requestParameters.prodDeleteId === undefined) {
            throw new runtime.RequiredError('prodDeleteId','Required parameter requestParameters.prodDeleteId was null or undefined when calling productTypeControllerMergeProductTypes.');
        }

        if (requestParameters.prodStayId === null || requestParameters.prodStayId === undefined) {
            throw new runtime.RequiredError('prodStayId','Required parameter requestParameters.prodStayId was null or undefined when calling productTypeControllerMergeProductTypes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.prodDeleteId !== undefined) {
            queryParameters['prodDeleteId'] = requestParameters.prodDeleteId;
        }

        if (requestParameters.prodStayId !== undefined) {
            queryParameters['prodStayId'] = requestParameters.prodStayId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/merge/productTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTypeControllerMergeProductTypes(prodDeleteId: string, prodStayId: string): Promise<void> {
        await this.productTypeControllerMergeProductTypesRaw({ prodDeleteId: prodDeleteId, prodStayId: prodStayId });
    }

    /**
     */
    async productTypeControllerRevertApproveRaw(requestParameters: ProductTypeControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productTypeControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/revertApprove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTypeControllerRevertApprove(id: string): Promise<void> {
        await this.productTypeControllerRevertApproveRaw({ id: id });
    }

    /**
     */
    async productTypeControllerUpdateRaw(requestParameters: ProductTypeControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productTypeControllerUpdate.');
        }

        if (requestParameters.createUpdateProductTypeDto === null || requestParameters.createUpdateProductTypeDto === undefined) {
            throw new runtime.RequiredError('createUpdateProductTypeDto','Required parameter requestParameters.createUpdateProductTypeDto was null or undefined when calling productTypeControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProductTypeDtoToJSON(requestParameters.createUpdateProductTypeDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productTypeControllerUpdate(id: string, createUpdateProductTypeDto: CreateUpdateProductTypeDto): Promise<void> {
        await this.productTypeControllerUpdateRaw({ id: id, createUpdateProductTypeDto: createUpdateProductTypeDto });
    }

    /**
     */
    async productTypeControllerValidateNewRaw(requestParameters: ProductTypeControllerValidateNewRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.createUpdateProductTypeDto === null || requestParameters.createUpdateProductTypeDto === undefined) {
            throw new runtime.RequiredError('createUpdateProductTypeDto','Required parameter requestParameters.createUpdateProductTypeDto was null or undefined when calling productTypeControllerValidateNew.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productType/validateNew`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProductTypeDtoToJSON(requestParameters.createUpdateProductTypeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async productTypeControllerValidateNew(createUpdateProductTypeDto: CreateUpdateProductTypeDto): Promise<ValidationStatus> {
        const response = await this.productTypeControllerValidateNewRaw({ createUpdateProductTypeDto: createUpdateProductTypeDto });
        return await response.value();
    }

    /**
     */
    async protocolDuringInspectionControllerApproveRaw(requestParameters: ProtocolDuringInspectionControllerApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling protocolDuringInspectionControllerApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/protocolDuringInspection/approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async protocolDuringInspectionControllerApprove(id: string): Promise<void> {
        await this.protocolDuringInspectionControllerApproveRaw({ id: id });
    }

    /**
     */
    async protocolDuringInspectionControllerCreateRaw(requestParameters: ProtocolDuringInspectionControllerCreateRequest): Promise<runtime.ApiResponse<GetProtocolDuringInspectionDto>> {
        if (requestParameters.createUpdateProtocolDuringInspectionDto === null || requestParameters.createUpdateProtocolDuringInspectionDto === undefined) {
            throw new runtime.RequiredError('createUpdateProtocolDuringInspectionDto','Required parameter requestParameters.createUpdateProtocolDuringInspectionDto was null or undefined when calling protocolDuringInspectionControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/protocolDuringInspection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProtocolDuringInspectionDtoToJSON(requestParameters.createUpdateProtocolDuringInspectionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtocolDuringInspectionDtoFromJSON(jsonValue));
    }

    /**
     */
    async protocolDuringInspectionControllerCreate(createUpdateProtocolDuringInspectionDto: CreateUpdateProtocolDuringInspectionDto): Promise<GetProtocolDuringInspectionDto> {
        const response = await this.protocolDuringInspectionControllerCreateRaw({ createUpdateProtocolDuringInspectionDto: createUpdateProtocolDuringInspectionDto });
        return await response.value();
    }

    /**
     */
    async protocolDuringInspectionControllerDeleteRaw(requestParameters: ProtocolDuringInspectionControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling protocolDuringInspectionControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/protocolDuringInspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async protocolDuringInspectionControllerDelete(id: string): Promise<void> {
        await this.protocolDuringInspectionControllerDeleteRaw({ id: id });
    }

    /**
     */
    async protocolDuringInspectionControllerGetRaw(requestParameters: ProtocolDuringInspectionControllerGetRequest): Promise<runtime.ApiResponse<GetProtocolDuringInspectionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling protocolDuringInspectionControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/protocolDuringInspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProtocolDuringInspectionDtoFromJSON(jsonValue));
    }

    /**
     */
    async protocolDuringInspectionControllerGet(id: string): Promise<GetProtocolDuringInspectionDto> {
        const response = await this.protocolDuringInspectionControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async protocolDuringInspectionControllerGetByInspectedProductRaw(requestParameters: ProtocolDuringInspectionControllerGetByInspectedProductRequest): Promise<runtime.ApiResponse<Array<GetProtocolDuringInspectionDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling protocolDuringInspectionControllerGetByInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/protocolDuringInspection/byInspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProtocolDuringInspectionDtoFromJSON));
    }

    /**
     */
    async protocolDuringInspectionControllerGetByInspectedProduct(id: string): Promise<Array<GetProtocolDuringInspectionDto>> {
        const response = await this.protocolDuringInspectionControllerGetByInspectedProductRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async protocolDuringInspectionControllerRevertApproveRaw(requestParameters: ProtocolDuringInspectionControllerRevertApproveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling protocolDuringInspectionControllerRevertApprove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/protocolDuringInspection/revertApprove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async protocolDuringInspectionControllerRevertApprove(id: string): Promise<void> {
        await this.protocolDuringInspectionControllerRevertApproveRaw({ id: id });
    }

    /**
     */
    async protocolDuringInspectionControllerUpdateRaw(requestParameters: ProtocolDuringInspectionControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling protocolDuringInspectionControllerUpdate.');
        }

        if (requestParameters.createUpdateProtocolDuringInspectionDto === null || requestParameters.createUpdateProtocolDuringInspectionDto === undefined) {
            throw new runtime.RequiredError('createUpdateProtocolDuringInspectionDto','Required parameter requestParameters.createUpdateProtocolDuringInspectionDto was null or undefined when calling protocolDuringInspectionControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/protocolDuringInspection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProtocolDuringInspectionDtoToJSON(requestParameters.createUpdateProtocolDuringInspectionDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async protocolDuringInspectionControllerUpdate(id: string, createUpdateProtocolDuringInspectionDto: CreateUpdateProtocolDuringInspectionDto): Promise<void> {
        await this.protocolDuringInspectionControllerUpdateRaw({ id: id, createUpdateProtocolDuringInspectionDto: createUpdateProtocolDuringInspectionDto });
    }

    /**
     */
    async pubControllerGetAttachmentForExamResultRaw(requestParameters: PubControllerGetAttachmentForExamResultRequest): Promise<runtime.ApiResponse<AttachmentFileContent>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pubControllerGetAttachmentForExamResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/fileDownload/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFileContentFromJSON(jsonValue));
    }

    /**
     */
    async pubControllerGetAttachmentForExamResult(id: string): Promise<AttachmentFileContent> {
        const response = await this.pubControllerGetAttachmentForExamResultRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pubControllerGetEssentialFeaturesForExamRaw(requestParameters: PubControllerGetEssentialFeaturesForExamRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pubControllerGetEssentialFeaturesForExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/essentialFeaturesForExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pubControllerGetEssentialFeaturesForExam(id: string): Promise<void> {
        await this.pubControllerGetEssentialFeaturesForExamRaw({ id: id });
    }

    /**
     */
    async pubControllerGetExamResultsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/examResults`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pubControllerGetExamResults(): Promise<void> {
        await this.pubControllerGetExamResultsRaw();
    }

    /**
     */
    async pubControllerGetKwzIndexDictionaryRaw(): Promise<runtime.ApiResponse<Array<GetDictionaryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/kwzIndexes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryDtoFromJSON));
    }

    /**
     */
    async pubControllerGetKwzIndexDictionary(): Promise<Array<GetDictionaryDto>> {
        const response = await this.pubControllerGetKwzIndexDictionaryRaw();
        return await response.value();
    }

    /**
     */
    async pubControllerGetKwzSymbolDictionaryRaw(): Promise<runtime.ApiResponse<Array<GetDictionaryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/kwzSymbols`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryDtoFromJSON));
    }

    /**
     */
    async pubControllerGetKwzSymbolDictionary(): Promise<Array<GetDictionaryDto>> {
        const response = await this.pubControllerGetKwzSymbolDictionaryRaw();
        return await response.value();
    }

    /**
     */
    async pubControllerGetKwzsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/kwzs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pubControllerGetKwzs(): Promise<void> {
        await this.pubControllerGetKwzsRaw();
    }

    /**
     */
    async pubControllerGetLatestExamResultDateRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/latestExamResultDate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pubControllerGetLatestExamResultDate(): Promise<void> {
        await this.pubControllerGetLatestExamResultDateRaw();
    }

    /**
     */
    async pubControllerGetMessagesRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pubControllerGetMessages(): Promise<void> {
        await this.pubControllerGetMessagesRaw();
    }

    /**
     */
    async pubControllerGetOrganizationalUnitsRaw(): Promise<runtime.ApiResponse<Array<GetOrganizationalUnitDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/units`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganizationalUnitDtoFromJSON));
    }

    /**
     */
    async pubControllerGetOrganizationalUnits(): Promise<Array<GetOrganizationalUnitDto>> {
        const response = await this.pubControllerGetOrganizationalUnitsRaw();
        return await response.value();
    }

    /**
     */
    async pubControllerGetPrintedKwzCardRaw(requestParameters: PubControllerGetPrintedKwzCardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pubControllerGetPrintedKwzCard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/printedKwzCard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pubControllerGetPrintedKwzCard(id: string): Promise<void> {
        await this.pubControllerGetPrintedKwzCardRaw({ id: id });
    }

    /**
     */
    async pubControllerGetProducersRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/producers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pubControllerGetProducers(): Promise<void> {
        await this.pubControllerGetProducersRaw();
    }

    /**
     */
    async pubControllerGetProductTypesDictionaryRaw(): Promise<runtime.ApiResponse<Array<GetDictionaryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pub/productTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryDtoFromJSON));
    }

    /**
     */
    async pubControllerGetProductTypesDictionary(): Promise<Array<GetDictionaryDto>> {
        const response = await this.pubControllerGetProductTypesDictionaryRaw();
        return await response.value();
    }

    /**
     */
    async reportControllerGetExamResultHistoryTimelineRaw(requestParameters: ReportControllerGetExamResultHistoryTimelineRequest): Promise<runtime.ApiResponse<Array<TimelineProcessType>>> {
        if (requestParameters.examResultId === null || requestParameters.examResultId === undefined) {
            throw new runtime.RequiredError('examResultId','Required parameter requestParameters.examResultId was null or undefined when calling reportControllerGetExamResultHistoryTimeline.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examResultId !== undefined) {
            queryParameters['examResultId'] = requestParameters.examResultId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/timelineExamResult`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimelineProcessTypeFromJSON));
    }

    /**
     */
    async reportControllerGetExamResultHistoryTimeline(examResultId: string): Promise<Array<TimelineProcessType>> {
        const response = await this.reportControllerGetExamResultHistoryTimelineRaw({ examResultId: examResultId });
        return await response.value();
    }

    /**
     */
    async reportControllerGetKwzHistoryTimelineRaw(requestParameters: ReportControllerGetKwzHistoryTimelineRequest): Promise<runtime.ApiResponse<Array<TimelineProcessType>>> {
        if (requestParameters.kwzId === null || requestParameters.kwzId === undefined) {
            throw new runtime.RequiredError('kwzId','Required parameter requestParameters.kwzId was null or undefined when calling reportControllerGetKwzHistoryTimeline.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.kwzId !== undefined) {
            queryParameters['kwzId'] = requestParameters.kwzId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/timelineKwz`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimelineProcessTypeFromJSON));
    }

    /**
     */
    async reportControllerGetKwzHistoryTimeline(kwzId: string): Promise<Array<TimelineProcessType>> {
        const response = await this.reportControllerGetKwzHistoryTimelineRaw({ kwzId: kwzId });
        return await response.value();
    }

    /**
     */
    async reportControllerGetTimelineByProcessRaw(requestParameters: ReportControllerGetTimelineByProcessRequest): Promise<runtime.ApiResponse<Array<TimelineEventType>>> {
        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling reportControllerGetTimelineByProcess.');
        }

        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling reportControllerGetTimelineByProcess.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.objectId !== undefined) {
            queryParameters['objectId'] = requestParameters.objectId;
        }

        if (requestParameters.objectType !== undefined) {
            queryParameters['objectType'] = requestParameters.objectType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/timelineEventByProcess`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimelineEventTypeFromJSON));
    }

    /**
     */
    async reportControllerGetTimelineByProcess(objectId: string, objectType: string): Promise<Array<TimelineEventType>> {
        const response = await this.reportControllerGetTimelineByProcessRaw({ objectId: objectId, objectType: objectType });
        return await response.value();
    }

    /**
     */
    async reportControllerGetTimelineByProductTypeRaw(requestParameters: ReportControllerGetTimelineByProductTypeRequest): Promise<runtime.ApiResponse<Array<TimelineProcessType>>> {
        if (requestParameters.productTypeId === null || requestParameters.productTypeId === undefined) {
            throw new runtime.RequiredError('productTypeId','Required parameter requestParameters.productTypeId was null or undefined when calling reportControllerGetTimelineByProductType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productTypeId !== undefined) {
            queryParameters['productTypeId'] = requestParameters.productTypeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/timeline`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimelineProcessTypeFromJSON));
    }

    /**
     */
    async reportControllerGetTimelineByProductType(productTypeId: string): Promise<Array<TimelineProcessType>> {
        const response = await this.reportControllerGetTimelineByProductTypeRaw({ productTypeId: productTypeId });
        return await response.value();
    }

    /**
     */
    async reportControllerGetTimelineBySampleExamRaw(requestParameters: ReportControllerGetTimelineBySampleExamRequest): Promise<runtime.ApiResponse<Array<TimelineProcessType>>> {
        if (requestParameters.sampleExamId === null || requestParameters.sampleExamId === undefined) {
            throw new runtime.RequiredError('sampleExamId','Required parameter requestParameters.sampleExamId was null or undefined when calling reportControllerGetTimelineBySampleExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sampleExamId !== undefined) {
            queryParameters['sampleExamId'] = requestParameters.sampleExamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/timelineSampleExam`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimelineProcessTypeFromJSON));
    }

    /**
     */
    async reportControllerGetTimelineBySampleExam(sampleExamId: string): Promise<Array<TimelineProcessType>> {
        const response = await this.reportControllerGetTimelineBySampleExamRaw({ sampleExamId: sampleExamId });
        return await response.value();
    }

    /**
     */
    async reportControllerNegativeTestResultsRaw(requestParameters: ReportControllerNegativeTestResultsRequest): Promise<runtime.ApiResponse<Array<ReportNegativeTestType>>> {
        if (requestParameters.filterNegativeTestDataExtended === null || requestParameters.filterNegativeTestDataExtended === undefined) {
            throw new runtime.RequiredError('filterNegativeTestDataExtended','Required parameter requestParameters.filterNegativeTestDataExtended was null or undefined when calling reportControllerNegativeTestResults.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/negativeTestResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterNegativeTestDataExtendedToJSON(requestParameters.filterNegativeTestDataExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportNegativeTestTypeFromJSON));
    }

    /**
     */
    async reportControllerNegativeTestResults(filterNegativeTestDataExtended: FilterNegativeTestDataExtended): Promise<Array<ReportNegativeTestType>> {
        const response = await this.reportControllerNegativeTestResultsRaw({ filterNegativeTestDataExtended: filterNegativeTestDataExtended });
        return await response.value();
    }

    /**
     */
    async reportControllerReportConductedInspectionsRaw(requestParameters: ReportControllerReportConductedInspectionsRequest): Promise<runtime.ApiResponse<ReportInspected>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportConductedInspections.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/conductedInspection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportInspectedFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportConductedInspections(filterData: FilterData): Promise<ReportInspected> {
        const response = await this.reportControllerReportConductedInspectionsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportControlSamlesRaw(requestParameters: ReportControllerReportControlSamlesRequest): Promise<runtime.ApiResponse<Array<ControlSampleAnalysisType>>> {
        if (requestParameters.filterNegativeTestDataExtended === null || requestParameters.filterNegativeTestDataExtended === undefined) {
            throw new runtime.RequiredError('filterNegativeTestDataExtended','Required parameter requestParameters.filterNegativeTestDataExtended was null or undefined when calling reportControllerReportControlSamles.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/ControlSampleAnalysisType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterNegativeTestDataExtendedToJSON(requestParameters.filterNegativeTestDataExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ControlSampleAnalysisTypeFromJSON));
    }

    /**
     */
    async reportControllerReportControlSamles(filterNegativeTestDataExtended: FilterNegativeTestDataExtended): Promise<Array<ControlSampleAnalysisType>> {
        const response = await this.reportControllerReportControlSamlesRaw({ filterNegativeTestDataExtended: filterNegativeTestDataExtended });
        return await response.value();
    }

    /**
     */
    async reportControllerReportCorrectiveActionsRaw(requestParameters: ReportControllerReportCorrectiveActionsRequest): Promise<runtime.ApiResponse<ReportCorrectiveActionsType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportCorrectiveActions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/correctiveAction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportCorrectiveActionsTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportCorrectiveActions(filterData: FilterData): Promise<ReportCorrectiveActionsType> {
        const response = await this.reportControllerReportCorrectiveActionsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportCostSampleExamsRaw(requestParameters: ReportControllerReportCostSampleExamsRequest): Promise<runtime.ApiResponse<ReportCostSampleExamsType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportCostSampleExams.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/costSampleExams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportCostSampleExamsTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportCostSampleExams(filterData: FilterData): Promise<ReportCostSampleExamsType> {
        const response = await this.reportControllerReportCostSampleExamsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportCustomsFeedbacksRaw(requestParameters: ReportControllerReportCustomsFeedbacksRequest): Promise<runtime.ApiResponse<ReportCustomsFeedbacksType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportCustomsFeedbacks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/customsFeedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportCustomsFeedbacksTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportCustomsFeedbacks(filterData: FilterData): Promise<ReportCustomsFeedbacksType> {
        const response = await this.reportControllerReportCustomsFeedbacksRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportFormalIrregulationsRaw(requestParameters: ReportControllerReportFormalIrregulationsRequest): Promise<runtime.ApiResponse<Array<FormalIrregulationsType>>> {
        if (requestParameters.filterDataExtended === null || requestParameters.filterDataExtended === undefined) {
            throw new runtime.RequiredError('filterDataExtended','Required parameter requestParameters.filterDataExtended was null or undefined when calling reportControllerReportFormalIrregulations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/formalIrregulations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataExtendedToJSON(requestParameters.filterDataExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FormalIrregulationsTypeFromJSON));
    }

    /**
     */
    async reportControllerReportFormalIrregulations(filterDataExtended: FilterDataExtended): Promise<Array<FormalIrregulationsType>> {
        const response = await this.reportControllerReportFormalIrregulationsRaw({ filterDataExtended: filterDataExtended });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectedOrganizationsRaw(requestParameters: ReportControllerReportInspectedOrganizationsRequest): Promise<runtime.ApiResponse<ReportInspectedOrganizations>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportInspectedOrganizations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectedOrganizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportInspectedOrganizationsFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectedOrganizations(filterData: FilterData): Promise<ReportInspectedOrganizations> {
        const response = await this.reportControllerReportInspectedOrganizationsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectedOrganizationsByProductsRaw(requestParameters: ReportControllerReportInspectedOrganizationsByProductsRequest): Promise<runtime.ApiResponse<ReportInspectedOrganizations>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportInspectedOrganizationsByProducts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectedOrganizationsByProducts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportInspectedOrganizationsFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectedOrganizationsByProducts(filterData: FilterData): Promise<ReportInspectedOrganizations> {
        const response = await this.reportControllerReportInspectedOrganizationsByProductsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectedOrganizationsByProductsWithMisstatementRaw(requestParameters: ReportControllerReportInspectedOrganizationsByProductsWithMisstatementRequest): Promise<runtime.ApiResponse<ReportInspectedOrganizations>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportInspectedOrganizationsByProductsWithMisstatement.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectedOrganizationsByProductsWithMisstatement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportInspectedOrganizationsFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectedOrganizationsByProductsWithMisstatement(filterData: FilterData): Promise<ReportInspectedOrganizations> {
        const response = await this.reportControllerReportInspectedOrganizationsByProductsWithMisstatementRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectedProductsRaw(requestParameters: ReportControllerReportInspectedProductsRequest): Promise<runtime.ApiResponse<ReportInspected>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportInspectedProducts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectedProducts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportInspectedFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectedProducts(filterData: FilterData): Promise<ReportInspected> {
        const response = await this.reportControllerReportInspectedProductsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectionPlanByInspectedProductRaw(requestParameters: ReportControllerReportInspectionPlanByInspectedProductRequest): Promise<runtime.ApiResponse<ReportInspectionPlanForInspectedProductType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportInspectionPlanByInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectionPlanByInspectedProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportInspectionPlanForInspectedProductTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectionPlanByInspectedProduct(filterYearData: FilterYearData): Promise<ReportInspectionPlanForInspectedProductType> {
        const response = await this.reportControllerReportInspectionPlanByInspectedProductRaw({ filterYearData: filterYearData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectionPlanByOrganizationRaw(requestParameters: ReportControllerReportInspectionPlanByOrganizationRequest): Promise<runtime.ApiResponse<ReportInspectionPlanForOrganizationType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportInspectionPlanByOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectionPlanByOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportInspectionPlanForOrganizationTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectionPlanByOrganization(filterYearData: FilterYearData): Promise<ReportInspectionPlanForOrganizationType> {
        const response = await this.reportControllerReportInspectionPlanByOrganizationRaw({ filterYearData: filterYearData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectionPlanProgressByInspectedProductRaw(requestParameters: ReportControllerReportInspectionPlanProgressByInspectedProductRequest): Promise<runtime.ApiResponse<InspectionPlanProgressDataForChartType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportInspectionPlanProgressByInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectionPlanProgressByInspectedProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionPlanProgressDataForChartTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectionPlanProgressByInspectedProduct(filterYearData: FilterYearData): Promise<InspectionPlanProgressDataForChartType> {
        const response = await this.reportControllerReportInspectionPlanProgressByInspectedProductRaw({ filterYearData: filterYearData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectionPlanProgressByInspectedProductCprRaw(requestParameters: ReportControllerReportInspectionPlanProgressByInspectedProductCprRequest): Promise<runtime.ApiResponse<InspectionPlanProgressDataForChartType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportInspectionPlanProgressByInspectedProductCpr.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectionPlanProgressByInspectedProductCpr`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionPlanProgressDataForChartTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectionPlanProgressByInspectedProductCpr(filterYearData: FilterYearData): Promise<InspectionPlanProgressDataForChartType> {
        const response = await this.reportControllerReportInspectionPlanProgressByInspectedProductCprRaw({ filterYearData: filterYearData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectionPlanProgressByInspectedProductGroupRaw(requestParameters: ReportControllerReportInspectionPlanProgressByInspectedProductGroupRequest): Promise<runtime.ApiResponse<InspectionPlanProgressDataForChartType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportInspectionPlanProgressByInspectedProductGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectionPlanProgressByInspectedProductGroups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionPlanProgressDataForChartTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectionPlanProgressByInspectedProductGroup(filterYearData: FilterYearData): Promise<InspectionPlanProgressDataForChartType> {
        const response = await this.reportControllerReportInspectionPlanProgressByInspectedProductGroupRaw({ filterYearData: filterYearData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportInspectionPlanProgressByOrganizationRaw(requestParameters: ReportControllerReportInspectionPlanProgressByOrganizationRequest): Promise<runtime.ApiResponse<InspectionPlanProgressDataForChartType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportInspectionPlanProgressByOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/inspectionPlanProgressByOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionPlanProgressDataForChartTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportInspectionPlanProgressByOrganization(filterYearData: FilterYearData): Promise<InspectionPlanProgressDataForChartType> {
        const response = await this.reportControllerReportInspectionPlanProgressByOrganizationRaw({ filterYearData: filterYearData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportJudgmentsCountRaw(requestParameters: ReportControllerReportJudgmentsCountRequest): Promise<runtime.ApiResponse<ReportJudgmentsCountType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportJudgmentsCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/judgmentsCount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportJudgmentsCountTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportJudgmentsCount(filterData: FilterData): Promise<ReportJudgmentsCountType> {
        const response = await this.reportControllerReportJudgmentsCountRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportJudgmentsInProceedingAndPakRaw(requestParameters: ReportControllerReportJudgmentsInProceedingAndPakRequest): Promise<runtime.ApiResponse<ReportJudgmentsCountType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportJudgmentsInProceedingAndPak.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/judgmentsInProceedingAndPak`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportJudgmentsCountTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportJudgmentsInProceedingAndPak(filterData: FilterData): Promise<ReportJudgmentsCountType> {
        const response = await this.reportControllerReportJudgmentsInProceedingAndPakRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportOrderedSampleExamsRaw(requestParameters: ReportControllerReportOrderedSampleExamsRequest): Promise<runtime.ApiResponse<ReportOrderedSampleExamsType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportOrderedSampleExams.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/orderedSampleExams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportOrderedSampleExamsTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportOrderedSampleExams(filterData: FilterData): Promise<ReportOrderedSampleExamsType> {
        const response = await this.reportControllerReportOrderedSampleExamsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportPakAmountRaw(requestParameters: ReportControllerReportPakAmountRequest): Promise<runtime.ApiResponse<ReportPakAmountType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportPakAmount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/pakAmount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportPakAmountTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportPakAmount(filterData: FilterData): Promise<ReportPakAmountType> {
        const response = await this.reportControllerReportPakAmountRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportPakAnalysisMoneyPenaltiesRaw(requestParameters: ReportControllerReportPakAnalysisMoneyPenaltiesRequest): Promise<runtime.ApiResponse<Array<PakAnalysisMoneyPenaltiesType>>> {
        if (requestParameters.filterDataExtended === null || requestParameters.filterDataExtended === undefined) {
            throw new runtime.RequiredError('filterDataExtended','Required parameter requestParameters.filterDataExtended was null or undefined when calling reportControllerReportPakAnalysisMoneyPenalties.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/pakAnalysisMoneyPenalties`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataExtendedToJSON(requestParameters.filterDataExtended),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PakAnalysisMoneyPenaltiesTypeFromJSON));
    }

    /**
     */
    async reportControllerReportPakAnalysisMoneyPenalties(filterDataExtended: FilterDataExtended): Promise<Array<PakAnalysisMoneyPenaltiesType>> {
        const response = await this.reportControllerReportPakAnalysisMoneyPenaltiesRaw({ filterDataExtended: filterDataExtended });
        return await response.value();
    }

    /**
     */
    async reportControllerReportPakByJudgmentRaw(requestParameters: ReportControllerReportPakByJudgmentRequest): Promise<runtime.ApiResponse<ReportPakJudgmentType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportPakByJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/pak`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportPakJudgmentTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportPakByJudgment(filterData: FilterData): Promise<ReportPakJudgmentType> {
        const response = await this.reportControllerReportPakByJudgmentRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportProceedingRaw(requestParameters: ReportControllerReportProceedingRequest): Promise<runtime.ApiResponse<ReportProceedingType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportProceeding.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/proceeding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportProceedingTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportProceeding(filterData: FilterData): Promise<ReportProceedingType> {
        const response = await this.reportControllerReportProceedingRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportProductsByMisstatementRaw(requestParameters: ReportControllerReportProductsByMisstatementRequest): Promise<runtime.ApiResponse<ReportProductsByMisstatement>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportProductsByMisstatement.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/productsByMisstatement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportProductsByMisstatementFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportProductsByMisstatement(filterData: FilterData): Promise<ReportProductsByMisstatement> {
        const response = await this.reportControllerReportProductsByMisstatementRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportProductsByRangeRaw(requestParameters: ReportControllerReportProductsByRangeRequest): Promise<runtime.ApiResponse<ReportInspectedProductsByRange>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportProductsByRange.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/productsByRange`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportInspectedProductsByRangeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportProductsByRange(filterData: FilterData): Promise<ReportInspectedProductsByRange> {
        const response = await this.reportControllerReportProductsByRangeRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportProductsBySignRaw(requestParameters: ReportControllerReportProductsBySignRequest): Promise<runtime.ApiResponse<ReportProductsBySign>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportProductsBySign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/productsBySign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportProductsBySignFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportProductsBySign(filterData: FilterData): Promise<ReportProductsBySign> {
        const response = await this.reportControllerReportProductsBySignRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportProductsByTypeRaw(requestParameters: ReportControllerReportProductsByTypeRequest): Promise<runtime.ApiResponse<ReportProductsByType>> {
        if (requestParameters.filterDatesProductTypeReportData === null || requestParameters.filterDatesProductTypeReportData === undefined) {
            throw new runtime.RequiredError('filterDatesProductTypeReportData','Required parameter requestParameters.filterDatesProductTypeReportData was null or undefined when calling reportControllerReportProductsByType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/productsByType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDatesProductTypeReportDataToJSON(requestParameters.filterDatesProductTypeReportData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportProductsByTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportProductsByType(filterDatesProductTypeReportData: FilterDatesProductTypeReportData): Promise<ReportProductsByType> {
        const response = await this.reportControllerReportProductsByTypeRaw({ filterDatesProductTypeReportData: filterDatesProductTypeReportData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportProductsGroupsRaw(requestParameters: ReportControllerReportProductsGroupsRequest): Promise<runtime.ApiResponse<ReportProductsGroupsType>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportProductsGroups.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/productsGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportProductsGroupsTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportProductsGroups(filterData: FilterData): Promise<ReportProductsGroupsType> {
        const response = await this.reportControllerReportProductsGroupsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async reportControllerReportPublicationsRaw(requestParameters: ReportControllerReportPublicationsRequest): Promise<runtime.ApiResponse<Array<ReportPublicationsType>>> {
        if (requestParameters.filterData === null || requestParameters.filterData === undefined) {
            throw new runtime.RequiredError('filterData','Required parameter requestParameters.filterData was null or undefined when calling reportControllerReportPublications.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/publications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDataToJSON(requestParameters.filterData),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportPublicationsTypeFromJSON));
    }

    /**
     */
    async reportControllerReportPublications(filterData: FilterData): Promise<Array<ReportPublicationsType>> {
        const response = await this.reportControllerReportPublicationsRaw({ filterData: filterData });
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerCreateNewRaw(requestParameters: SampleCollectControllerCreateNewRequest): Promise<runtime.ApiResponse<GetSampleCollectDto>> {
        if (requestParameters.createUpdateSampleCollectDto === null || requestParameters.createUpdateSampleCollectDto === undefined) {
            throw new runtime.RequiredError('createUpdateSampleCollectDto','Required parameter requestParameters.createUpdateSampleCollectDto was null or undefined when calling sampleCollectControllerCreateNew.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/createNew`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSampleCollectDtoToJSON(requestParameters.createUpdateSampleCollectDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleCollectDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleCollectControllerCreateNew(createUpdateSampleCollectDto: CreateUpdateSampleCollectDto): Promise<GetSampleCollectDto> {
        const response = await this.sampleCollectControllerCreateNewRaw({ createUpdateSampleCollectDto: createUpdateSampleCollectDto });
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerCreateWithNewOrganizationRaw(requestParameters: SampleCollectControllerCreateWithNewOrganizationRequest): Promise<runtime.ApiResponse<GetSampleCollectDto>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sampleCollectControllerCreateWithNewOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/createWithNewOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleCollectDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleCollectControllerCreateWithNewOrganization(body: object): Promise<GetSampleCollectDto> {
        const response = await this.sampleCollectControllerCreateWithNewOrganizationRaw({ body: body });
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerDeleteRaw(requestParameters: SampleCollectControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleCollectControllerDelete(id: string): Promise<void> {
        await this.sampleCollectControllerDeleteRaw({ id: id });
    }

    /**
     */
    async sampleCollectControllerGetRaw(requestParameters: SampleCollectControllerGetRequest): Promise<runtime.ApiResponse<GetSampleCollectDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleCollectDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleCollectControllerGet(id: string): Promise<GetSampleCollectDto> {
        const response = await this.sampleCollectControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerGetPendingForUserRaw(): Promise<runtime.ApiResponse<Array<GetSampleCollectExtendedDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/pending/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleCollectExtendedDtoFromJSON));
    }

    /**
     */
    async sampleCollectControllerGetPendingForUser(): Promise<Array<GetSampleCollectExtendedDto>> {
        const response = await this.sampleCollectControllerGetPendingForUserRaw();
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerGetPlacesForOrganizationRaw(requestParameters: SampleCollectControllerGetPlacesForOrganizationRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerGetPlacesForOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/collectPlaces/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async sampleCollectControllerGetPlacesForOrganization(id: string): Promise<Array<string>> {
        const response = await this.sampleCollectControllerGetPlacesForOrganizationRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerGetWithExamsRaw(requestParameters: SampleCollectControllerGetWithExamsRequest): Promise<runtime.ApiResponse<GetSampleCollectWithExamsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerGetWithExams.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/withExams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleCollectWithExamsDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleCollectControllerGetWithExams(id: string): Promise<GetSampleCollectWithExamsDto> {
        const response = await this.sampleCollectControllerGetWithExamsRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerMayDeleteRaw(requestParameters: SampleCollectControllerMayDeleteRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerMayDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/mayDelete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async sampleCollectControllerMayDelete(id: string): Promise<ValidationStatus> {
        const response = await this.sampleCollectControllerMayDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerReadyToSignRaw(requestParameters: SampleCollectControllerReadyToSignRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerReadyToSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/readyToSign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async sampleCollectControllerReadyToSign(id: string): Promise<ValidationStatus> {
        const response = await this.sampleCollectControllerReadyToSignRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleCollectControllerRevertSignRaw(requestParameters: SampleCollectControllerRevertSignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerRevertSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/revertSign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleCollectControllerRevertSign(id: string): Promise<void> {
        await this.sampleCollectControllerRevertSignRaw({ id: id });
    }

    /**
     */
    async sampleCollectControllerSignRaw(requestParameters: SampleCollectControllerSignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/sign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleCollectControllerSign(id: string): Promise<void> {
        await this.sampleCollectControllerSignRaw({ id: id });
    }

    /**
     */
    async sampleCollectControllerUpdateRaw(requestParameters: SampleCollectControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerUpdate.');
        }

        if (requestParameters.createUpdateSampleCollectDto === null || requestParameters.createUpdateSampleCollectDto === undefined) {
            throw new runtime.RequiredError('createUpdateSampleCollectDto','Required parameter requestParameters.createUpdateSampleCollectDto was null or undefined when calling sampleCollectControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSampleCollectDtoToJSON(requestParameters.createUpdateSampleCollectDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleCollectControllerUpdate(id: string, createUpdateSampleCollectDto: CreateUpdateSampleCollectDto): Promise<void> {
        await this.sampleCollectControllerUpdateRaw({ id: id, createUpdateSampleCollectDto: createUpdateSampleCollectDto });
    }

    /**
     */
    async sampleCollectControllerUpdateWithNewOrganizationRaw(requestParameters: SampleCollectControllerUpdateWithNewOrganizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleCollectControllerUpdateWithNewOrganization.');
        }

        if (requestParameters.createUpdateOrganizationDto === null || requestParameters.createUpdateOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrganizationDto','Required parameter requestParameters.createUpdateOrganizationDto was null or undefined when calling sampleCollectControllerUpdateWithNewOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleCollect/updateWithNewOrganization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrganizationDtoToJSON(requestParameters.createUpdateOrganizationDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleCollectControllerUpdateWithNewOrganization(id: string, createUpdateOrganizationDto: CreateUpdateOrganizationDto): Promise<void> {
        await this.sampleCollectControllerUpdateWithNewOrganizationRaw({ id: id, createUpdateOrganizationDto: createUpdateOrganizationDto });
    }

    /**
     */
    async sampleControllerCreateRaw(requestParameters: SampleControllerCreateRequest): Promise<runtime.ApiResponse<GetSampleDto>> {
        if (requestParameters.createUpdateSampleDto === null || requestParameters.createUpdateSampleDto === undefined) {
            throw new runtime.RequiredError('createUpdateSampleDto','Required parameter requestParameters.createUpdateSampleDto was null or undefined when calling sampleControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sample`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSampleDtoToJSON(requestParameters.createUpdateSampleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleControllerCreate(createUpdateSampleDto: CreateUpdateSampleDto): Promise<GetSampleDto> {
        const response = await this.sampleControllerCreateRaw({ createUpdateSampleDto: createUpdateSampleDto });
        return await response.value();
    }

    /**
     */
    async sampleControllerDeleteRaw(requestParameters: SampleControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sample/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleControllerDelete(id: string): Promise<void> {
        await this.sampleControllerDeleteRaw({ id: id });
    }

    /**
     */
    async sampleControllerGetRaw(requestParameters: SampleControllerGetRequest): Promise<runtime.ApiResponse<GetSampleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sample/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleControllerGet(id: string): Promise<GetSampleDto> {
        const response = await this.sampleControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleControllerGetByPakRaw(requestParameters: SampleControllerGetByPakRequest): Promise<runtime.ApiResponse<Array<GetSampleExtendedDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleControllerGetByPak.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sample/byPak/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExtendedDtoFromJSON));
    }

    /**
     */
    async sampleControllerGetByPak(id: string): Promise<Array<GetSampleExtendedDto>> {
        const response = await this.sampleControllerGetByPakRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleControllerGetBySampleExamRaw(requestParameters: SampleControllerGetBySampleExamRequest): Promise<runtime.ApiResponse<Array<GetSampleExtendedDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleControllerGetBySampleExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sample/bySampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExtendedDtoFromJSON));
    }

    /**
     */
    async sampleControllerGetBySampleExam(id: string): Promise<Array<GetSampleExtendedDto>> {
        const response = await this.sampleControllerGetBySampleExamRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleControllerGetControlsSampleByDateOrgUnitRaw(requestParameters: SampleControllerGetControlsSampleByDateOrgUnitRequest): Promise<runtime.ApiResponse<Array<GetSampleDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.organizationalUnitId !== undefined) {
            queryParameters['organizationalUnitId'] = requestParameters.organizationalUnitId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sample/controlsSample/byDateOrgUnit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleDtoFromJSON));
    }

    /**
     */
    async sampleControllerGetControlsSampleByDateOrgUnit(dateFrom?: Date, dateTo?: Date, organizationalUnitId?: string): Promise<Array<GetSampleDto>> {
        const response = await this.sampleControllerGetControlsSampleByDateOrgUnitRaw({ dateFrom: dateFrom, dateTo: dateTo, organizationalUnitId: organizationalUnitId });
        return await response.value();
    }

    /**
     */
    async sampleControllerUpdateRaw(requestParameters: SampleControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleControllerUpdate.');
        }

        if (requestParameters.createUpdateSampleDto === null || requestParameters.createUpdateSampleDto === undefined) {
            throw new runtime.RequiredError('createUpdateSampleDto','Required parameter requestParameters.createUpdateSampleDto was null or undefined when calling sampleControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sample/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSampleDtoToJSON(requestParameters.createUpdateSampleDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleControllerUpdate(id: string, createUpdateSampleDto: CreateUpdateSampleDto): Promise<void> {
        await this.sampleControllerUpdateRaw({ id: id, createUpdateSampleDto: createUpdateSampleDto });
    }

    /**
     */
    async sampleExamControllerCreateBasedOnInspectedProductRaw(requestParameters: SampleExamControllerCreateBasedOnInspectedProductRequest): Promise<runtime.ApiResponse<GetSampleExamDto>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sampleExamControllerCreateBasedOnInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/basedOnInspectedProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerCreateBasedOnInspectedProduct(body: object): Promise<GetSampleExamDto> {
        const response = await this.sampleExamControllerCreateBasedOnInspectedProductRaw({ body: body });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerCreateBasedOnProductTypeRaw(requestParameters: SampleExamControllerCreateBasedOnProductTypeRequest): Promise<runtime.ApiResponse<GetSampleExamDto>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sampleExamControllerCreateBasedOnProductType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/basedOnProductType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerCreateBasedOnProductType(body: object): Promise<GetSampleExamDto> {
        const response = await this.sampleExamControllerCreateBasedOnProductTypeRaw({ body: body });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerCreateBasedOnProductTypeWithLinkedInspectionRaw(requestParameters: SampleExamControllerCreateBasedOnProductTypeWithLinkedInspectionRequest): Promise<runtime.ApiResponse<GetSampleExamDto>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sampleExamControllerCreateBasedOnProductTypeWithLinkedInspection.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/basedOnProductTypeWithLinkedInspection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerCreateBasedOnProductTypeWithLinkedInspection(body: object): Promise<GetSampleExamDto> {
        const response = await this.sampleExamControllerCreateBasedOnProductTypeWithLinkedInspectionRaw({ body: body });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerCreateWithNewProductTypeRaw(requestParameters: SampleExamControllerCreateWithNewProductTypeRequest): Promise<runtime.ApiResponse<GetSampleExamDto>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sampleExamControllerCreateWithNewProductType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/withNewProductType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerCreateWithNewProductType(body: object): Promise<GetSampleExamDto> {
        const response = await this.sampleExamControllerCreateWithNewProductTypeRaw({ body: body });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerCreateWithNewProductTypeAndOrganizationRaw(requestParameters: SampleExamControllerCreateWithNewProductTypeAndOrganizationRequest): Promise<runtime.ApiResponse<GetSampleExamDto>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sampleExamControllerCreateWithNewProductTypeAndOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/withNewProductTypeAndOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerCreateWithNewProductTypeAndOrganization(body: object): Promise<GetSampleExamDto> {
        const response = await this.sampleExamControllerCreateWithNewProductTypeAndOrganizationRaw({ body: body });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerDeleteRaw(requestParameters: SampleExamControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleExamControllerDelete(id: string): Promise<void> {
        await this.sampleExamControllerDeleteRaw({ id: id });
    }

    /**
     */
    async sampleExamControllerGetRaw(requestParameters: SampleExamControllerGetRequest): Promise<runtime.ApiResponse<GetSampleExamDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerGet(id: string): Promise<GetSampleExamDto> {
        const response = await this.sampleExamControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetAllByPortionRaw(requestParameters: SampleExamControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetSampleExamExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.organizationalUnit === null || requestParameters.organizationalUnit === undefined) {
            throw new runtime.RequiredError('organizationalUnit','Required parameter requestParameters.organizationalUnit was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.organization === null || requestParameters.organization === undefined) {
            throw new runtime.RequiredError('organization','Required parameter requestParameters.organization was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.productType === null || requestParameters.productType === undefined) {
            throw new runtime.RequiredError('productType','Required parameter requestParameters.productType was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.collectDateFrom === null || requestParameters.collectDateFrom === undefined) {
            throw new runtime.RequiredError('collectDateFrom','Required parameter requestParameters.collectDateFrom was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.collectDateTo === null || requestParameters.collectDateTo === undefined) {
            throw new runtime.RequiredError('collectDateTo','Required parameter requestParameters.collectDateTo was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.fileNumber === null || requestParameters.fileNumber === undefined) {
            throw new runtime.RequiredError('fileNumber','Required parameter requestParameters.fileNumber was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        if (requestParameters.sysNumber === null || requestParameters.sysNumber === undefined) {
            throw new runtime.RequiredError('sysNumber','Required parameter requestParameters.sysNumber was null or undefined when calling sampleExamControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.organizationalUnit !== undefined) {
            queryParameters['organizationalUnit'] = requestParameters.organizationalUnit;
        }

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.productType !== undefined) {
            queryParameters['productType'] = requestParameters.productType;
        }

        if (requestParameters.collectDateFrom !== undefined) {
            queryParameters['collectDateFrom'] = requestParameters.collectDateFrom;
        }

        if (requestParameters.collectDateTo !== undefined) {
            queryParameters['collectDateTo'] = requestParameters.collectDateTo;
        }

        if (requestParameters.fileNumber !== undefined) {
            queryParameters['fileNumber'] = requestParameters.fileNumber;
        }

        if (requestParameters.sysNumber !== undefined) {
            queryParameters['sysNumber'] = requestParameters.sysNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExamExtendedDtoFromJSON));
    }

    /**
     */
    async sampleExamControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, organizationalUnit: string, organization: string, productType: string, collectDateFrom: string, collectDateTo: string, fileNumber: string, sysNumber: string): Promise<Array<GetSampleExamExtendedDto>> {
        const response = await this.sampleExamControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, organizationalUnit: organizationalUnit, organization: organization, productType: productType, collectDateFrom: collectDateFrom, collectDateTo: collectDateTo, fileNumber: fileNumber, sysNumber: sysNumber });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetAllCountRaw(requestParameters: SampleExamControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async sampleExamControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.sampleExamControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetByInspectedProductRaw(requestParameters: SampleExamControllerGetByInspectedProductRequest): Promise<runtime.ApiResponse<GetSampleExamDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerGetByInspectedProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/byInspectedProduct/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerGetByInspectedProduct(id: string): Promise<GetSampleExamDto> {
        const response = await this.sampleExamControllerGetByInspectedProductRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetExamsInRevisionByPortionRaw(requestParameters: SampleExamControllerGetExamsInRevisionByPortionRequest): Promise<runtime.ApiResponse<Array<GetSampleExamExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling sampleExamControllerGetExamsInRevisionByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling sampleExamControllerGetExamsInRevisionByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling sampleExamControllerGetExamsInRevisionByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling sampleExamControllerGetExamsInRevisionByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetExamsInRevisionByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/examsInRevision/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExamExtendedDtoFromJSON));
    }

    /**
     */
    async sampleExamControllerGetExamsInRevisionByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetSampleExamExtendedDto>> {
        const response = await this.sampleExamControllerGetExamsInRevisionByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetExamsInRevisionCountRaw(requestParameters: SampleExamControllerGetExamsInRevisionCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetExamsInRevisionCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/examsInRevision/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async sampleExamControllerGetExamsInRevisionCount(filterText: string): Promise<number> {
        const response = await this.sampleExamControllerGetExamsInRevisionCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetExamsWithoutApprovedResultsRaw(): Promise<runtime.ApiResponse<Array<GetSampleExamExtendedDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/pendingWithoutResults/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExamExtendedDtoFromJSON));
    }

    /**
     */
    async sampleExamControllerGetExamsWithoutApprovedResults(): Promise<Array<GetSampleExamExtendedDto>> {
        const response = await this.sampleExamControllerGetExamsWithoutApprovedResultsRaw();
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetExamsWithoutApprovedResultsByPortionRaw(requestParameters: SampleExamControllerGetExamsWithoutApprovedResultsByPortionRequest): Promise<runtime.ApiResponse<Array<GetSampleExamExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling sampleExamControllerGetExamsWithoutApprovedResultsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling sampleExamControllerGetExamsWithoutApprovedResultsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling sampleExamControllerGetExamsWithoutApprovedResultsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling sampleExamControllerGetExamsWithoutApprovedResultsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetExamsWithoutApprovedResultsByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/withdrawal/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExamExtendedDtoFromJSON));
    }

    /**
     */
    async sampleExamControllerGetExamsWithoutApprovedResultsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetSampleExamExtendedDto>> {
        const response = await this.sampleExamControllerGetExamsWithoutApprovedResultsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetExamsWithoutApprovedResultsCountRaw(requestParameters: SampleExamControllerGetExamsWithoutApprovedResultsCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetExamsWithoutApprovedResultsCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/withdrawal/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async sampleExamControllerGetExamsWithoutApprovedResultsCount(filterText: string): Promise<number> {
        const response = await this.sampleExamControllerGetExamsWithoutApprovedResultsCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetNotInspectedForUserRaw(requestParameters: SampleExamControllerGetNotInspectedForUserRequest): Promise<runtime.ApiResponse<Array<GetSampleExamExtendedDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerGetNotInspectedForUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/notInspected/byProductType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExamExtendedDtoFromJSON));
    }

    /**
     */
    async sampleExamControllerGetNotInspectedForUser(id: string): Promise<Array<GetSampleExamExtendedDto>> {
        const response = await this.sampleExamControllerGetNotInspectedForUserRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetPublishedByPortionRaw(requestParameters: SampleExamControllerGetPublishedByPortionRequest): Promise<runtime.ApiResponse<Array<GetSampleExamExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling sampleExamControllerGetPublishedByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling sampleExamControllerGetPublishedByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling sampleExamControllerGetPublishedByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling sampleExamControllerGetPublishedByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetPublishedByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/published/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExamExtendedDtoFromJSON));
    }

    /**
     */
    async sampleExamControllerGetPublishedByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetSampleExamExtendedDto>> {
        const response = await this.sampleExamControllerGetPublishedByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetPublishedCountRaw(requestParameters: SampleExamControllerGetPublishedCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetPublishedCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/published/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async sampleExamControllerGetPublishedCount(filterText: string): Promise<number> {
        const response = await this.sampleExamControllerGetPublishedCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetReadyForPublicationByPortionRaw(requestParameters: SampleExamControllerGetReadyForPublicationByPortionRequest): Promise<runtime.ApiResponse<Array<GetSampleExamExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling sampleExamControllerGetReadyForPublicationByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling sampleExamControllerGetReadyForPublicationByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling sampleExamControllerGetReadyForPublicationByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling sampleExamControllerGetReadyForPublicationByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetReadyForPublicationByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/readyForPublication/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExamExtendedDtoFromJSON));
    }

    /**
     */
    async sampleExamControllerGetReadyForPublicationByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetSampleExamExtendedDto>> {
        const response = await this.sampleExamControllerGetReadyForPublicationByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetReadyForPublicationCountRaw(requestParameters: SampleExamControllerGetReadyForPublicationCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetReadyForPublicationCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/readyForPublication/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async sampleExamControllerGetReadyForPublicationCount(filterText: string): Promise<number> {
        const response = await this.sampleExamControllerGetReadyForPublicationCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetViewModelRaw(requestParameters: SampleExamControllerGetViewModelRequest): Promise<runtime.ApiResponse<GetSampleExamViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerGetViewModel.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/viewModel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSampleExamViewModelFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerGetViewModel(id: string): Promise<GetSampleExamViewModel> {
        const response = await this.sampleExamControllerGetViewModelRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetWithdrawalExamsByPortionRaw(requestParameters: SampleExamControllerGetWithdrawalExamsByPortionRequest): Promise<runtime.ApiResponse<Array<GetSampleExamExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling sampleExamControllerGetWithdrawalExamsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling sampleExamControllerGetWithdrawalExamsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling sampleExamControllerGetWithdrawalExamsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling sampleExamControllerGetWithdrawalExamsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetWithdrawalExamsByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/pendingWithoutResults/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSampleExamExtendedDtoFromJSON));
    }

    /**
     */
    async sampleExamControllerGetWithdrawalExamsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetSampleExamExtendedDto>> {
        const response = await this.sampleExamControllerGetWithdrawalExamsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerGetWithdrawalExamsCountRaw(requestParameters: SampleExamControllerGetWithdrawalExamsCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling sampleExamControllerGetWithdrawalExamsCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/pendingWithoutResults/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async sampleExamControllerGetWithdrawalExamsCount(filterText: string): Promise<number> {
        const response = await this.sampleExamControllerGetWithdrawalExamsCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerMayDeleteRaw(requestParameters: SampleExamControllerMayDeleteRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerMayDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/mayDelete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerMayDelete(id: string): Promise<ValidationStatus> {
        const response = await this.sampleExamControllerMayDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerMayFinishRaw(requestParameters: SampleExamControllerMayFinishRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerMayFinish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/mayFinish/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerMayFinish(id: string): Promise<ValidationStatus> {
        const response = await this.sampleExamControllerMayFinishRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerMayRevertSigningRaw(requestParameters: SampleExamControllerMayRevertSigningRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerMayRevertSigning.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/mayRevertSigning/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerMayRevertSigning(id: string): Promise<ValidationStatus> {
        const response = await this.sampleExamControllerMayRevertSigningRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerReadyToSignRaw(requestParameters: SampleExamControllerReadyToSignRequest): Promise<runtime.ApiResponse<ValidationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerReadyToSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/readyToSign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationStatusFromJSON(jsonValue));
    }

    /**
     */
    async sampleExamControllerReadyToSign(id: string): Promise<ValidationStatus> {
        const response = await this.sampleExamControllerReadyToSignRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async sampleExamControllerRevertSignRaw(requestParameters: SampleExamControllerRevertSignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerRevertSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/revertSign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleExamControllerRevertSign(id: string): Promise<void> {
        await this.sampleExamControllerRevertSignRaw({ id: id });
    }

    /**
     */
    async sampleExamControllerSignRaw(requestParameters: SampleExamControllerSignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/sign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleExamControllerSign(id: string): Promise<void> {
        await this.sampleExamControllerSignRaw({ id: id });
    }

    /**
     */
    async sampleExamControllerUpdateRaw(requestParameters: SampleExamControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerUpdate.');
        }

        if (requestParameters.createUpdateSampleExamDto === null || requestParameters.createUpdateSampleExamDto === undefined) {
            throw new runtime.RequiredError('createUpdateSampleExamDto','Required parameter requestParameters.createUpdateSampleExamDto was null or undefined when calling sampleExamControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSampleExamDtoToJSON(requestParameters.createUpdateSampleExamDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleExamControllerUpdate(id: string, createUpdateSampleExamDto: CreateUpdateSampleExamDto): Promise<void> {
        await this.sampleExamControllerUpdateRaw({ id: id, createUpdateSampleExamDto: createUpdateSampleExamDto });
    }

    /**
     */
    async sampleExamControllerUpdateWithNewSupplierRaw(requestParameters: SampleExamControllerUpdateWithNewSupplierRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sampleExamControllerUpdateWithNewSupplier.');
        }

        if (requestParameters.createUpdateOrganizationDto === null || requestParameters.createUpdateOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrganizationDto','Required parameter requestParameters.createUpdateOrganizationDto was null or undefined when calling sampleExamControllerUpdateWithNewSupplier.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sampleExam/updateWithNewSupplier/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrganizationDtoToJSON(requestParameters.createUpdateOrganizationDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sampleExamControllerUpdateWithNewSupplier(id: string, createUpdateOrganizationDto: CreateUpdateOrganizationDto): Promise<void> {
        await this.sampleExamControllerUpdateWithNewSupplierRaw({ id: id, createUpdateOrganizationDto: createUpdateOrganizationDto });
    }

    /**
     */
    async techAssessmentControllerCreateRaw(requestParameters: TechAssessmentControllerCreateRequest): Promise<runtime.ApiResponse<GetTechAssessmentDto>> {
        if (requestParameters.createUpdateTechAssessmentDto === null || requestParameters.createUpdateTechAssessmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateTechAssessmentDto','Required parameter requestParameters.createUpdateTechAssessmentDto was null or undefined when calling techAssessmentControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/techAssessment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateTechAssessmentDtoToJSON(requestParameters.createUpdateTechAssessmentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTechAssessmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async techAssessmentControllerCreate(createUpdateTechAssessmentDto: CreateUpdateTechAssessmentDto): Promise<GetTechAssessmentDto> {
        const response = await this.techAssessmentControllerCreateRaw({ createUpdateTechAssessmentDto: createUpdateTechAssessmentDto });
        return await response.value();
    }

    /**
     */
    async techAssessmentControllerCreateWithOrganizationRaw(requestParameters: TechAssessmentControllerCreateWithOrganizationRequest): Promise<runtime.ApiResponse<GetTechAssessmentDto>> {
        if (requestParameters.createUpdateTechAssessmentWithOrganizationDto === null || requestParameters.createUpdateTechAssessmentWithOrganizationDto === undefined) {
            throw new runtime.RequiredError('createUpdateTechAssessmentWithOrganizationDto','Required parameter requestParameters.createUpdateTechAssessmentWithOrganizationDto was null or undefined when calling techAssessmentControllerCreateWithOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/techAssessment/withOrganization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateTechAssessmentWithOrganizationDtoToJSON(requestParameters.createUpdateTechAssessmentWithOrganizationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTechAssessmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async techAssessmentControllerCreateWithOrganization(createUpdateTechAssessmentWithOrganizationDto: CreateUpdateTechAssessmentWithOrganizationDto): Promise<GetTechAssessmentDto> {
        const response = await this.techAssessmentControllerCreateWithOrganizationRaw({ createUpdateTechAssessmentWithOrganizationDto: createUpdateTechAssessmentWithOrganizationDto });
        return await response.value();
    }

    /**
     */
    async techAssessmentControllerDeleteRaw(requestParameters: TechAssessmentControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling techAssessmentControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/techAssessment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async techAssessmentControllerDelete(id: string): Promise<void> {
        await this.techAssessmentControllerDeleteRaw({ id: id });
    }

    /**
     */
    async techAssessmentControllerGetRaw(requestParameters: TechAssessmentControllerGetRequest): Promise<runtime.ApiResponse<GetTechAssessmentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling techAssessmentControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/techAssessment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTechAssessmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async techAssessmentControllerGet(id: string): Promise<GetTechAssessmentDto> {
        const response = await this.techAssessmentControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async techAssessmentControllerGetAllByPortionRaw(requestParameters: TechAssessmentControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetTechAssessmentDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling techAssessmentControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling techAssessmentControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling techAssessmentControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling techAssessmentControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling techAssessmentControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/techAssessment/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTechAssessmentDtoFromJSON));
    }

    /**
     */
    async techAssessmentControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetTechAssessmentDto>> {
        const response = await this.techAssessmentControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async techAssessmentControllerGetAllCountRaw(requestParameters: TechAssessmentControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling techAssessmentControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/techAssessment/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async techAssessmentControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.techAssessmentControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async techAssessmentControllerGetByOrganizationRaw(requestParameters: TechAssessmentControllerGetByOrganizationRequest): Promise<runtime.ApiResponse<Array<GetTechAssessmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling techAssessmentControllerGetByOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/techAssessment/byOrganization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTechAssessmentDtoFromJSON));
    }

    /**
     */
    async techAssessmentControllerGetByOrganization(id: string): Promise<Array<GetTechAssessmentDto>> {
        const response = await this.techAssessmentControllerGetByOrganizationRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async techAssessmentControllerUpdateRaw(requestParameters: TechAssessmentControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling techAssessmentControllerUpdate.');
        }

        if (requestParameters.createUpdateTechAssessmentDto === null || requestParameters.createUpdateTechAssessmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateTechAssessmentDto','Required parameter requestParameters.createUpdateTechAssessmentDto was null or undefined when calling techAssessmentControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/techAssessment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateTechAssessmentDtoToJSON(requestParameters.createUpdateTechAssessmentDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async techAssessmentControllerUpdate(id: string, createUpdateTechAssessmentDto: CreateUpdateTechAssessmentDto): Promise<void> {
        await this.techAssessmentControllerUpdateRaw({ id: id, createUpdateTechAssessmentDto: createUpdateTechAssessmentDto });
    }

}

/**
    * @export
    * @enum {string}
    */
export enum MemoControllerGetSubjectThreadStatsSubjectTypeEnum {
    Kontrola = 'kontrola',
    PobranieProbek = 'pobranie_probek',
    BadanieProbek = 'badanie_probek',
    Postepowanie = 'postepowanie',
    Pak = 'pak',
    Kwz = 'kwz'
}
