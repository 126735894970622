import React from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as Icon from 'react-bootstrap-icons';

import { GetSampleCollectExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ProductTypeNotApproved from '../ProductTypes/ProductTypeNotApproved';

export const SampleCollectType: React.FC<{
  sampleCollect: GetSampleCollectExtendedDto;
  duringInspectionBasisId: string;
}> = props => {
  if (!props.sampleCollect) {
    return <></>;
  }

  return (
    <>
      {props.sampleCollect.notApprovedProductTypes > 0 && (
        <ProductTypeNotApproved
          className="me-1"
          tooltip="Co najmniej jeden z pobranych typów wyrobów nie jest zatwierdzony"
        />
      )}
      {props.sampleCollect.approved &&
        props.sampleCollect?.collectBasis?.id !==
          props.duringInspectionBasisId && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Podpisany protokół z czynności</Tooltip>}
          >
            <Icon.Pencil className="text-success me-1" />
          </OverlayTrigger>
        )}
      {props.sampleCollect.notApprovedResults > 0 && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Liczba wyników próbek do zatwierdzenia</Tooltip>}
        >
          <Badge bg="info" className="me-1">
            {props.sampleCollect.notApprovedResults}
          </Badge>
        </OverlayTrigger>
      )}
      {props.sampleCollect.publications && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Wyniki zostały opublikowane pod nr:{' '}
              {props.sampleCollect.publications}
            </Tooltip>
          }
        >
          <Icon.Globe className="me-1" />
        </OverlayTrigger>
      )}
      {props.sampleCollect.positiveResults > 0 && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Liczba pozytywnych wyników</Tooltip>}
        >
          <Badge bg="success" className="me-1">
            {props.sampleCollect.positiveResults}
          </Badge>
        </OverlayTrigger>
      )}
      {props.sampleCollect.negativeResults > 0 && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Liczba negatywnych wyników</Tooltip>}
        >
          <Badge bg="danger" className="me-1">
            {props.sampleCollect.negativeResults}
          </Badge>
        </OverlayTrigger>
      )}
    </>
  );
};
